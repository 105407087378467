/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';
import './index.scss';

export const SalesPolicy = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/sales_policy.jpeg'
        mainTitle='CHÍNH SÁCH'
        subTitle='BÁN HÀNG'
      />
      <div id='pricing' className='content-section-plan-plan gray'>
        {/* <div className='hero-content-wrapper-plans w-container'>
          <div className='plan-box' />
          <div className='heading-box'>
            <h2 className='h2 big smaller'>
              bảng giá dịch vụ tại <span className='text-span-13'>emvn.co</span>
            </h2>
          </div>
          <div className='flex-plan'>
            <div
              data-duration-in={300}
              data-duration-out={100}
              className='tabs w-tabs'
            >
              <div className='tabs-content w-tab-content'>
                <div data-w-tab='Tab 2' className='w-tab-pane w--tab-active'>
                  <div className='flex-plann mobile-center-plann'>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $13 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$156 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Personal</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Personal Creator with channel &lt;500K
                                subscribers
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 100,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>1 channel/ platform</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f68831d3'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $39 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$468 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Professional </div>
                        </div>
                        <div className='badge-text'>Popular</div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Personal Creator with channel &gt;500K
                                subscribers
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>1 channel/ platform</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f688324c'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $149 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$1788 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Industrial</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Freelancers, Production House &amp; Media
                                Agency
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Multiple channels/ platform
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f68832c3'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan-copy'>
                          <h3 className='pricing-plan-quote'>
                            Within 24h quote
                          </h3>
                        </div>
                        <div className='top-margin-7-plan'>
                          <div className='subtitle-bold'>Custom quote</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Production House, Media Agency &amp;
                                Broadcaster
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Multiple channels/ platform
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f6883333'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                                style={{
                                  transform:
                                    'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                  transformStyle: 'preserve-3d',
                                }}
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div id='Intro' className='content-section-rich-text top-padding'>
        <div className='paragraph-10'>
          <a href='https://www.emvn.info/service-agreement'>
            <strong className='bold-text-8'>
              QUY ĐỊNH VÀ HÌNH THỨC THANH TOÁN
              <br />
            </strong>
          </a>
          Khách hàng sau khi lựa chọn xong dịch vụ của EMVN tại
          <a href='https://airtable.com/shr2kH3g6tLNFb5Vf'>EMVN Contact</a> có thể lựa
          chọn một trong hai hình thức thanh toán sau cho đơn hàng của mình.
          <br />
          <br />
          1.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            THANH TOÁN BẰNG TIỀN MẶT
          </p>
          <br />
          <p style={{ marginTop: 10 }}>
            Thực hiện giao dịch và thanh toán tiền mặt trực tiếp cho đơn hàng
            tại:
          </p>
          <ul>
            <li>
              Địa chỉ: Số 19, ngách 2, ngõ 90, đường Bưởi, Phường Ngọc Khánh,
              Quận Ba Đình, Thành phố Hà Nội, Việt Nam
            </li>
            <li>Số điện thoại liên hệ: 083 773 2900</li>
            <li>
              Yêu cầu: Sử dụng tiền Việt Nam Đồng thật và đủ tiêu chuẩn lưu
              thông theo quy định của Ngân hàng Nhà nước.
            </li>
          </ul>
          2.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            THANH TOÁN CHUYỂN KHOẢN
          </p>
          <br />
          <p style={{ marginTop: 10 }}>
            Chuyển khoản giá trị đơn hàng về Tài khoản:
          </p>
          <ul>
            <li>
              Ngân hàng TMCP Ngoại Thương Việt Nam (VIETCOMBANK) - Chi nhánh
              Thăng Long
            </li>
            <li>
              Chủ tài khoản: Công ty TNHH Giải trí Đa Phương Tiện EpicMusicVN
            </li>
            <li>Số tài khoản: 0491000074755</li>
            <li>Nội dung: “tên công ty/cá nhân thanh toan subscription” </li>
          </ul>
          3.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            CHI TIẾT CÁCH THỨC THANH TOÁN
          </p>
          <br />
          <br />
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Phân loại</th>
                <th scope='col' className='w-25'>
                  Cách thức thanh toán được chấp nhận
                </th>
                <th scope='col' className='w-25'>
                  Chi tiết
                </th>
                <th scope='col' style={{ width: '35%' }}>
                  Điều kiện thanh toán được chấp nhận
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row'>Trả trước</th>
                <td>Thẻ ATM (Thẻ ghi nợ/ thanh toán/trả trước nội địa)</td>
                <td>
                  Thẻ ghi nợ/ thanh toán/trả trước nội địa của các ngân hàng
                  trong nước phát hành có kết nối với cổng thanh toán.
                </td>
                <td>
                  Để thanh toán bằng thẻ ngân hàng nội địa, thẻ của khách hàng
                  phải được đăng ký sử dụng tính năng thanh toán trực tuyến,
                  hoặc ngân hàng điện tử của Ngân hàng. Giao dịch phải được ghi
                  nhận thành công từ thông báo cấp phép thành công do hệ thống
                  cổng thanh toán trả về (đảm bảo số dư/hạn mức và xác thực
                  kháchhàng theo quy định sử dụng của thẻ).
                </td>
              </tr>
              <tr>
                <th scope='row'> </th>
                <td>Thẻ tín dụng, thẻ thanh toán quốc tế</td>
                <td>
                  Thẻ tín dụng/ghi nợ/trả trước VISA. MasterCard, JCB, Union
                  Pay, Amex của các ngân hàng trong nước và nước ngoài phát
                  hành.
                </td>
                <td>
                  Giao dịch phải được ghi nhận cấp phép thành công do đứng hệ
                  thống cổng thanh toán trả về (đảm bảo số dư/hạn mức và xác
                  thực khách hàng theo quy định sử dụng của thẻ){' '}
                </td>
              </tr>
              <tr>
                <th scope='row'>Trả sau</th>
                <td>Thanh toán bằng tiền mặt</td>
                <td>
                  Thu tiền mặt khi khách đồng ý thanh toán giao dịch tại văn
                  phòng
                </td>
                <td>
                  Thanh toán bằng tiền Việt Nam Đồng thật và đủ tiêu chuẩn lưu
                  thông theo quy định của Ngân hàng Nhà nước.
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          4.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            CHÍNH SÁCH HOÀN TIỀN
          </p>
          <br />
          <p style={{ marginTop: 10 }}>
            Tại EMVN chúng tôi trân trọng sự tin tưởng của khách hàng khi sử
            dụng các dịch vụ sử dụng bản quyền âm nhạc hay subscription của
            chúng tôi. Chính sách hoàn tiền được xây dựng để quý khách có thể
            yên tâm lựa chọn và trải nghiệm dịch vụ của chúng tôi một cách trọn
            vẹn nhất.
          </p>
          <p style={{ marginTop: 10 }}>
            Việc đăng ký sử dụng subscription của EMVN cũng có nghĩa rằng khách
            hàng đồng ý và chấp thuận ràng buộc bởi các điều khoản của bản Chính
            sách hoàn tiền này cũng như các Chính sách sử dụng có liên quan
          </p>
          <p style={{ marginTop: 10 }}>
            Khách hàng có thể yêu cầu hoàn tiền 100% trong vòng 30 ngày đầu tiên
            kể từ ngày gói subscription được kích hoạt. Số tiền và hình thức
            hoàn trả sẽ phụ thuộc vào từng trường hợp cụ thể được quy định trong
            Chính sách hoàn tiền
          </p>
          4.1.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            DỊCH VỤ ÁP DỤNG HOÀN TIỀN
          </p>
          <br />
          <p style={{ marginTop: 10 }}>
            Chính sách hoàn tiền áp dụng cho tất cả các gói subscription mặc
            định của EMVN, bao gồm BASIC, PROFESSIONAL và PRENIUM.
          </p>
          4.2.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            ĐIỀU KIỆN HOÀN TIỀN
          </p>
          <br />
          <p style={{ marginTop: 10 }}>
            Khi yêu cầu hoàn tiền dịch vụ của EMVN, khách hàng phải thỏa mãn tất
            cả các điều kiện sau
          </p>
          <ul>
            <li>
              Đang dùng thử một trong các gói subscription thuộc phạm vi áp dụng
              chính sách này.
            </li>
            <li>
              Mỗi khách hàng chỉ được hoàn tiền một lần duy nhất với mỗi loại
              dịch vụ.
            </li>
            <li>
              Chính sách hoàn tiền chỉ áp dụng một lần duy nhất đối với mỗi
              khách hàng.
            </li>
            <li>
              Xuất trình thông tin hợp lệ cùng với hợp đồng dịch vụ hoặc hóa đơn
              dịch vụ.
            </li>
            <li>
              Chưa sử dụng tác phẩm thuộc gói subscription vào bất kỳ sản phẩm
              hoàn thiện nào và đăng tải lên bất kỳ nền tảng nào.
            </li>
            <li>
              Không thuộc các trường hợp bị từ chối hoàn tiền theo Chính sách
              này.
            </li>
          </ul>
          <p style={{ marginTop: 10 }}>
            Khi yêu cầu hoàn tiền dịch vụ của EMVN, khách hàng phải thỏa mãn tất
            cả các điều kiện sau
          </p>
          <ul>
            <li>
              Thông tin khách hàng cung cấp trùng với một trong số các thông tin
              trùng với dữ liệu khách hàng/dịch vụ đã được hoàn tiền trước đây:
              <ul>
                <li>
                  Các thông tin tài khoản: Tên khách hàng (cá nhân, tổ chức,
                  pháp nhân), Mã số thuế (Pháp nhân, cá nhân), Số CMND, số điện
                  thoại, email khách hàng (bao gồm email liên lạc và email xuất
                  hóa đơn VAT), ...bao gồm, nhưng không giới hạn các thông tin
                  cá nhân, tổ chức do khách hàng cung cấp hoặc EMVN thu thập
                  được trong quá trình cung cấp dịch vụ.
                </li>
                <li>
                  Các thông tin liên quan khác, bao gồm nhưng không giới hạn các
                  thông tin có liên quan do khách hàng cung cấp hoặc EMVN thu
                  thập được trong quá trình cung cấp dịch vụ.
                </li>
              </ul>
            </li>
            <li>
              Khách hàng không cung cấp được các thông tin, giấy tờ bắt buộc đã
              sử dụng để đăng ký gói subscription.
            </li>
            <li>
              Khách hàng đã sử dụng tác phẩm thuộc gói subscription vào sản phẩm
              hoàn thiện và đăng tải lên bất kỳ nền tảng nào.
            </li>
          </ul>
          <p style={{ marginTop: 10 }}>
            Khách hàng đồng ý rằng, để bảo vệ lợi ích các bên, việc tiếp nhận và
            xác minh yêu cầu hoàn tiền không loại trừ trường hợp EMVN có lý do
            để tin rằng thông tin được cung cấp là không hợp lệ. Chúng tôi có
            quyền chủ động tiến hành mọi biện pháp kiểm tra, xác minh trong phạm
            vi hợp lý và thông báo bằng kết quả bằng văn bản cho khách hàng.
          </p>
          <p style={{ marginTop: 10 }}>
            Kể từ thời điểm chúng tôi xác nhận yêu cầu hoàn tiền bằng một email
            đến tài khoản đăng ký, quyền sử dụng gói subscription của khách hàng
            cũng theo đó chấm dứt.
          </p>
          <p style={{ marginTop: 10 }}>
            Chính sách hoàn tiền không áp dụng với các gói subscription được
            hưởng khuyến mãi theo bất kỳ hình thức nào (Mã giảm giá, giảm giá
            trực tiếp, v..v..).
          </p>
          4.3.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            CÁCH THỨC HOÀN TIỀN
          </p>
          <p style={{ marginTop: 10 }}>
            Trong vòng 30 ngày đầu sử dụng gói subscription, nếu có phát sinh
            nhu cầu hủy dịch vụ, quý khách vui lòng tham khảo các trường hợp áp
            dụng tại đây và gửi yêu cầu cho chúng tôi với nội dung nhu sau:
          </p>
          <ul>
            <li>
              Gửi email về địa chỉ care@emvn.co với tiêu đề email: YÊU CẦU HOÀN
              TIỀN SUBSCRIPTION
            </li>
            <li>
              Nội dung email:
              <ul>
                <li>Yêu cầu hoàn tiền cho gói “tên gói subscription”</li>
                <li>
                  Email đăng ký tài khoản - Số điện thoại đăng ký tài khoản
                </li>
                <li>Chi tiết số tài khoản nhận khoản hoàn trả</li>
                <li>Tài liệu đính kèm: Hợp đồng dịch vụ/ Hóa đơn dịch vụ. </li>
                <li>Lý do yêu cầu hủy gói</li>
              </ul>
            </li>
            <li>
              Vui lòng cung cấp đúng thông tin đã sử dụng đăng ký tài khoản.
              Khách hàng chịu mọi trách nhiệm với các thông tin cung cấp.
            </li>
            <li>
              Số tiền được hoàn trả vào tài khoản do khách hàng cung cấp tại
              thời điểm đăng ký sử dụng gói subscription trong vòng 30 ngày kể
              từ ngày EMVN xác nhận việc hoàn tiền.
            </li>
            <li>
              Sau thời hạn trên, trong vòng 03 ngày, nếu có bất kỳ vấn đề khác
              phát sinh, vui lòng liên hệ về địa chỉ email care@emvn.co hoặc
              Hotline: 083 773 2900 nêu rõ nội dung phát sinh để được hỗ trợ.
              EMVN sẽ không giải quyết bất kỳ vấn đề nào khi hết thời hạn này.
            </li>
          </ul>
          4.4.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            HOÀN TRẢ TRỄ HOẶC THIẾU (NẾU CÓ)
          </p>
          <p style={{ marginTop: 10 }}>
            Nếu hết thời hạn 30 ngày hoàn tiền, khách hàng chưa nhận được khoản
            tiền hoàn lại hoặc khoản tiền hoàn lại bị thiếu, vui lòng thực hiện
            các thao tác kiểm tra sau:
          </p>
          <ul>
            <li>Kiểm tra lại tài khoản ngân hàng của bạn.</li>
            <li>
              Liên hệ với ngân hàng, công ty phát hành thẻ tín dụng của bạn.
              Thường có một số thời gian xử lý trước khi khoản tiền hoàn lại
              được thông báo.
            </li>
            <li>
              Nếu bạn đã thực hiện tất cả những điều này mà vẫn chưa nhận được
              tiền hoàn lại, vui lòng liên hệ ngay với chúng tôi tại địa chỉ
              email care@emvn.co hoặc Hotline: 083 773 2900.
            </li>
          </ul>
          5.
          <p
            style={{ display: 'inline', fontWeight: 'bold' }}
            className='bold-text-8'
          >
            LIÊN HỆ VỚI CHÚNG TÔI
          </p>
          <p style={{ marginTop: 10 }}>
            Nếu có bất kỳ thắc mắc nào liên quan đến Chính sách hoàn tiền, khách
            hàng có thể liên hệ với EMVN bằng cách gửi email tới địa chỉ
            care@emvn.co. EMVN rất vui lòng và sẵn sàng hỗ trợ mọi vấn đề liên
            quan đến Chính sách hoàn tiền này.
          </p>
          <p style={{ marginTop: 10 }}>
            EMVN có toàn quyền thay đổi, bổ sung nội dung cho chính sách hoàn
            tiền này mà không cần phải thông báo trước với khách hàng.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};
