/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';

export const PrivacyPolicy = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/privacy_policy.jpeg'
        mainTitle='CHÍNH SÁCH'
        subTitle='BẢO MẬT'
      />
      <div id='Intro' className='content-section-rich-text top-padding'>
        <p className='paragraph-10'>
          Tại EMVN việc bảo vệ dữ liệu cá nhân của Người dùng là ưu tiên hàng
          đầu. Chúng tôi hiểu rằng, &nbsp;các dữ liệu của Người dùng là thuộc
          quyền sở hữu hợp pháp và được pháp luật bảo vệ, chính vì vậy, việc thu
          thập, lưu giữ, xử lý Dữ liệu cá nhân chỉ được EMVN thực hiện khi có sự
          đồng ý và cho phép của Người dùng.
          <br />
          Để truy cập và sử dụng Trang Web, Dịch vụ ngoài việc đồng ý về điều
          khoản sử dụng thì người dùng còn phải đọc kỹ những chính sách bảo mật
          của EMVN sau đây. Việc đăng ký sử dụng Trang Web, Dịch vụ của EMVN
          cũng có nghĩa rằng Người dùng đã đồng ý và chấp thuận ràng buộc bởi
          các điều khoản của bản Chính sách bảo mật này.
          <br />
          Chính sách bảo mật giải thích cách EMVN thu thập, lưu giữ và xử lý Dữ
          liệu cá nhân trong quá trình Người dùng truy cập Trang Web hoặc sử
          dụng Dịch vụ của chúng tôi. <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            ĐỊNH NGHĨA <br />‍
          </strong>
          Các thuật ngữ viết hoa trong Chính sách bảo mật này có ý nghĩa như
          sau:
          <br />
          <br />“<strong>EMVN</strong>” nghĩa là Công ty TNHH Giải trí Đa Phương
          tiện EpicMusicVN. &nbsp;Trong Chính sách bảo mật này, &nbsp;EMVN có
          thể &nbsp;được đề cập là “chúng tôi” hoặc “của chúng tôi”, tùy theo
          ngữ cảnh.“
          <strong>Dịch vụ</strong>” nghĩa là mọi sản phẩm, dịch vụ, nội dung,
          tính năng, công nghệ hay chức năng, cũng như tất cả các Trang Web, ứng
          dụng và dịch vụ liên quan do EMVN &nbsp;cung cấp cho người dùng.“
          <strong>Trang Web</strong>” nghĩa là các Trang Web, ứng dụng di động,
          nền tảng mạng xã hội chính thức hoặc các thuộc tính trực tuyến khác mà
          qua đó EMVN cung cấp Dịch vụ và đã đăng tải hoặc liên kết với Chính
          sách bảo mật này.
          <br />
          <br />“<strong>Người dùng</strong>” nghĩa là cá nhân sử dụng Dịch vụ
          hoặc truy cập các Trang Web.
          <br />
          <br />“<strong>Dữ liệu cá nhân</strong>” có thể bao gồm tên, địa chỉ
          gửi thư (bao gồm cả địa chỉ thanh toán và địa chỉ giao hàng), số điện
          thoại, địa chỉ email, số thẻ thanh toán, thông tin tài khoản tài chính
          khác, số tài khoản, ngày sinh và thông tin chứng nhận do cơ quan có
          thẩm quyền cấp (ví dụ: số giấy phép lái xe, số căn cước, hộ chiếu, và
          mã số người nộp thuế). &nbsp;Dữ liệu cá nhân không bao gồm các thông
          tin không xác định danh tính của một Người dùng cụ thể.
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            MỤC ĐÍCH VÀ PHẠM VI THU THẬP
            <br />‍
          </strong>
          Việc thu thập Dữ liệu cá nhân trên Trang Web là nhằm:
          <br />- Tuân thủ các quy định pháp luật, thực hiện các nghĩa vụ pháp
          lý yêu cầu cung cấp hệ thống Dữ liệu cá nhân; <br />- Thực hiện các
          quy trình, thủ tục nhằm đảm bảo mục đích kinh doanh của chúng tôi và
          quyền lợi của Người dùng ;<br />- Đảm bảo việc lưu trữ hệ thống thông
          tin của người sử dụng trong hệ thống của EMVN <br />- Tạo cơ sở để
          EMVN &nbsp;giải quyết những vấn đề , tranh chấp (nếu có) xảy ra.
          <br />‍<br />
          Trong phạm vi về quyền thu thập thông tin của người dùng, EMVN thu
          thập các Dữ liệu cá nhân khi người dùng truy cập các Trang Web hay sử
          dụng Dịch vụ của chúng tôi, bao gồm những thông tin sau:
          <br />- Thông tin đăng ký sử dụng: Khi Người dùng đăng ký sử dụng Dịch
          vụ của chúng tôi bằng cách tạo Tài khoản, chúng tôi sẽ thu thập Dữ
          liệu cá nhân cần thiết để thực hiện Dịch vụ được yêu cầu, bao gồm: Họ
          tên, ngày tháng năm sinh, địa chỉ, giấy tờ tùy thân, Email đăng ký tài
          khoản, số điện thoại. Với các thông tin này, người dùng sẽ tự chịu
          trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ trên
          Trang Web. EMVN &nbsp;có thể yêu cầu bạn cung cấp Dữ liệu cá nhân bổ
          sung trong quá trình sử dụng Dịch vụ.
          <br />- Thông tin giao dịch và trải nghiệm: &nbsp;Khi người dùng sử
          dụng các phương thức thanh toán cho Dịch vụ của EMVN, chúng tôi sẽ thu
          thập thông tin về các giao dịch này, cũng như thông tin khác liên quan
          đến giao dịch, bao gồm nhưng không giới hạn: số tiền thanh toán cho
          các sản phẩm hoặc dịch vụ, thông tin về tài khoản thanh toán, phương
          thức thanh toán được sử dụng để hoàn tất giao dịch, thông tin về thiết
          bị, dữ liệu sử dụng kỹ thuật và thông tin vị trí địa lý.
          <br />- Thông tin bạn chọn cung cấp cho chúng tôi để có được Dịch vụ
          bổ sung cụ thể: Khi người dùng yêu cầu thêm các Dịch vụ khác
          &nbsp;hoặc các chức năng tùy chọn khác, chúng tôi có thể cần thu thập
          thêm thông tin. &nbsp; <br />- Thông tin khác liên quan đến việc người
          dùng sử dụng các Trang Web hoặc Dịch vụ của EMVN: Khi người dùng liên
          lạc với EMVN, liên hệ với bộ phận chăm sóc khách hàng của EMVN hoặc
          trả lời khảo sát, chúng tôi có thể thu thập thêm thông tin cần thiết
          từ hoặc về người dùng.{' '}
          <strong className='bold-text-8'>
            {' '}
            <br />
            <br />
            <br />
            PHƯƠNG THỨC THU THẬP
            <br />‍
          </strong>
          Khi bạn truy cập Trang Web, sử dụng Dịch vụ EMVN có thể sử dụng cookie
          và các công nghệ theo dõi khác (gọi chung là "Cookie") để nhận ra và
          tùy chỉnh trải nghiệm trực tuyến của Người dùng, các Dịch vụ được sử
          dụng, nội dung trực tuyến và quảng cáo; đo lường hiệu quả của chương
          trình khuyến mãi và thực hiện phân tích; và để giảm thiểu rủi ro, ngăn
          chặn gian lận tiềm ẩn và nâng cao độ tin cậy và an toàn trên các Trang
          Web và Dịch vụ của EMVN. &nbsp;
          <br />
          Để tránh nhầm lẫn, việc sử dụng Cookie chỉ được thực hiện dưới sự cho
          phép của Người dùng. Tuy nhiên, lư ý rằng, một số tính năng của các
          Dịch vụ và Trang Web của chúng tôi chỉ khả dụng khi sử dụng Cookie. Vì
          vậy, nếu bạn chọn tắt hoặc từ chối Cookie, việc sử dụng các Trang Web
          và Dịch vụ của bạn có thể bị giới hạn hoặc không thể thực hiện được.
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            PHẠM VI SỬ DỤNG DỮ LIỆU CÁ NHÂN
            <br />‍
          </strong>
          EMVN sử dụng các Dữ liệu cá nhân với sự chấp thuận của người dùng,
          nhằm thực hiện các mục đích được mô tả trong Chính sách bảo mật này,
          hoặc khi EMVN đánh giá việc này là cần thiết vì mục đích của lợi ích
          hợp pháp của các bên. Bao gồm:
          <br />- Vận hành Trang Web và cung cấp Dịch vụ: Dữ liệu cá nhân thu
          thập được cho phép EMVN thường xuyên liên lạc với Người dùng về các
          Dịch vụ mới nhất, cập nhật thông tin, sự kiện sắp tới của EMVN. Để
          tránh nhầm lẫn, Người dùng có quyền lựa chọn có/không tham gia vào
          danh sách gửi thư của EMVN.
          <br />- Gửi thông báo quan trọng liên quan đến Dịch vụ: Dữ liệu cá
          nhân thu thập được cho phép EMVN gửi các thông báo quan trọng như:
          thông tin về giá, các thay đổi đối với điều khoản, điều kiện và chính
          sách của EMVN. Vì đây là thông tin quan trọng đối với hoạt động của
          các bên nên người dùng không thể từ chối nhận các thông báo này.
          <br />- Cá nhân hóa trải nghiệm của người dùng: Dữ liệu cá nhân thu
          thập được cho phép EMVN đề xuất, cung cấp cho người dùng các Dịch vụ,
          lựa chọn, ưu đãi phù hợp.
          <br />- Đáp ứng yêu cầu phát sinh tức thời của Người dùng, ví dụ: để
          liên hệ với bạn về thắc mắc bạn đã gửi cho bộ phận chăm sóc khách hàng
          của chúng tôi.
          <br />- Quản lý rủi ro và bảo vệ các Trang Web, Dịch vụ và Người dùng
          tránh khỏi gian lận bằng cách xác minh danh tính người dùng. Dữ liệu
          cá nhân thu thập được cùng với các thông tin thiết bị, dữ liệu kỹ
          thuật, vị trí địa lý giúp EMVN phát hiện và ngăn chặn gian lận và lạm
          dụng các Dịch vụ. <br />- Quản lý, phát triển hoạt động kinh doanh: Dữ
          liệu cá nhân thu thập được cho phép EMVN &nbsp;theo dõi, phân tích và
          cải thiện Dịch vụ, Trang Web, quy trình chăm sóc khách hàng. <br />-
          Thực hiện nghĩa vụ của chúng tôi với Chính sách bảo mật này cũng như
          đối với tất cả các luật và quy định hiện hành.
          <br />
          <strong className='bold-text-8'>
            <br />
            <br />
            CHIA SẺ DỮ LIỆU CÁ NHÂN <br />‍
          </strong>
          EMVN cam kết không cung cấp, chia sẻ phát tán Dữ liệu cá nhân mà mình
          thu thập, quản lý cho bên thứ ba bất kỳ, &nbsp;trừ trường hợp có sự
          đồng ý của Người dùng đó.
          <br />
          Trong một vài trường hợp, EMVN có thể tiết lộ Dữ liệu người dùng cho
          bên thứ ba, cụ thể như sau: <br />- Khi có sự đồng ý của Người dùng.
          <br />- Đáp ứng yêu cầu của Người dùng hoặc cải thiện Dịch vụ của
          chúng tôi, bao gồm nhưng không giới hạn việc liên kết thanh toán, giao
          nhận hợp đồng, chứng từ liên quan đến Dịch vụ theo yêu cầu của người
          dùng. Trong những trường hợp này, chúng tôi yêu cầu các bên thứ ba đó
          phải đảm bảo xử lý các Dữ liệu này theo luật pháp liên quan. <br />-
          Theo pháp luật, quy trình pháp lý, kiện tụng và/hoặc có yêu cầu của cơ
          quan có thẩm quyền. <br />- Trường hợp EMVN tin rằng hoặc có lý do để
          tin rằng, việc tiết lộ Dữ liệu cá nhân là cần thiết hoặc phù hợp để
          phát hiện, ngăn chặn và xử lý hành vi gian lận, hành vi sử dụng trái
          phép Dịch vụ, hành vi vi phạm các điều khoản hoặc chính sách của chúng
          tôi hay hoạt động phi pháp hoặc có hại khác; để bảo vệ chính chúng tôi
          (gồm quyền, tài sản, Dịch vụ, ngăn chặn tổn thất tài chính hoặc thiệt
          hại vật chất), bạn hoặc những người khác; vì mục đích an ninh quốc
          gia, thực thi pháp luật hoặc các vấn đề khác.
          <br />- Ngoài ra, trong trường hợp tổ chức lại, sáp nhập, EMVN có thể
          chuyển giao bất kỳ và tất cả Dữ liệu cá nhân chúng tôi thu thập được
          cho bên thứ ba có liên quan.
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            THỜI GIAN LƯU TRỮ DỮ LIỆU CÁ NHÂN
            <br />‍
          </strong>
          Việc lưu trữ Dữ liệu cá nhân sẽ được bảo mật, lưu trữ tại máy chủ của
          EMVN và chỉ được hủy bỏ khi có yêu cầu từ Người dùng.
          <strong className='bold-text-8'>
            {' '}
            <br />
            <br />
            <br />
            ĐỊA CHỈ THU THẬP VÀ LƯU TRỮ DỮ LIỆU CÁ NHÂN
            <br />‍
          </strong>
          Mọi Dữ liệu cá nhân khi thu thập được sẽ được lưu trữ tại máy chủ của
          EMVN tại địa chỉ:
          <br />
          CÔNG TY TNHH GIẢI TRÍ ĐA PHƯƠNG TIỆN EPICMUSICVN
          <br />
          Địa chỉ liên lạc: số 19, Ngách 02, Ngõ 90, Đường Bưởi, Phường Ngọc
          Khánh, Quận Ba Đình, Thành phố Hà Nội, Việt Nam
          <br />
          Email: contact@emvn.co
          <br />
          <strong className='bold-text-8'>
            <br />
            <br />
            <br />
            PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU
            CÁ NHÂN <br />‍
          </strong>
          Người dùng có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ
          thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh
          sửa thông tin cá nhân hoặc yêu cầu EMVN thực hiện việc này.
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            CAM KẾT BẢO MẬT DỮ LIỆU CÁ NHÂN
            <br />‍
          </strong>
          EMVN luôn coi trọng việc bảo mật Dữ liệu cá nhân của Người dùng. Chúng
          tôi duy trì các biện pháp bảo mật bằng kỹ thuật, vật lý và quản trị
          được thiết kế để cung cấp biện pháp bảo vệ hợp lý cho Dữ liệu cá nhân
          của Người dùng khỏi bị thất lạc, lạm dụng, truy cập trái phép, tiết lộ
          và thay đổi.
          <br />- Dữ liệu cá nhân của Người dùng trên Trang Web được EMVN cam
          kết bảo mật tuyệt đối theo Chính sách bảo mật này. Việc thu thập và sử
          dụng chỉ được thực hiện khi có sự đồng ý của Người dùng hoặc theo yêu
          cầu của cơ quan nhà nước có thẩm quyền.
          <br />- Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên
          thứ ba nào về Dữ liệu cá nhân khi không có sự cho phép từ Người dùng.
          <br />- Trong trường hợp máy chủ lưu trữ dữ liệu bị hacker tấn công
          dẫn đến mất mát dữ liệu cá nhân, EMVN sẽ có trách nhiệm thông báo vụ
          việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho
          người sử dụng được biết. EMVN sẽ không phải chịu trách nhiệm phát sinh
          liên quan đến việc mất mát dữ liệu này của Người dùng.
          <br />- EMVN yêu cầu các cá nhân khi đăng ký là thành viên, phải cung
          cấp đầy đủ thông tin cá nhân có liên quan như: Họ và tên, địa chỉ liên
          lạc, email, số chứng minh nhân dân, điện thoại …., và chịu trách nhiệm
          về tính pháp lý của những thông tin trên. EMVN không chịu trách nhiệm
          cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của
          Người dùng đó nếu xét thấy tất cả thông cung cấp khi đăng ký ban đầu
          là không chính xác.
          <br />- Người dùng có quyền gửi khiếu nại về việc lộ thông tin các
          nhân cho bên thứ ba đến EMVN. Khi tiếp nhận những phản hồi này, EMVN
          sẽ xác nhận lại thông tin, phải có trách nhiệm trả lời lý do và hướng
          dẫn người dùng khôi phục và bảo mật lại thông tin.
          <br />- Trường hợp EMVN có sửa đổi, bổ sung thì không cần phải thông
          báo trước cho Người dùng mà mặc định việc sửa đổi, bổ sung này. Người
          dùng phải thường xuyên kiểm tra lại để có các bản cập nhật cho Chính
          sách bảo mật này. Trong trường hợp EMVN cập nhật Chính sách bảo mật và
          người dùng tiếp tục sử dụng Dịch vụ sau khi cập nhật, điều đó có nghĩa
          là người dùng đồng ý với (các) điều khoản mới được xác định trong bản
          cập nhật.
          <br />- Mặc dù chúng tôi cam kết bảo vệ hệ thống và Dịch vụ của chúng
          tôi nhưng Người dùng vẫn có trách nhiệm bảo vệ và duy trì quyền riêng
          tư đối với mật khẩu và thông tin đăng ký Tài khoản/hồ sơ của mình và
          xác minh rằng Dữ liệu cá nhân chúng tôi đang lưu giữ là chính xác và
          luôn được cập nhật. &nbsp;
          <br />- Người dùng tự chịu trách nhiệm đối với thông tin cá nhân khi
          chia sẻ về sản phẩm, dịch vụ của EMVN trên các nền tảng. &nbsp;Vui
          lòng cẩn thận khi chia sẻ các thông tin này.
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            LIÊN HỆ VỚI CHÚNG TÔI
            <br />‍
          </strong>
          Nếu có bất kỳ thắc mắc nào liên quan đến Chính sách bảo mật, người
          dùng có thể liên hệ với EMVN bằng cách gửi email tới địa chỉ
          contact@emvn.co. EMVN rất vui lòng và sẵn sàng hỗ trợ người dùng liên
          quan đến Chính sách bảo mật này.
          <br />
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
};
