import React, { Component, ReactNode } from 'react';
import { History } from 'history';

// COMPONENT
import {
  Row,
  Col,
  Menu,
} from 'antd';

// STYLE
import './index.scss';

// DEFAULT AVATAR
import Avatar from '../../assets/images/default-avatar.png';
import ReactLoading from 'react-loading';

// MODEL
import { User } from '../../model/user';

// MODULE
import Profile from './module/profile';
import Plan from './module/plan';
import ClearVideo from './module/clearvideo';
import Licenses from './module/licenses';

interface TProps {
  user: User;
  history: History;
}

interface TState {
  activeMenu: number;
  activeParent: number;
  user?: User;
  isLoading: boolean;
}

const menu = [
  { id: 0, title: 'Profile', parent: 1 },
  { id: 1, title: 'Subscriptions', parent: 1 },
  // { id: 2, title: 'Licenses and receipts', parent: 1 },
  { id: 3, title: 'Clear videos', parent: 1 },
  // { id: 4, title: 'Setting', parent: 1 },
];

const { SubMenu } = Menu;

export default class AccountManagement extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      activeMenu: 0,
      activeParent: 1,
      user: undefined,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
  }

  UNSAFE_componentWillReceiveProps(nextProps: TProps) {
    this.setState({ user: nextProps.user });
  }

  changeLoadingStatus = (status: boolean, callback: (data?: any) => void) => {
    this.setState({ isLoading: status }, () => {
      callback();
    });
  };

  loadingScreen = () => {
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.7,
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <ReactLoading type='cylon' color='#fff' height={100} width={100} />
        </div>
      </div>
    );
  };

  render() {
    const { activeMenu, activeParent, user, isLoading } = this.state;
    const { history } = this.props;

    let activeTitle: string = '';

    let component: ReactNode = null;

    switch (activeParent) {
      case 1:
        activeTitle = menu.find((v) => v.id === activeMenu)!.title;
        break;
      case 2:
        activeTitle = 'My Music';
        break;
      case 3:
        activeTitle = 'Referral';
        break;
    }

    switch (activeMenu) {
      case 0:
        component = <Profile history={history} user={user} />;
        break;
      case 1:
        component = <Plan history={history} user={user} />;
        break;
      case 2:
        component = <Licenses history={history} user={user} />;
        break;
      case 3:
        component = (
          <ClearVideo
            history={history}
            user={user}
            changeLoadingStatus={(status, callback) =>
              this.changeLoadingStatus(status, callback)
            }
          />
        );
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        break;
    }

    return (
      <>
        {isLoading && this.loadingScreen()}

        <div className='account-management'>
          <div className='custom-container'>
            <Row gutter={[16, 8]} className='profile-menu-wrapper'>
              <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
                <div className='profile-menu-wrapper-title-wrapper'>
                  <div className='default-avartar'>
                    <img
                      src={Avatar}
                      alt='default'
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <p className='profile-menu-profile-name'>
                    {user?.displayName}
                  </p>
                </div>
                <hr className='profile-page-hr' />
                <Menu
                  key={activeParent}
                  style={{ width: 256 }}
                  selectedKeys={[`${activeMenu}`]}
                  defaultOpenKeys={
                    activeParent === 1 ? [`${activeParent}`] : []
                  }
                  mode='inline'
                  className='profile-menu'
                >
                  <SubMenu
                    key={1}
                    title='My Account'
                    onTitleClick={() =>
                      activeParent !== 1 &&
                      this.setState({ activeParent: 1, activeMenu: 0 })
                    }
                  >
                    {menu.map((v) => {
                      return (
                        <Menu.Item
                          key={v.id}
                          className='profile-menu-sub-item'
                          onClick={() => this.setState({ activeMenu: v.id })}
                        >
                          {v.title}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                  {/* <Menu.Item
                  className='parent-menu'
                  onClick={() =>
                    this.setState({ activeParent: 2, activeMenu: 5 })
                  }
                >
                  My Music
                </Menu.Item>

                <Menu.Item
                  className='parent-menu'
                  onClick={() =>
                    this.setState({ activeParent: 3, activeMenu: 6 })
                  }
                >
                  Referral
                </Menu.Item> */}
                </Menu>
              </Col>
              <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
                <div className='profile-content-wrapper-title-wrapper'>
                  <p className='profile-content-name'>{activeTitle}</p>
                </div>
                <hr className='profile-page-hr-right' />
                <div className='profile-content-wrapper-main'>{component}</div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
