export enum Service {
  LicenseWeb = 'license-web',
}

export interface Payment {
  amount: number;
  descrition: string;
  order_id: string;
  service: Service;
  metadata: any;
}
