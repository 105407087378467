/* eslint-disable no-empty-pattern */
import React from 'react';

// COMPONENT
import { Footer } from '../../components/footer';

export const AboutUs = ({}: any) => {
  return (
    <div className='content'>
      <div id='story' className='content-section au'>
        <div className='hero-content-wrapper no-overflow w-container'>
          <div className='flex aboutus'>
            <div className='medium-left-column ab'>
              <div className='medium-text-box-copy'>
                <div className='top-margin-left _10-pixels'>
                  <h2 className='h2-left big'>
                    Về <span className='text-span-9'>EMVN</span>
                  </h2>
                </div>
                <div className='div-block-35'>
                  <div className='top-margin-7 _20-pixel'>
                    <div className='paragraph-large'>
                      EMVN, also known as Epic Music VN, founded in 2015. We’re
                      a team of hard working music lovers &amp; programmers who
                      share the same dream - to help Vietnamese creators access
                      world-class music in a simple, affordable and safe way.{' '}
                      <div style={{ marginTop: 20 }}>
                        We are proud of our growing music library with more than
                        500,000+ exclusive, high-quality tracks &amp; 300,000+
                        SFX for use in advertising, film, TV &amp; video games.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pictures-column ab'>
              <div
                data-w-id='a3dd6bdd-a040-5e6c-27aa-5a154aa91437'
                className='picture-frame-3'
              />
              <div
                data-w-id='5f680e47-e641-a139-5597-6124a7cee465'
                className='picture-frame-2'
              >
                <img
                  src='/assets/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min.jpg'
                  width='465'
                  srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min-p-2600.jpeg 2600w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min-p-3200.jpeg 3200w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d03bc08517addf020daa_jan-strecha-X829kDjTcxo-unsplash-min.jpg 5616w'
                  sizes='(max-width: 479px) 100vw, (max-width: 767px) 207.45669555664062px, (max-width: 991px) 320.2048645019531px, 50vw'
                  alt=''
                />
              </div>
              <div
                data-w-id='e82e7826-3ce0-471a-efb1-fa2f524c813a'
                className='picture-frame'
              >
                <img
                  src='/assets/5f10b77291eedd7c20c4a4b0_photography-of-woman-listening-to-music-761963-min.jpg'
                  width='467'
                  srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f10b77291eedd7c20c4a4b0_photography-of-woman-listening-to-music-761963-min-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f10b77291eedd7c20c4a4b0_photography-of-woman-listening-to-music-761963-min.jpg 1134w'
                  sizes='(max-width: 479px) 100vw, (max-width: 767px) 40vw, (max-width: 991px) 263.20001220703125px, 466px'
                  alt=''
                  className='image-36'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
