import React, { Component } from 'react';
import { History } from 'history';
import { Button, Row, Col } from 'antd';

// STYLE
import './index.scss';

// MODEL
import { User } from '../../../../model/user';

// COMPONENT
import PlanModule from './component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// DATA
import { DataPlan } from '../../../../model/data';

const faYoutubeIP = faYoutube as IconProp;

interface TProps {
  user?: User;
  history: History;
}

interface TState {
  user?: User;
}

export default class Plan extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      user: undefined,
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
  }

  componentWillReceiveProps(nextProps: TProps) {
    this.setState({ user: nextProps.user });
  }

  render() {
    const { user } = this.state;
    const { history } = this.props;

    if (
      user &&
      user.srcAudioMeta &&
      user.srcAudioMeta.data &&
      user.srcAudioMeta.data.length > 0
    ) {
      const dataPlan = user.srcAudioMeta.data;

      const style = {
        color: '#FFFFFF',
        opacity: 0.5,
        fontSize: 15,
        fontFamily: 'Montserrat',
      };

      const style1 = {
        color: '#FFFFFF',
        opacity: 1,
        fontSize: 15,
        fontFamily: 'Montserrat',
      };

      return (
        <div className='profile-plan-information-wrapper'>
          {dataPlan.map((v, i) => {
            const plan = DataPlan.find((p) => p.id === v.planId);

            if (!plan) return null;

            return (
              <div key={i}>
                <div className='personal-information'>
                  <p className='profile-information-title'>{plan.modelTitle}</p>
                </div>
                <Row gutter={[16, 8]} className='pricing-plan-wrapper'>
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <p style={{ ...style }}>Next billing</p>
                    <p style={{ ...style1 }}>{v.expired}</p>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <p style={{ ...style }}>Price</p>
                    <p style={{ ...style1 }}>{v.amount}</p>
                  </Col>
                </Row>

                <div className='personal-information'>
                  <p className='profile-information-title'>Channels</p>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={faYoutubeIP} size='2x' />
                  <p
                    style={{
                      margin: 0,
                      color: '#fff',
                      marginLeft: 10,
                      fontSize: 15,
                    }}
                  >
                    {v.channel}
                  </p>
                </div>

                <hr className='profile-page-hr-right-plan' />
              </div>
            );
          })}

          <Button
            className='submit-profile-information'
            style={{ borderColor: 'unset' }}
            onClick={() => this.props.history.push('/pricing')}
          >
            <span>Add new subscription</span>
          </Button>

          {/* <div className='personal-information'>
            <p
              className='profile-information-title'
              style={{ marginBottom: 0 }}
            >
              Channels
            </p>
          </div>
          <p className='channel-plan-text'>
            By adding one channel per platform* below, we can clear the content
            you publish on those channels featuring our music and keep it safe
            from copyright claims. read more here
          </p>
          <div className='add-youtube-channel'>
            <div className='add-youtube-channel-icon'>
              <FontAwesomeIcon icon={faYoutubeIP} size='2x' />
            </div>
            <div className='add-youtube-channel-label'>
              <Input
                placeholder='Add your Youtube account'
                className='profile-information-input'
              />
            </div>
          </div>
          <p className='channel-plan-text' style={{ color: '#B7AFA7' }}>
            *Once you have saved a channel on a platform, you can’t change to
            different one. Need help? Visit Customer Support
          </p>
          <hr className='profile-page-hr-right-plan' />
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: 5 }}>
              <Button
                className='submit-profile-information'
                style={{ borderColor: 'unset' }}
              >
                <span>Add subscription</span>
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 5 }}>
              <Button
                className='submit-profile-information'
                style={{ borderColor: 'unset' }}
              >
                <span>Add Channel</span>
              </Button>
            </div>
          </div> */}
        </div>
      );
    }

    return (
      <div
        className='profile-plan-information-wrapper'
        style={{ width: '100%' }}
      >
        <div className='personal-information'>
          <p className='profile-information-title'>
            You are currently not subscribed to any plan.
          </p>
        </div>
        <PlanModule isAuthenticated={true} history={history} />
      </div>
    );
  }
}
