/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

// COMPONENT
import { Footer } from '../../components/footer';
import '../../assets/css/emvn.webflow.39ad2354b.min.css';
import '../../assets/css/other.css';
import './index.scss';

export const LandingPage = () => {
  return (
    <div className='content' style={{ overflowY: 'scroll' }}>
      <div
        data-w-id='bdce0165-097e-91ec-4559-17f7badeded6'
        style={{ width: '100%', height: '100%' }}
        className='hero-full-bg h50'
      >
        <div
          data-poster-url='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-poster-00001.jpg'
          data-video-urls='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-transcode.mp4,https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-transcode.webm'
          data-autoplay='true'
          data-loop='true'
          data-wf-ignore='true'
          className='background-video-2 w-background-video w-background-video-atom'
        >
          <video
            autoPlay
            loop
            style={{
              backgroundImage:
                'url("https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-poster-00001.jpg")',
            }}
            muted
            playsInline
            data-wf-ignore='true'
            data-object-fit='cover'
          >
            <source
              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-transcode.mp4'
              data-wf-ignore='true'
            />
            <source
              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0d3246cf63ccb383f0cd73_EMVN timeline 2019 30mb v2-transcode.webm'
              data-wf-ignore='true'
            />
          </video>
        </div>
      </div>
      <div id='Top' className='hero-section-phone home-phone'>
        <div className='center-flex home'>
          <div
            data-w-id='31a1dd21-f888-95fe-e81e-98bdeb19dc27'
            className='hero-content-wrapper h50 w-container'
          >
            <div
              data-w-id='eef64d65-9d98-d058-8e25-b79b8dee9a93'
              className='center-hero-text'
            >
              <div className='top-margin-7 _15-pixels'>
                <div className='hero-heading-box-copy'>
                  <h1
                    className='hero-home-heading-copy'
                    data-ix='new-interaction'
                  >
                    Music for
                    <br />
                    <span className='text-span-2'>Creators</span>
                  </h1>
                </div>
              </div>
              <div className='top-margin-7'>
                <div className='subtitle-box'>
                  <p className='hero-subtitle'>
                    Discover the perfect sounds for your videos.
                  </p>
                </div>
              </div>
              <div className='top-margin-7 _25-pixels button-banner'>
                <a
                  href='https://emvn.sourceaudio.com/'
                  id='Button-2'
                  data-w-id='f4050b78-2f08-f6f2-a1c9-ca1b8650dfb9'
                  target='_blank'
                  className='button yellow-shadow w-inline-block w-clearfix'
                  data-ix='new-interaction'
                >
                  <div className='button-left'>
                    <div className='text-block-2 text-block-7'>
                      EXPLORE MUSIC
                    </div>
                  </div>
                  <div className='button-right'>
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                      width={7}
                      alt=''
                      className='button-arrow'
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='feature-section'>
        <div className='column-text-image w-row'>
          <div className='column-typo w-col w-col-6'>
            <div>
              <div className='bold-text-black-55pt'>
                Get full access to{' '}
                <span className='bold-text-yellow-55pt'>500.000+</span> Tracks
                and <span className='bold-text-yellow-55pt'>300.000+</span>{' '}
                sound effects
              </div>
            </div>
          </div>
          <div
            data-w-id='a8541280-bef1-f46b-8921-5fa6854b947e'
            className='column-photo w-col w-col-6'
          >
            <div>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201.png'
                srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201-p-800.png 800w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201-p-1080.png 1080w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201-p-1600.png 1600w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09d490991017a9b01c2bfe_Photo%201.png 1959w'
                sizes='(max-width: 479px) 100vw, (max-width: 4897px) 40vw, 1959px'
                alt=''
              />
            </div>
          </div>
        </div>
        <div
          data-w-id='41c861b2-33d0-d3bb-fe61-67923573d5f2'
          className='af-circular-text'
        >
          <img
            src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0fa64405770923716907b7_Asset%202.svg'
            data-w-id='41c861b2-33d0-d3bb-fe61-67923573d5f3'
            style={{
              WebkitTransform:
                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0DEG) skew(0, 0)',
              msTransform:
                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0DEG) skew(0, 0)',
              transform:
                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0DEG) skew(0, 0)',
            }}
            alt=''
            className='image-14'
          />
        </div>
        <div className='column-image-text w-row'>
          <div className='column-photo w-col w-col-6'>
            <div>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min.png'
                srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min-p-800.png 800w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min-p-1080.png 1080w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min-p-1600.png 1600w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min-p-2000.png 2000w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f09e5e9c99ebf92f8c7eb01_photo%202-min.png 2400w'
                sizes='(max-width: 479px) 100vw, (max-width: 6000px) 40vw, 2400px'
                alt=''
              />
            </div>
          </div>
          <div className='column-typo w-col w-col-6'>
            <div>
              <div className='bold-text-black-55pt'>
                Vietnam’s first &amp; largest{' '}
                <span className='bold-text-yellow-55pt'>
                  production music library.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='text-block-8'>
          <span className='text-span-12'>
            <strong className='bold-text-6-copy-copy-copy'>
              first
              <br />
              and
              <br />
              largest
            </strong>
          </span>
        </div>
        <div className='column-text-image w-row'>
          <div className='column-typo w-col w-col-6'>
            <div>
              <div className='bold-text-black-55pt'>
                Hollywood producers use our music{' '}
                <span className='bold-text-yellow-55pt'>
                  to soundtrack their movies.
                </span>
              </div>
            </div>
          </div>
          <div className='column-photo w-col w-col-6'>
            <div>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203.png'
                srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203-p-800.png 800w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203-p-1080.png 1080w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203-p-1600.png 1600w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203-p-2000.png 2000w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0e382fca88843577632984_photo%203.png 2248w'
                sizes='(max-width: 479px) 100vw, 60vw'
                alt=''
                className='image-40'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='simple-process gray'>
        <div className='hero-content-wrapper process w-container'>
          <div className='heading-box _0px'>
            <h2 className='h2 big _0px'>
              Simple <span className='bold-text-heading'>process</span>
            </h2>
          </div>
          <div className='div-w100'>
            <div className='columns-4 w-row'>
              <div className='column-9 w-col w-col-4'>
                <div className='columns-5 w-row'>
                  <div className='column-8 w-col w-col-3'>
                    <div className='text-block-10'>1.</div>
                  </div>
                  <div className='column-12 w-col w-col-9'>
                    <p className='text-block-9'>
                      Subscribe
                      <br />
                      Library.
                    </p>
                  </div>
                </div>
              </div>
              <div className='column-10 w-col w-col-5'>
                <div className='w-row'>
                  <div className='w-col w-col-3'>
                    <div className='text-block-10'>2.</div>
                  </div>
                  <div className='column-13 w-col w-col-9'>
                    <p className='text-block-9'>
                      Explore
                      <br />
                      Music.
                    </p>
                  </div>
                </div>
              </div>
              <div className='column-11 w-col w-col-3'>
                <div className='columns-6 right w-row'>
                  <div className='w-col w-col-3'>
                    <div className='text-block-10'>3.</div>
                  </div>
                  <div className='column-14 w-col w-col-9'>
                    <p className='text-block-9'>
                      Create
                      <br />
                      Stories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex center mobile' />
        </div>
      </div>
      <div className='row' style={{ marginRight: 0, marginLeft: 0 }}>
        <div className='heading-box-partner'>
          <h2 className='bold-text-6-white'>
            partners
            <span className='bold-text-hero' />
          </h2>
          <p className='subtitle-copy'>
            Trusted by the World’s Best Companies and Creative Professionals.
          </p>
        </div>
        <div className='flex partners'>
          <div className='slide-auto'>
            <div
              data-delay={4000}
              data-animation='slide'
              className='slider-3 w-slider'
              data-autoplay={1}
              data-easing='ease-in-out'
              data-disable-swipe={1}
              data-nav-spacing={10}
              data-duration={800}
              data-infinite={1}
            >
              <div className='mask-4 w-slider-mask'>
                <div className='slide-2 mobile w-slide'>
                  <div className='w-layout-grid grid-3 mobile'>
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a473c46d6149695cae587_Audiomachine%402x.jpg'
                      alt=''
                      className='image-37'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a473d3219541109abd4c8_So%CC%82nton%402x.jpg'
                      alt=''
                      className='image-32'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4a49d686eb1ffa74873c_BandXmusic%402x.jpg'
                      alt=''
                      className='image-38'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f95ea21c787834777a615_photo_2020-07-16_06-45-39.jpg'
                      alt=''
                      className='image-20-copy'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9c99ebf5694c888cd_SONY.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9c99ebf5694c888cd_SONY-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9c99ebf5694c888cd_SONY.png 604w'
                      sizes='(max-width: 479px) 180.91004943847656px, (max-width: 767px) 34vw, (max-width: 991px) 173.95199584960938px, 29vw'
                      alt=''
                      className='image-22 sony'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af8bf7bfc20d8fdd8f8_ALI.png'
                      alt=''
                      className='image-23 alibaba'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6e8b64098f5ad54fb107_photo_2020-07-13_21-23-57.jpg'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6e8b64098f5ad54fb107_photo_2020-07-13_21-23-57-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6e8b64098f5ad54fb107_photo_2020-07-13_21-23-57.jpg 1280w'
                      sizes='(max-width: 479px) 340.78717041015625px, (max-width: 767px) 68vw, (max-width: 991px) 464.6400146484375px, 78vw'
                      alt=''
                      className='image-21-copy'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af927f3d3df9bb08b47_VTV.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af927f3d3df9bb08b47_VTV-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af927f3d3df9bb08b47_VTV.png 604w'
                      sizes='(max-width: 479px) 100vw, (max-width: 767px) 101.47198486328125px, (max-width: 991px) 144.9599609375px, 24vw'
                      alt=''
                      className='image-24'
                    />
                  </div>
                </div>
                <div className='slide-2 w-slide'>
                  <div className='w-layout-grid grid-3'>
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9545517bce6ce4884375_photo_2020-07-16_06-45-30.jpg'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9545517bce6ce4884375_photo_2020-07-16_06-45-30-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9545517bce6ce4884375_photo_2020-07-16_06-45-30.jpg 1016w'
                      sizes='(max-width: 479px) 100vw, (max-width: 767px) 238.9632568359375px, (max-width: 991px) 341.37603759765625px, 57vw'
                      alt=''
                      className='image-29'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6df9945cba21b312d5c7_photo_2020-07-13_21-21-28.jpg'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6df9945cba21b312d5c7_photo_2020-07-13_21-21-28-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6df9945cba21b312d5c7_photo_2020-07-13_21-21-28-p-800.jpeg 800w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0c6df9945cba21b312d5c7_photo_2020-07-13_21-21-28.jpg 1280w'
                      sizes='(max-width: 479px) 100vw, (max-width: 767px) 406.5599365234375px, (max-width: 991px) 580.7999267578125px, 968px'
                      alt=''
                      className='image-30'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a473d6632f7326a6262b6_Song%20to%402x.jpg'
                      alt=''
                      className='image-39'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a473dc99ebf568bc88506_APL%402x.jpg'
                      alt=''
                      className='image-20'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af91293a2a8b359dcd8_K.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af91293a2a8b359dcd8_K-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af91293a2a8b359dcd8_K.png 604w'
                      sizes='(max-width: 479px) 175.88470458984375px, (max-width: 767px) 121.766357421875px, (max-width: 991px) 173.951904296875px, 289.919921875px'
                      alt=''
                      className='image-22 k'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af93ebbae3e6fb5403a_THVL.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af93ebbae3e6fb5403a_THVL-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af93ebbae3e6fb5403a_THVL.png 604w'
                      sizes='(max-width: 479px) 100vw, (max-width: 767px) 25vw, (max-width: 991px) 173.9520263671875px, 29vw'
                      alt=''
                      className='image-23'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af963d9d859fd9ebc04_POP.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af963d9d859fd9ebc04_POP-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af963d9d859fd9ebc04_POP.png 604w'
                      sizes='(max-width: 479px) 100vw, (max-width: 767px) 60.88323974609375px, (max-width: 991px) 86.97607421875px, 15vw'
                      alt=''
                      className='image-21-copy-copy'
                    />
                    <img
                      src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9b21759f3299173c1_ME.png'
                      srcSet='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9b21759f3299173c1_ME-p-500.png 500w, https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0a4af9b21759f3299173c1_ME.png 604w'
                      sizes='(max-width: 479px) 125.63201904296875px, (max-width: 767px) 101.4720458984375px, (max-width: 991px) 144.9600830078125px, 24vw'
                      alt=''
                      className='image-31'
                    />
                  </div>
                </div>
              </div>
              <div className='left-arrow-5 w-slider-arrow-left'>
                <div className='w-icon-slider-left' />
              </div>
              <div className='right-arrow-6 w-slider-arrow-right'>
                <div className='w-icon-slider-right' />
              </div>
              <div className='slide-nav-2 w-slider-nav w-round' />
            </div>
          </div>
        </div>
      </div>
      {/* <div id='pricing' className='content-section-plan-plan gray'>
        <div className='hero-content-wrapper-plans w-container'>
          <div className='plan-box' />
          <div className='heading-box'>
            <h2 className='h2 big'>
              Pricing <span className='bold-text-heading'>plans</span>
            </h2>
          </div>
          <div className='flex-plan'>
            <div
              data-duration-in={300}
              data-duration-out={100}
              className='tabs w-tabs'
            >
              <div className='tabs-content w-tab-content'>
                <div data-w-tab='Tab 2' className='w-tab-pane w--tab-active'>
                  <div className='flex-plann mobile-center-plann'>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $13 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$156 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Personal</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Personal Creator with channel &lt;500K
                                subscribers
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 100,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>1 channel/ platform</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f68831d3'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $39 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$468 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Professional </div>
                        </div>
                        <div className='badge-text'>Popular</div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Personal Creator with channel &gt;500K
                                subscribers
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>1 channel/ platform</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f688324c'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan'>
                          <h3 className='pricing-plan'>
                            $149 <span className='text-span-6'>/mo</span>
                          </h3>
                        </div>
                        <div className='sub-price'>
                          <div className='subtitle-price'>
                            <em>$1788 billed yearly</em>
                          </div>
                        </div>
                        <div className='top-margin-7'>
                          <div className='subtitle-bold'>Industrial</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Freelancers, Production House &amp; Media
                                Agency
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Multiple channels/ platform
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details-not-show'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7-not-show'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f68832c3'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='pricing _2'>
                      <div className='title-pricing-box'>
                        <div className='top-margin-plan-copy'>
                          <h3 className='pricing-plan-quote'>
                            Within 24h quote
                          </h3>
                        </div>
                        <div className='top-margin-7-plan'>
                          <div className='subtitle-bold'>Custom quote</div>
                        </div>
                      </div>
                      <div className='pricing-details-container'>
                        <div className='pricing-details'>
                          <div className='expand'>
                            <div>
                              <p className='sub-head-copy'>
                                For Production House, Media Agency &amp;
                                Broadcaster
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited access to 500,000 tracks
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Lifetime Monetization</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Curated Playlists</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Whitelist on YouTube</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Covered all online platforms
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Multiple channels/ platform
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Unlimited use in digital ads
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>300,000+ Sound Effects</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for client productions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Dedicated account manager</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>Multiple user accounts</p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                Clearance for TV shows, TV ads and cinema
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-details'>
                          <div className='right-margin'>
                            <img
                              src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c0431d5be0c2046_marca-de-verificacion.svg'
                              alt=''
                              className='image-7'
                            />
                          </div>
                          <div className='expand-left'>
                            <div>
                              <p className='small'>
                                On-demand playlist curation
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='pricing-button-box'>
                          <a
                            data-w-id='ae67dddd-74f1-d068-d8f0-3792f6883333'
                            href='https://airtable.com/shrFo2FAeE4JxcZxX'
                            target='_blank'
                            className='button yellow-shadow w-inline-block w-clearfix'
                          >
                            <div className='button-left'>
                              <div className='text-block-4'>Get this Plan</div>
                            </div>
                            <div className='button-right'>
                              <img
                                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043120750c1fff_Arrow.png'
                                width={7}
                                alt=''
                                className='button-arrow'
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section id='cards-section' className='cards-section'>
        <div className='centered-container w-container'>
          <h2 className='bold-text-center-black'>
            Why <span className='bold-text-heading'>EMVN</span>
          </h2>
          <div className='cards-grid-icon-text'>
            <div id='w-node-ace4f7314de2-e60c1dd2' className='div-block-24'>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9f4c5785eee1a9310bb6_Money.svg'
                alt=''
                className='image-26-copy'
              />
            </div>
            <div id='w-node-34271c76f792-e60c1dd2' className='div-block-24'>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9f4d9f3cfb2428d8e5e7_star.svg'
                alt=''
                className='image-26'
              />
            </div>
            <div id='w-node-0c6efb50fefd-e60c1dd2' className='div-block-24'>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9f4dee20ce54b54257dc_Song.svg'
                alt=''
                className='image-26'
              />
            </div>
            <div id='w-node-ec868f2dde41-e60c1dd2' className='div-block-24'>
              <img
                src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f0f9f4eda23c529e3ee2b3c_copyright.svg'
                alt=''
                className='image-26'
              />
            </div>
            <div>
              <div className='text-block-13'>
                Local
                <br />
                Price
              </div>
            </div>
            <div>
              <div className='text-block-13'>
                Hollywood
                <br />
                Quality
              </div>
            </div>
            <div>
              <div className='text-block-13'>
                Unlimited
                <br />
                Downloads
              </div>
            </div>
            <div>
              <div className='text-block-13'>
                Copyright
                <br />
                Protection
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='section' />
      <Footer />
    </div>
  );
};
