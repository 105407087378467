import React, { Component } from 'react';
import axios from 'axios';
import { History } from 'history';

// API
import { API_URL, API_ROUTE, headerConfiguration } from '../../constant';

// STYLE
import './index.scss';

// COMPONENTS
import { GoogleBtn } from '../../components/button';
import { Form, Input, Button, notification } from 'antd';
import ReactLoading from 'react-loading';

// HELPERS
import { setLocalStorageKey, getLocalStorageKey } from '../../helpers';


// FIREBASE
import { auth, googleProvider } from '../../Config/auth';

interface TProps {
  history: History;
  callback?: (callback?: () => void) => void;
}

interface TState {
  isLoading: boolean;
}

let getUser = false;

export default class SignIn extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    auth.getRedirectResult().then(async (result) => {
      if (!result.user) {
        this.setState({ isLoading: false });
      }

      if (result.user && !getUser) {
        getUser = true;

        const firebaseToken = await result.user?.getIdToken();

        const { history, callback } = this.props;

        try {
          const response = await axios.post<
            { firebaseToken: string },
            {
              data: {
                user_id: string;
                access_token: string;
              };
            }
          >(
            `${API_URL}${API_ROUTE.SOCIAL_LOGIN}`,
            {
              firebaseToken: firebaseToken,
            },
            {
              headers: headerConfiguration,
            },
          );

          setLocalStorageKey('accessToken', response.data.access_token);
          setLocalStorageKey('userId', response.data.user_id);

          if (callback) {
            return callback(() => {
              const detailPlan = JSON.parse(getLocalStorageKey('detailPlan') as string)
              if (detailPlan &&
                detailPlan.plan &&
                detailPlan.paymentPeriod &&
                detailPlan.price)
                return history.push('/pay');

              return history.push('/');
            });
          }

          return history.push('/');
        } catch (error) {
          this.setState({ isLoading: false }, () => {
            notification.error({
              message: 'EMVN Error',
              description:
                'There is an error, Please contact EMVN team for assistance!',
              top: 100,
            });
          });
        }
      }
    });
  }

  componentWillUnmount() {
    getUser = false;
  }

  handleSignIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const { history, callback } = this.props;
    try {
      const response = await axios.post<
        { username: string; password: string },
        {
          data: {
            user_id: string;
            access_token: string;
          };
        }
      >(
        `${API_URL}${API_ROUTE.SIGN_IN}`,
        {
          username: email,
          password: password,
        },
        {
          headers: headerConfiguration,
        },
      );

      setLocalStorageKey('accessToken', response.data.access_token);
      setLocalStorageKey('userId', response.data.user_id);

      if (callback) {
        return callback(() => {
          const detailPlan = JSON.parse(getLocalStorageKey('detailPlan') as string)
          if (detailPlan &&
            detailPlan.plan &&
            detailPlan.paymentPeriod &&
            detailPlan.price)
            return history.push('/pay');

          history.push('/');
        });
      }

      history.push('/');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'EMVN Error',
        description: 'Please double check either email or password',
        top: 100,
      });
    }
  };

  handleLogoClick = () => {
    const { history } = this.props;

    return history.push('/');
  };

  handleGoogleSignIn = () => {
    auth.signInWithRedirect(googleProvider);
  };

  loadingScreen = () => {
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.7,
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <ReactLoading type='cylon' color='#fff' height={100} width={100} />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { history } = this.props;

    return (
      <>
        {isLoading && this.loadingScreen()}

        <div className='sign-in'>
          <div className='body-wrapper'>
            <div className='sign_in-title'>
              <p>Sign</p>
              <p style={{ color: '#b99876', marginLeft: 5 }}>In</p>
            </div>
            {/* <GoogleBtn onClick={this.handleGoogleSignIn} /> */}
            <div className='or-section'>
              <div className='custom-hr' />
              <p>OR</p>
            </div>
            <div className='input-form'>
              <Form
                style={{ marginTop: 30 }}
                name='nest-messages'
                onFinish={this.handleSignIn}
              >
                <Form.Item
                  name={['email']}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Email is required',
                    },
                  ]}
                  style={{ marginBottom: 20 }}
                >
                  <Input
                    placeholder='Email'
                    style={{ width: '100%', height: 40 }}
                  />
                </Form.Item>
                <Form.Item
                  name={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Password is required',
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    type='password'
                    placeholder='Password'
                    style={{ width: '100%', height: 40 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType='submit' className='login-submit'>
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {/* <div className='sign-in-btn'>
            <RoundedButton title='SIGN IN' onClick={this.handleSignIn} />
          </div> */}
            {/* <div className='no-account-section'>
              <p style={{ fontSize: 15 }}>Don’t have an account?</p>
              <p
                onClick={() => history.push('/sign_up')}
                style={{ fontWeight: 'bold' }}
              >
                Sign up
              </p>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
