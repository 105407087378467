/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { pdfjs } from 'react-pdf';

// COMPONENT
import Header from './components/header';

// PAGES
import AccountPage from './pages/Account';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import Pricing from './pages/Pricing/index.new';
import Pay from './pages/Pay';
import { LandingPage } from './pages/LandingPage';
import { Redirect } from './pages/Redirect';
import { AboutUs } from './pages/AboutUs';
import { AffiliateProgram } from './pages/AffiliateProgram';
import { SalesPolicy } from './pages/SalesPolicy';
import { TermsCondition } from './pages/TermsCondition';
import { Agreement } from './pages/Agreement';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { FAQ } from './pages/Faq';

// PRIVATE ROUTE
import { PrivateRoute } from './PrivateRoute';

// STYLE
import './App.scss';
import axios from 'axios';
import ReactLoading from 'react-loading';

// MODEL
import { User } from './model/user';

// HELPER
import { getLocalStorageKey, removeLocalStorageToken } from './helpers';
import { API_URL, API_ROUTE } from './constant';

const MessengerCustomerChat = require('react-messenger-customer-chat');

interface TState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User;
  facebookPageId: String;
  facebookAppId: String;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Loading = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactLoading type='cylon' color='#fff' height={'10%'} width={'10%'} />
    </div>
  );
};

const FacebookChat = (pageId: any, appId: any) => {
  return (
    <MessengerCustomerChat
      pageId={pageId}
      appId={appId}
    />
  )
}

export const App = () => {
  const [localState, setLocalState] = useState<TState>({
    isAuthenticated: false,
    isLoading: true,
    user: {
      displayName: '',
      email: '',
      firstName: '',
      flatRoles: '',
      id: '',
      lastName: '',
      provider: '',
      saleId: '',
      username: '',
    },
    facebookPageId: '',
    facebookAppId: '',
  });

  useEffect(() => {
    fetchUserInformation();
  }, []);

  const fetchUserInformation = (callback?: () => void) => {
    const accessToken = getLocalStorageKey('accessToken');
    const userId = getLocalStorageKey('userId');

    var facebookPageId = '';
    var facebookAppId = '';
    axios.get(`${API_URL}${API_ROUTE.GET_FACEBOOK_CHAT_INFO}`)
      .then((response) => {
        if (response && response.data) {
          facebookPageId = response.data.pageId;
          facebookAppId = response.data.appId;
          setLocalState({
            ...localState,
            facebookPageId: facebookPageId,
            facebookAppId: facebookAppId
          })
        }

        if (accessToken) {
          return axios
            .get<User>(`${API_URL}${API_ROUTE.GET_USER_INFORMATION}/${userId}`, {
              headers: {
                authorization: accessToken,
              },
            })
            .then((response) => {
              const user = response.data;

              setLocalState({
                ...localState,
                isAuthenticated: true,
                user,
                isLoading: false
              });

              if (callback) {
                callback();
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                removeLocalStorageToken('accessToken');
                removeLocalStorageToken('userId');
                // toast.error('Your access token is unvalid, Please login!!');
                return setLocalState({ ...localState, isLoading: false });
              }

              // toast.error(error.response.data?.error?.message);
              return setLocalState({ ...localState, isLoading: false });
            });
        }

        return setLocalState({ ...localState, isLoading: false });
      });
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <LandingPage />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/about_us'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <AboutUs />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/affiliate_program'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <AffiliateProgram />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/sales_policy'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <SalesPolicy />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/terms_condition'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <TermsCondition />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/agreement'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <Agreement />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/privacy_policy'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <PrivacyPolicy />
                  </div>
                )}
              </>
            )}
          />
          <Route
            exact
            path='/faq'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: 'unset' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <FAQ />
                  </div>
                )}
              </>
            )}
          />

          <Route
            path='/log_in'
            render={({ history }: RouteComponentProps) => {
              return (
                <div style={{ height: '100%' }}>
                  <Header
                    isAuthenticated={localState.isAuthenticated}
                    user={localState.user}
                    history={history}
                  />
                  <SignInPage
                    history={history}
                    callback={(callback) => fetchUserInformation(callback)}
                  />
                </div>
              );
            }}
          />
          <Route
            path='/sign_up'
            render={({ history }: RouteComponentProps) => {
              return (
                <div style={{ height: '100%' }}>
                  <Header
                    isAuthenticated={localState.isAuthenticated}
                    user={localState.user}
                    history={history}
                  />
                  <SignUpPage
                    history={history}
                    callback={(callback) => fetchUserInformation(callback)}
                  />
                </div>
              );
            }}
          />
          <Route
            path='/pricing'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: '100%' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <Pricing
                      isAuthenticated={localState.isAuthenticated}
                      history={history}
                    />
                  </div>
                )}
              </>
            )}
          />
          {/* <Route
            path='/success_non_auth'
            component={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: '100%' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <Redirect history={history} />
                  </div>
                )}
              </>
            )}
          /> */}
          <Route
            path='/pay'
            render={({ history }: RouteComponentProps) => (
              <>
                {localState.isLoading ? (
                  <div style={{ height: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <div style={{ height: '100%' }}>
                    <Header
                      isAuthenticated={localState.isAuthenticated}
                      user={localState.user}
                      history={history}
                    />
                    <Pay user={localState.user} history={history} />
                  </div>
                )}
              </>
            )}
          />

          <PrivateRoute
            user={localState.user}
            isAuthenticated={localState.isAuthenticated}
            isLoading={localState.isLoading}
          >
            <Route
              exact
              path='/account_management'
              render={({ history }: RouteComponentProps) => (
                <>
                  {localState.isLoading ? (
                    <div style={{ height: '100%' }}>
                      <Loading />
                    </div>
                  ) : (
                    <div style={{ height: '100%' }}>
                      <Header
                        isAuthenticated={localState.isAuthenticated}
                        user={localState.user}
                        history={history}
                      />
                      <AccountPage user={localState.user} history={history} />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              path='/success'
              render={({ history }: RouteComponentProps) => (
                <>
                  {localState.isLoading ? (
                    <div style={{ height: '100%' }}>
                      <Loading />
                    </div>
                  ) : (
                    <div style={{ height: '100%' }}>
                      <Header
                        isAuthenticated={localState.isAuthenticated}
                        user={localState.user}
                        history={history}
                      />
                      <Redirect user={localState.user} history={history} />
                    </div>
                  )}
                </>
              )}
            />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
      <ToastContainer />
      <FacebookChat pageId={localState.facebookPageId} appId={localState.facebookAppId} />
    </>
  );
};
