import React from 'react';

import './index.scss';

interface TProps {
  background: string;
  mainTitle: string;
  subTitle: string;
}

export const HeaderBackground = ({
  background,
  mainTitle,
  subTitle,
}: TProps) => {
  return (
    <div
      className='header-background'
      style={{ backgroundImage: `url(${background})` }}
    >
      <p
        className='header-background-title'
        style={{ lineHeight: 'unset', textAlign: 'center' }}
      >
        {mainTitle}
      </p>
      <p
        className='header-background-title subtitle'
        style={{ lineHeight: 'unset', textAlign: 'center' }}
      >
        {subTitle}
      </p>
    </div>
  );
};
