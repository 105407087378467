export const headerConfiguration = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const API_URL = process.env.REACT_APP_API_URL ?? '';

export enum API_ROUTE {
  GET_USER_INFORMATION = '/users',
  SIGN_IN = '/sign_in',
  SIGN_UP = '/sign_up',
  PAYMENT = '/payment',
  SOCIAL_LOGIN = '/social-login',
  UPDATE_USER_INFORMATION = '/users',
  UPGRADE_USER_PLAN = '/upgrade_user_plan',
  UPDATE_USER_PASSWORD = '/users/update_password',
  CLEAR_VIDEO = '/clear_video',
  GET_FACEBOOK_CHAT_INFO = '/facebook_chat_info'
}
