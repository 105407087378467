import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAZw1q5D0FAAJyY_Hy0qmQd2-ZEekNeOlQ',
  authDomain: 'emvn-website.firebaseapp.com',
  databaseURL: 'https://emvn-website.firebaseio.com',
  projectId: 'emvn-website',
  storageBucket: 'emvn-website.appspot.com',
  messagingSenderId: '396706008661',
  appId: '1:396706008661:web:67c41ff40c8cde0ed80edc',
  measurementId: 'G-2LF0P5HZDP'
};

// Initialize Firebase
try {
  console.log(`Init firebase with authDomain = ${firebaseConfig.authDomain} ...`);
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}
const fire = firebase;
export default fire;
