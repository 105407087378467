/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Select,
  Radio,
  Form,
  Input,
  InputNumber,
  Button,
} from 'antd';
import { History } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faFileInvoiceDollar,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { FormInstance } from 'antd/es/form';

// COMPONENTS
import {
  RoundedButton,
  NonRoundedButton,
} from '../../../../../components/button';
import { Modal } from '../../../../../components/modal';

// DATA
import {
  SubscriptionInformation,
  IndustryOptions,
  PlatformsOptions,
  CountriesOptions,

} from './data';

import {
  DataPlan,
  Plan,
  PaymentPeriod,
  getPlanPrice,
  DefaultPaymentPeriod,
} from '../../../../../model/data'

// HELPER
import { numberWithCommas, setLocalStorageKey } from '../../../../../helpers';

interface TProps {
  history: History;
  isAuthenticated: boolean;
}

interface TState {
  selectedPlan: Plan | undefined;
  modalState: string;
  paymentPeriod: number;
  addSfx: boolean;
  mode: 'normal' | 'custom' | 'single';
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
};

const { Option } = Select;

export default class Pricing extends Component<TProps, TState> {
  formRef = React.createRef<FormInstance>();

  constructor(props: TProps) {
    super(props);
    this.state = {
      selectedPlan: undefined,
      modalState: 'none',
      paymentPeriod: DefaultPaymentPeriod,
      addSfx: false,
      mode: 'normal',
    };
  }

  handlerSubscribe = (plan: Plan, mode: 'normal' | 'custom' | 'single') => {
    this.setState({
      modalState: 'block',
      selectedPlan: plan,
      mode,
    });
  };

  handlerSingleSubscribe = () => {
    this.setState({
      mode: 'single',
      modalState: 'block',
      selectedPlan: DataPlan[3],
    });
  };

  handleChangePaymentPeriod = (id: number) => {
    this.setState({
      paymentPeriod: id,
    });
  };

  getPrice = () => {
    const { paymentPeriod, selectedPlan, addSfx } = this.state;

    if (!selectedPlan)
      return 0;

    return getPlanPrice(paymentPeriod, selectedPlan, addSfx)
  };

  generatePrice = () => {
    return numberWithCommas(this.getPrice());
  };

  handleClickSubScribleChannel = ({ channel }: { channel: string }) => {
    const { history, isAuthenticated } = this.props;
    const price = this.getPrice();

    const regex = new RegExp(
      /((http|https):\/\/|)(www\.|)youtube\.com\/(channel\/|user\/)[a-zA-Z0-9-]{1,}/g,
    );

    if (!regex.test(channel)) {
      return this.formRef.current?.setFields([
        { name: 'channel', errors: ['Channel is not valid'] },
      ]);
    }

    setLocalStorageKey('detailPlan', JSON.stringify({
      price: price,
      paymentPeriod: this.state.paymentPeriod,
      plan: this.state.selectedPlan,
      addSfx: this.state.addSfx
    }))
    setLocalStorageKey('channel', String(channel));

    if (isAuthenticated) {
      return history.push('/pay');
    }

    return history.push('/log_in');
  };

  handleNormalModeRender = () => {
    const { paymentPeriod, addSfx } = this.state;
    return (
      <div style={{ color: '#000' }}>
        <p className='modal-title'>1. Subscription period</p>
        <div className='list-subscribes'>
          <Select
            value={paymentPeriod}
            style={{ width: '100%' }}
            onChange={this.handleChangePaymentPeriod}
          >
            {PaymentPeriod.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
          </Select>
        </div>
        <p className='modal-title' style={{ marginBottom: 0 }}>
          2. Add SFXs to your subscription plan?
        </p>
        <p className='sub-modal-title'>
          +25% for unlimited use of 300K SFXs library
        </p>
        <div className='list-subscribes'>
          <Radio.Group
            onChange={(e) => this.setState({ addSfx: e.target.value })}
            value={addSfx}
          >
            <Radio value={true} className='radio-item'>
              Yes
            </Radio>
            <Radio value={false} className='radio-item'>
              No
            </Radio>
          </Radio.Group>
        </div>
        <Form
          ref={this.formRef}
          onFinish={this.handleClickSubScribleChannel}
          initialValues={{ channel: '' }}
        >
          <p className='modal-title'>3. Add your YouTube channel</p>
          <div className='list-subscribes'>
            <Form.Item
              name={['channel']}
              rules={[
                {
                  required: true,
                  message: 'Channel is required',
                },
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input
                placeholder='Channel'
                style={{ width: '100%', height: 40, borderRadius: 5 }}
              />
            </Form.Item>
          </div>

          <hr className='hr-modal' />

          <div className='installment-selection'>
            <div className='period-container'>
              <p className='period-price'>Total</p>
              <p className='sub-period-price'>(VAT included)</p>
            </div>
            <div className='price-container'>
              <p className='price'>{this.generatePrice()} VND</p>
            </div>
          </div>
          <NonRoundedButton type='submit' title='CONTINUE' />
          {/* <p className='subscrible-modal-title'>
              Your rate is based on a yearly agreement that you are agreeing to
              pay in monthly installments. You will be charged for your first
              month today and your next payment will be charged automatically on
              November 19, 2020
            </p> */}
        </Form>
      </div>
    );
  };

  handleSubmitCustomMode = (values: SubscriptionInformation) => {
    console.log(values);
  };

  handleCustomModeRender = () => {
    return (
      <div className='custom-subscription'>
        <p className='custom-subscription-title' style={{ color: '#000' }}>
          Please tell us about your production and we will get back to you with
          a customized quote as soon as possible.
        </p>

        <Form
          style={{ marginTop: 30 }}
          name='nest-messages'
          onFinish={this.handleSubmitCustomMode}
          validateMessages={validateMessages}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, paddingRight: 10 }}>
              <Form.Item
                name={['first_name']}
                rules={[
                  {
                    required: true,
                    message: 'First name is required',
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  placeholder='First Name'
                  style={{ width: '100%', height: 40 }}
                />
              </Form.Item>
            </div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <Form.Item
                name={['last_name']}
                rules={[
                  {
                    required: true,
                    message: 'Last name is required',
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  placeholder='Last Name'
                  style={{ width: '100%', height: 40 }}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name={['email']}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email is required',
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <Input placeholder='Email' style={{ width: '100%', height: 40 }} />
          </Form.Item>
          <Form.Item
            name={['phone']}
            rules={[
              {
                required: true,
                type: 'number',
                message: 'Phone is required',
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <InputNumber
              style={{ width: '100%', height: 40 }}
              placeholder='Phone'
            />
          </Form.Item>
          <Form.Item
            name={['company']}
            rules={[
              {
                required: true,
                message: 'Company is required',
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <Input
              placeholder='Company'
              style={{ width: '100%', height: 40 }}
            />
          </Form.Item>
          <Form.Item
            name={['industry']}
            rules={[{ required: true, message: 'Industry is required' }]}
            style={{ marginBottom: 10 }}
          >
            <Select placeholder='Industry' style={{ height: 40 }}>
              {IndustryOptions.map((v, i) => {
                return (
                  <Option key={i} value={v}>
                    {v}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={['platform']}
            rules={[{ required: true, message: 'Platform is required' }]}
            style={{ marginBottom: 10 }}
          >
            <Select placeholder='Platform' style={{ height: 40 }}>
              {PlatformsOptions.map((v, i) => {
                return (
                  <Option key={i} value={v}>
                    {v}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={['country']}
            rules={[{ required: true, message: 'Country is required' }]}
          >
            <Select placeholder='Country' style={{ height: 40 }}>
              {CountriesOptions.map((v, i) => {
                return (
                  <Option key={i} value={v.country}>
                    {v.country}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' className='subscription-submit'>
              GET QUOTE
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  handleSubmitSingleMode = (values: SubscriptionInformation) => {
    console.log(values);
  };

  render() {
    const { modalState, mode, selectedPlan } = this.state;

    return (
      <div className='profile-pricing-container'>
        <Row gutter={[16, 8]} className='profile-pricing-plan-wrapper'>
          {DataPlan.filter((v) => v.id < 4).map((v, i) => {
            return (
              <Col key={v.id} xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <div className='pricing-plan-item'>
                  <p
                    className='pricing-plan-item-title'
                    style={{ color: '#b99876' }}
                  >
                    {v.title}
                  </p>

                  {v.startAt ? (
                    <>
                      <p className='pricing-plan-item-starting-at'>
                        Starting at
                      </p>
                      <div className='pricing-plan-item-amount'>
                        <p
                          className='amount'
                          style={{ fontWeight: 500, color: '#fff' }}
                        >
                          {v.startAtLabel}
                        </p>
                      </div>
                      <p className='pricing-amount-total' style={{ height: '23px' }}>
                      </p>
                    </>
                  ) : (
                    <>
                      <div
                        className='pricing-plan-item-amount'
                        style={{ marginTop: 20 }}
                      >
                        <FontAwesomeIcon icon={faFileInvoiceDollar} size='5x' />
                      </div>
                      <p
                        className='pricing-amount-total'
                        style={{ color: '#fff', height: '23px' }}
                      >
                        Same day quote
                      </p>
                    </>
                  )}

                  <div className='pricing-plan-item-detail'>
                    <hr className='pricing-plan-item-hr' />

                    {v.rules.map((rule, ruleIdx) => (
                      <div className='pricing-more-detal' key={ruleIdx}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: 15 }}
                        />
                        <p
                          style={
                            v.id === 1 && ruleIdx === 1
                              ? { marginBottom: 15 }
                              : {}
                          }
                        >
                          {rule}
                        </p>
                        {v.id === 1 && ruleIdx === 1 && (
                          <p
                            style={{
                              position: 'absolute',
                              top: 22,
                              fontSize: 12,
                              left: 30,
                              backgroundColor: 'rgba(185, 152, 118, 0.2)',
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 5,
                              fontStyle: 'italic',
                            }}
                          >
                            {'limit 200k followers/channel'}
                          </p>
                        )}
                      </div>
                    ))}
                    {v.notAllowedRules.map((notRule, i) => (
                      <div
                        className='pricing-more-detal'
                        key={i}
                        style={{ opacity: 0.35 }}
                      >
                        <FontAwesomeIcon
                          icon={faBan}
                          style={{ marginRight: 15 }}
                        />
                        <p>{notRule}</p>
                      </div>
                    ))}
                  </div>

                  <RoundedButton
                    className='pricing-get-this-plan'
                    onClick={() =>
                      this.handlerSubscribe(v, v.startAt ? 'normal' : 'custom')
                    }
                  >
                    {v.startAt ? 'GET THIS PLAN' : 'GET QUOTE'}
                  </RoundedButton>
                </div>
              </Col>
            );
          })}
        </Row>
        <Modal modalState={modalState}>
          <FontAwesomeIcon
            icon={faTimes}
            className='modal-close-icon'
            onClick={() =>
              this.setState({
                selectedPlan: undefined,
                modalState: 'none',
                paymentPeriod: DefaultPaymentPeriod,
              })
            }
            style={{ color: '#000' }}
          />
          <div className='custom-modal-header-payment'>
            <p className='main-title' style={{ color: '#000' }}>
              {selectedPlan?.modelTitle}
            </p>
          </div>
          <div className='custom-modal-body-group-btn'>
            {mode === 'normal' ? this.handleNormalModeRender() : null}
            {mode === 'custom' ? this.handleCustomModeRender() : null}
          </div>
        </Modal>
      </div>
    );
  }
}
