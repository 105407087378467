import React, { Component } from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';

// STYLE
import './index.scss';

// HELPER
import { removeLocalStorageToken } from '../../helpers';

// MODEL
import { User } from '../../model/user';

interface TProps {
  user?: User;
  history: History;
  isAuthenticated: boolean;
}

export default class Header extends Component<TProps> {
  handleSignOut = () => {
    removeLocalStorageToken('accessToken');
    removeLocalStorageToken('userId');
    removeLocalStorageToken('detailPlan');

    window.location.href = '/login';
  };

  handleNavigateMyAccount = () => {
    this.props.history.push('/account_management');
  };

  handleSignIn = () => {
    this.props.history.push('/log_in');
  };

  handleClickPricing = () => {
    this.props.history.push('/pricing');
  };

  handleLogoClick = () => {
    const { history } = this.props;

    return history.push('/');
  };

  render() {
    const { user, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      return (
        <nav
          className='custom-header navbar navbar-expand-md'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <div className='navbar-brand'>
            <img
              src='/emvnlogo.svg'
              alt='logo'
              onClick={this.handleLogoClick}
            />
            <div
              className='header-search-song'
              onClick={() =>
                window.open('https://emvn.sourceaudio.com/', '__blank')
              }
            >
              <FontAwesomeIcon
                icon={faSearch}
                onClick={() => this.setState({ modalState: 'none' })}
              />
              <p className='song-title'>SONG</p>
            </div>
          </div>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#collapsibleNavbar'
          >
            <FontAwesomeIcon icon={faBars} style={{ color: '#fff' }} />
          </button>
          <div
            className='custom-left-header-group collapse navbar-collapse'
            id='collapsibleNavbar'
          >
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <div className='nav-link'>
                  <button
                    className='start-free-now-btn'
                    onClick={() => this.props.history.push('/sign_up')}
                  >
                    START FREE NOW
                  </button>
                </div>
              </li>
              <Link
                style={{ textDecoration: 'none' }}
                to='/pricing'
                className='nav-item'
              >
                <div className='nav-link'>Pricing</div>
              </Link>
              <Link
                style={{ textDecoration: 'none' }}
                to='/log_in'
                className='nav-item'
              >
                <div className='nav-link'>Sign In</div>
              </Link>
            </ul>
          </div>
        </nav>
      );
    }

    return (
      <nav className='custom-header navbar navbar-expand-md'>
        <div className='navbar-brand'>
          <img src='/emvnlogo.svg' alt='logo' onClick={this.handleLogoClick} />
          <div
            className='header-search-song'
            onClick={() =>
              window.open('https://emvn.sourceaudio.com/', '__blank')
            }
          >
            <FontAwesomeIcon
              icon={faSearch}
              onClick={() => this.setState({ modalState: 'none' })}
            />
            <p className='song-title'>SONG</p>
          </div>
        </div>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#collapsibleNavbar'
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div
          className='custom-left-header-group collapse navbar-collapse'
          id='collapsibleNavbar'
        >
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <div className='nav-link'>
                <button
                  className='start-free-now-btn'
                  onClick={() => this.props.history.push('/pricing')}
                >
                  GET A LISENCE
                </button>
              </div>
            </li>
            <Link
              style={{ textDecoration: 'none' }}
              to='/pricing'
              className='nav-item'
              onClick={this.handleClickPricing}
            >
              <div className='nav-link'>Pricing</div>
            </Link>
            <li className='nav-item'>
              <div className='nav-link'>
                <div className='dropdown'>
                  <div
                    id='dropdownMenu2'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {user && user.displayName}
                  </div>
                  <div
                    className='custom-dropdown dropdown-menu dropdown-menu-right'
                    aria-labelledby='dropdownMenu2'
                  >
                    <p
                      className='custom-dropdown-item'
                      onClick={this.handleNavigateMyAccount}
                    >
                      My Account
                    </p>
                    <p
                      className='custom-dropdown-item'
                      onClick={this.handleSignOut}
                    >
                      Sign out
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
