/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';

import './index.scss';

export const Agreement = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/agreement.jpeg'
        mainTitle='THỎA THUẬN'
        subTitle='DỊCH VỤ'
      />
      <div id='Intro' className='content-section-normal top-padding-visible'>
        <div className='hero-content-wrapper-copy2 w-container'>
          <div className='flex-as mobile-vertical'>
            <div className='faq-left sa'>
              <div className='position-sticky'>
                <h4 className='h4 title sa'>
                  Service
                  <br />
                  Agreement
                </h4>
                <div className='top-margin-7 _20-pixel'>
                  <a
                    href='#Personal-Creator'
                    className='no-underline w--current'
                  >
                    Personal
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Professional-Creator' className='no-underline'>
                    Professional
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Industrial-Producer' className='no-underline'>
                    Industrial
                  </a>
                </div>
              </div>
            </div>
            <div className='expand sa'>
              <div className='faq-set'>
                <div className='set-title'>
                  <h3 className='h3 big'>QUY TRÌNH CUNG ỨNG DỊCH VỤ</h3>
                  <div>
                    <div className='subtitle' style={{ paddingBottom: 0 }}>
                      <strong className='bold-text-8'>
                        QUY TRÌNH GIAO DỊCH
                      </strong>
                      <br />‍
                      <p style={{ display: 'inline' }}>
                        Người dùng có nhu cầu sử dụng các dịch vụ của EMVN cần
                        thực hiện các bước sau:
                      </p>
                      <br />
                      <ul>
                        <li>
                          Truy cập vào website theo đường dẫn
                          https://license.emvn.co/ để tìm hiểu và và lựa chọn
                          gói subscription
                        </li>
                        <li>
                          Lưạ chọn gói Subscrition phù hợp và điền thông tin vào
                          biểu mẫu đăng ký. Vui lòng điền chính xác các thông
                          tin để bộ phận hỗ trợ khách hàng EMVN có thể liên hệ
                          với bạn và tư vấn về dịch vụ.
                        </li>
                        <li>
                          Sau khi người dùng quyết định gói subscription sẽ sử
                          dụng, EMVN sẽ gửi Hợp đồng dịch vụ và hướng dẫn thanh
                          toán kèm theo đến email được cung cấp tại biểu mẫu.
                        </li>
                        <li>
                          Khi khoản thanh toán tại Hợp đồng dịch vụ được xác
                          nhận hoàn tất, EMVN sẽ gửi cho khách hàng một email
                          xác nhận. Kể từ thời điểm nhận được email, tài khoản
                          sử dụng gói subscription của khách hàng sẽ được kích
                          hoạt.
                        </li>
                      </ul>
                      <strong className='bold-text-8'>
                        THỜI GIAN KÍCH HOẠT DỊCH VỤ
                      </strong>
                      <ul>
                        <li>
                          Sau khi nhận được khoản thanh toán, EMVN sẽ kích hoạt
                          dịch vụ cho người dùng trong vòng 24 giờ .
                        </li>
                        <li>
                          Hướng dẫn đăng nhập:
                          <ul>
                            <li>
                              Bước 1: Sau khi nhận được email xác nhận, khách
                              hàng truy cập vào https://license.emvn.co/ chọn
                              Start Free Now -{'>'} Chọn Register và điền các
                              thông tin cần thiết.
                            </li>
                            <li>
                              Bước 2: Tài khoản phải được EMVN xác minh trong
                              vòng 24h.
                            </li>
                            <li>
                              Bước 3: Sau khi tài khoản được xác minh khách hàng
                              được phép sử dụng dịch vụ theo đúng phạm vi thỏa
                              thuận gói subscription.
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <strong className='bold-text-8'>
                        QUY ĐỊNH GIAO NHẬN GÓI DỊCH VỤ
                      </strong>
                      <ul>
                        <li>
                          Nhằm bảo vệ tối đa quyền lợi khách hàng, EMVN chỉ xác
                          thực tài khoản sử dụng gói Dịch vụ theo đúng danh sách
                          được khách hàng cung cấp.
                        </li>
                        <li>
                          Vì đặc thù của dịch vụ số, các thông tin sử dụng dịch
                          vụ quan trọng đều được cung cấp và chuyển giao qua
                          email và /hoặc các phương thức truyền điện tử khác
                          (nếu có).
                        </li>
                        <li>
                          Các thông tin sử dụng dịch vụ sẽ là một trong những
                          thông tin nhận diện khách hàng cho các chính sách quan
                          trọng khác có liên quan nên vui lòng đăng ký chính xác
                          và lưu giữ trong quá sử dụng. Khi có bất kỳ thay đổi
                          nào phát sinh, vui lòng liên hệ về bộ phận chăm sóc
                          khách hàng EMVN để được hỗ trợ.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Personal-Creator' className='faq-set'>
                <div className='set-title'>
                  <h3 className='h3 big'>Personal Creator</h3>
                  <div>
                    <p className='subtitle'>
                      <strong className='bold-text-8'>
                        ĐỊNH NGHĨA
                        <br />‍
                      </strong>
                      <em>
                        Trong Thỏa thuận này, các từ viết hoa có nghĩa như sau:
                        <br />
                        1. “
                      </em>
                      <strong>
                        <em>Thư viện EMVN</em>
                      </strong>
                      <em>
                        ” là thư viện âm thanh, âm nhạc bản quyền được cung cấp
                        bởi EMVN tại đường dẫn{' '}
                      </em>
                      <a href='https://emvn.c/'>
                        <em>https://emvn.co</em>
                      </a>
                      <em>
                        .<br />
                        2. “
                      </em>
                      <strong className='bold-text-10'>
                        <em>Bản ghi</em>
                      </strong>
                      <em className='italic-text'>
                        ” là bản ghi âm tác phẩm âm nhạc, tiếng động thuộc Thư
                        viện EMVN, do EMVN sở hữu và/hoặc có quyền phân phối.
                        <br />
                        3. “
                      </em>
                      <strong className='bold-text-11'>
                        <em>Subscription</em>
                      </strong>
                      <em>
                        ” là gói bản quyền âm nhạc do EMVN cung cấp cho cá nhân,
                        tổ chức, pháp nhân bao gồm quyền truy cập không giới hạn
                        và quyền sử dụng không giới hạn Bản ghi thuộc Thư viện
                        EMVN theo các điều khoản tại Thỏa thuận này.{' '}
                      </em>
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        PHẠM VI CẤP QUYỀN
                        <br />‍
                      </strong>
                      1. Theo các điều khoản của Thỏa thuận này, EMVN cấp cho
                      Bên được cấp phép quyền:
                      <br />
                      1.1. Truy cập, tạo bản sao Bản ghi để đồng bộ toàn bộ/một
                      phần vào sản phẩm nghe nhìn của Bên được cấp phép (“
                      <strong>Chương trình</strong>”) trong phạm vi lãnh thổ
                      Việt Nam và trong thời hạn của Thỏa thuận này. Để tránh
                      nhầm lẫn, trong phạm vi Thỏa thuận này, Chương trình không
                      bao gồm quảng cáo, trailer điện ảnh, phim điện ảnh, các
                      nội dung sản xuất và/hoặc liên kết sản xuất cho bên thứ ba
                      bất kỳ(không giới hạn hình thức thể hiện);
                      <br />
                      1.2 Đăng tải Chương trình lên các nền tảng bao gồm YouTube
                      và các trang mạng xã hội: Facebook, Instagram (“
                      <strong>Nền tảng</strong>”) trong thời hạn của Thỏa thuận
                      này. <br />
                      <br />
                      2. Trong phạm vi Thỏa thuận này, Bên được cấp phép có
                      quyền cấp các quyền thứ cấp sau: <br />
                      2.1. Cấp phép các quyền tại Mục 1.1 cho các đơn vị sản
                      xuất độc lập với mục đích thay mặt Bên được cấp phép tạo
                      ra Chương trình. Tuy nhiên, việc cấp phép này chỉ được
                      chấp nhận và có hiệu lực trong phạm vi Thỏa thuận này. Bên
                      được cấp phép có nghĩa vụ thông báo bằng văn bản tới EMVN
                      và là Bên chịu mọi trách nhiệm đối với Thỏa thuận này;
                      <br />
                      2.2. Cấp phép các quyền tại Mục 1.2 cho bên thứ ba. Hai
                      bên đồng ý rằng, Bên được cấp phép có trách nhiệm (i)
                      thông báo bằng văn bản tới EMVN trong vòng 10 (mười) ngày
                      kể từ ngày phát sinh thay đổi (ii) đảm bảo không vi phạm
                      Thỏa thuận này và (iii) nhận ủy quyền của bên thứ ba chịu
                      trách nhiệm tiếp tục thực hiện Thỏa thuận này.
                      <br />
                      <br />
                      3.Gói Subscription được áp dụng cho hầu hết các mục đích
                      sử dụng thương mại, trừ các trường hợp cụ thể sau đây:
                      <br />
                      <span className='text-span-8'>
                        3.1. Bên được cấp phép là/là một phần: (i) Cá nhân hoặc
                        nhóm sáng tạo nội dung chuyên nghiệp có từ 500.000 (Năm
                        trăm ngàn)người theo dõi/đăng ký; (ii) Pháp nhân có
                        ngành nghề kinh doanh chính là/hoặc liên quan đến sản
                        xuất và hoặc kinh doanh dịch vụ sản xuất nội dung, quảng
                        cáo, truyền thông, cung cấp cơ sở vật chất và các dịch
                        vụ liên quan, và/hoặc thu về lợi nhuận từ những hoạt
                        động này, bao gồm nhưng không giới hạn các loại hình
                        kinh doanh; (iii) Pháp nhân, bao gồm nhưng không giới
                        hạn các nhà xuất bản kỹ thuật số, nhà xuất bản podcast,
                        nhà xuất bản trò chơi video, đài truyền hình và các công
                        ty truyền thông tương tự;.
                        <br />
                        3.2. Sử dụng Bản ghi trong: (i) Các chương trình sản
                        xuất liên và/hoặc kết sản xuất cho bên thứ ba bất kỳ
                        (ii) Chương trình truyền hình, quảng cáo truyền hình,
                        nhạc hiệu cho chương trình, thương hiệu (iii) trailer
                        điện ảnh, phim điện ảnh; (iv)Các hình thức cho phép bên
                        thứ ba bất kỳ tải xuống và/hoặc truy cập hoặc sử dụng
                        Bản ghi một cách độc lập, bao gồm nhưng không giới hạn
                        các hình thức kỹ thuật số, ứng dụng cho phép người dùng
                        cuối đồng bộ và/hoặc kết hợp Bản ghi với các nội dung
                        khác, tạo ra các sản phẩm tùy chỉnh (như thiệp chúc mừng
                        điện tử hoặc nhạc chuông); hoặc tạo ra các sản phẩm vật
                        lý có chứa sẵn Bản ghi (loa thu âm sẵn); (v) Các nội
                        dung, hình thức có tính chất vi phạm pháp luật/kích động
                        hành vi vi phạm pháp luật; trái với các chuẩn mực đạo
                        đức, thuần phong mỹ tục (v)Các trường hợp sử dụng Bản
                        ghi liên quan đến các chủ đề nhạy cảm, bao gồm, nhưng
                        không giới hạn ở nội dung chính trị, video dành cho
                        người lớn hoặc tương tự. Bên được cấp phép có nghĩa vụ
                        thông báo bằng văn bản cho EMVN. Tại thời điểm nhận được
                        thông báo, EMVN có quyền quyết định có/không tiếp tục
                        cung cấp &nbsp;Subscription &nbsp;tại Thỏa thuận này;
                        <br />
                        3.3 Đăng tải Chương trình trên các Nền tảng (i) Các nền
                        tảng chia sẻ video trực tuyến, ngoại &nbsp;trừ YouTube
                        (ii) các nền tảng truyền hình tuyến tính bao gồm nhưng
                        không giới hạn phát sóng truyền hình, truyền hình cáp,
                        truyền hình vệ tinh; (iii) trong trình diễn sân khấu;
                        (iv) phát trực tuyến thương mại, bao gồm nhưng không
                        giới hạn Netflix, iTunes, Hulu, Facebook Watch, các dịch
                        vụ phòng tập &nbsp;trực tuyến dưới dạng VOD, OTT, AVOD,
                        FVOD và SVOD hoặc tương tự){' '}
                      </span>
                      <br />
                      <br />
                      4. Gói Subscription chỉ cấp cho một Tài khoản. Trong
                      trường hợp có vi phạm hoặc có lý do để tin rằng có nhiều
                      hơn một tài khoản sử dụng gói này, &nbsp;EMVN có quyền (i)
                      chấm dứt Thỏa thuận mà không phải trả bất kỳ một khoản
                      phí, bồi thường nào (i) Bên được cấp phép sẽ phải chịu mọi
                      trách nhiệm thanh toán tiền phí cho mỗi tài khoản sử dụng
                      trái phép đó theo đúng mức giá niêm yết ở thời điểm thực
                      hiện Thỏa thuận. Để tránh vấn đề này, vui lòng liên hệ với
                      EMVN để được hướng dẫn điều chỉnh gói dịch vụ phù hợp;
                      <br />
                      <br />
                      5. Các quyền được cấp tại mục 1.1 Điều này chỉ cho phép
                      các điều chỉnh như chia, cắt, lặp lại Bản ghi để phục vụ
                      nhu cầu sử dụng theo Thỏa thuận này, không cho phép thực
                      hiện các tác động làm thay đổi Bản ghi, bao gồm nhưng
                      không giới hạn remix, cover, cải biên, chuyển thể. &nbsp;
                      <br />
                      <br />
                      6. Các quyền, sản phẩm, dịch vụ mà EMVN cấp theo Thỏa
                      thuận này là không độc quyền, không thể chuyển nhượng và
                      giới hạn tuyệt đối trong phạm vi các quyền tại Mục 1. Theo
                      đó, bất kỳ hành động nào phát sinh ngoài phạm vi Thỏa
                      thuận đều được xem là vi phạm bản quyền, Bên được cấp phép
                      phải trả tiền bản quyền theo quy định và chịu mọi trách
                      nhiệm pháp lý liên quan. <br />‍<br />
                      7. EMVN có thể quyền chuyển Thỏa thuận này và/hoặc chuyển
                      nhượng toàn bộ hoặc một phần hoặc cam kết bất kỳ quyền
                      và/hoặc nghĩa vụ nào của Thỏa thuận này cho bất kỳ bên thứ
                      ba nào miễn là bên thứ ba đó đồng ý ràng buộc với Thỏa
                      thuận này.
                      <br />
                      <br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>KIẾM TIỀN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Bên được cấp phép có quyền kiếm tiền từ việc đăng tải
                      Chương trình lên các Nền tảng có chức năng này. &nbsp;Để
                      tránh nhầm lẫn, các Chương trình được sản xuất và đăng tải
                      trên Nền tảng trong thời hạn của Thỏa thuận này sẽ được
                      cấp quyền này trọn đời.
                      <br />
                      2. Trong trường hợp đăng tải Chương trình lên nền tảng
                      Youtube, Bên được cấp phép sẽ tự động nhận được thông báo
                      xác nhận sở hữu bản quyền. Để tránh tình trạng này, Bên
                      được cấp phép có thể yêu cầu đưa Tài khoản của mình vào
                      danh sách trắng (“
                      <strong>whitelist</strong>”) theo mẫu tại{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                        EMVN Contact
                      </a>
                      . Sau khi được whitelist, trong một vài trường hợp, Bên
                      cấp phép có thể vẫn nhận được thông báo xác nhận sở hữu
                      bản quyền, EMVN có nghĩa vụ hỗ trợ Bên được cấp phép cho
                      đến khi các xác nhận sở hữu bản quyền được giải quyết
                      xong. Các bên đồng ý rằng, việc hỗ trợ này chỉ áp dụng đối
                      với các Chương trình được sản xuất trong thời hạn, phạm vi
                      Thỏa thuận này.
                      <br />
                      3. Để tránh nhầm lẫn, các bên đồng ý rằng, Bên được cấp
                      phép chỉ có thể whitelist một kênh. <br />
                      4. Trong trường hợp Bên được cấp phép là đối tượng tại Mục
                      1, các video vẫn được phép kiếm tiền trên Nền tảng và phần
                      xác nhận sở hữu được sử dụng để trả tiền cho các tổ chức
                      tác quyền. EMVN không can thiệp và/hoặc có nghĩa vụ can
                      thiệp vào vấn đề này. Tuy nhiên, EMVN sẽ hỗ trợ Bên được
                      cấp phép trong phạm vi có thể.
                      <br />‍<br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>
                          THANH TOÁN &amp; HOÀN TRẢ
                        </strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Chi tiết khoản thanh toán được thể hiện tại Thông tin
                      đơn hàng. Bên được cấp phép không cần phải trả thêm bất kỳ
                      một khoản phí liên quan nào khác đến việc sử dụng các
                      quyền được cấp phép theo Thỏa thuận này. Mọi quyền và
                      nghĩa vụ chỉ có hiệu lực khi khoản thanh toán được hoàn
                      tất.
                      <br />
                      2. VAT: Khoản thanh toán trên đã bao gồm VAT. Nếu Bên được
                      cấp phép có bất kỳ yêu cầu nào khác về hóa đơn VAT, vui
                      lòng liên hệ EMVN tại:{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf' target='_blank'>
                        EMVN Contact
                      </a>{' '}
                      <br />
                      3. Không hoàn lại: Tất cả các khoản thanh toán là không
                      hoàn lại và không loại trừ việc chỉ sử dụng dịch vụ một
                      phần.
                      <br />
                      <br />
                      <br />
                      <a href='#' className='bold-text-8'>
                        ‍<strong>THỜI HẠN &amp; CHẤM DỨT THỎA THUẬN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Thỏa thuận này có có hiệu lực trong vòng 01 (một) năm
                      kể từ ngày Khách hàng nhận được email cấp Tài khoản.{' '}
                      <br />‍<br />
                      2. EMVN sẽ thông báo về ngày hết hạn tới Tài khoản của Bên
                      được cấp phép trong vòng 01 (một tháng) trước ngày hết
                      hạn. Tại thời điểm nhận được thông báo, Bên được cấp phép
                      có quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Nếu
                      trước ngày hết hạn, EMVN nhận được khoản thanh toán mới
                      của Bên được cấp phép, Thỏa thuận sẽ tự động gia hạn với
                      các điều khoản tương tự, trừ trường hợp có quy định khác;
                      <br />‍<br />
                      3. Thỏa thuận này sẽ chấm dứt hiệu lực trong các trường
                      hợp sau: &nbsp;
                      <br />
                      3.1. EMVN có quyền đơn phương chấm dứt Thỏa thuận bất cứ
                      lúc nào và yêu cầu bồi thường thiệt hại nếu Bên được cấp
                      phép sử dụng Bản ghi trái phép hoặc vi phạm các quy định
                      tại Thỏa thuận này. &nbsp;Trong trường hợp này, EMVN sẽ
                      không có/chịu bất cứ trách nhiệm nào và không hoàn trả lại
                      bất kỳ khoản thanh toán nào.
                      <br />
                      3.2. `Một bên đơn phương chấm dứt thỏa thuận trái với Thỏa
                      thuận thì phải bồi thường cho Bên còn lại các thiệt hại
                      phát sinh (nếu có).
                      <br />
                      3.3. Thỏa thuận hết hạn và Bên được cấp phép không tiến
                      hành gia hạn thêm.
                      <br />
                      <br />
                      4. Sau khi chấm dứt Thỏa thuận này, Tài khoản được cấp sẽ
                      bị vô hiệu, Bên được cấp phép không còn quyền sử dụng các
                      danh mục Bản ghi, Bản ghi theo Thỏa thuận này. Theo đó,
                      Bên được cấp phép có nghĩa vụ hủy, xóa mọi bản sao của
                      danh mục Bản ghi,bản ghi &nbsp;khỏi mọi bộ nhớ, nơi lưu
                      trữ (không giới hạn hình thức, thiết bị lưu trữ).
                      <br />
                      <br />
                      5. Các bên đồng ý rằng, sau khi chấm dứt Thỏa thuận, EMVN
                      sẽ thực hiện mọi hành động cần thiết để phát hiện, ngăn
                      chặn việc tiếp tục sử dụng các Bản ghi tại Thỏa thuận.
                      <br />
                      <br />
                      6. Để tránh nhầm lẫn, &nbsp;Bên được cấp phép được phép
                      tiếp tục phân phối các Chương trình được hoàn thành trong
                      thời hạn của Thỏa thuận này cho bên thứ ba.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN QUẢNG BÁ
                        <br />
                      </strong>
                      1. Nếu Bản ghi theo Thỏa thuận được sử dụng trong Chương
                      trình, Bên được cấp phép có nghĩa vụ giới thiệu nhà cung
                      cấp là EMVN trong phần mô tả video theo mẫu: Âm nhạc cung
                      cấp bởi EMVN – http://emvn.co. Trong trường hợp cho phép,
                      địa chỉ chú thích trên nên để ở dạng đường dẫn liên kết{' '}
                      <a href='http://emvn.co/'>http://emvn.co</a>.<br />
                      2. EMVN được quyền sử dụng Chương trình (một phần hay toàn
                      bộ), hình ảnh, tên tuổi của Bên được cấp phép với mục đích
                      quảng bá trên kênh riêng của mình, bao gồm nhưng không
                      giới hạn trang web, các nền tảng mạng xã hội (Facebook,
                      Instagram, Twitter..); Các bên đồng ý rằng, quyền này chỉ
                      có hiệu lực khi có sự cho phép của Bên được cấp phép
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN BẢO ĐẢM
                        <br />‍
                      </strong>
                      1. Các bên cam kết đảm bảo về năng lực, quyền hạn khi tham
                      gia vào Thỏa thuận này;
                      <br />
                      2. Mọi thông tin trong giao dịch này được Các bên nỗ lực
                      cung cấp trung thực, chính xác và không gây hiểu lầm trong
                      bất kỳ khía cạnh trọng yếu nào. <br />
                      3. Các bên độc lập tham gia Thỏa thuận này. Không có bất
                      kỳ nội dung nào trong Thỏa thuận này có ý định tạo ra một
                      đại lý, sự cộng tác, hoặc một liên doanh dưới bất kỳ
                      phương thức nào giữa các bên. <br />
                      4. EMVN là chủ sở hữu duy nhất các quyền tác giả và quyền
                      liên quan đối của Bản ghi tại lãnh thổ Việt Nam. Theo đó,
                      EMVN (i) có đầy đủ quyền pháp lý để cấp phép sử dụng Bản
                      ghi theo quy định tại Thỏa thuận ; (iii) việc sử dụng Bản
                      ghi theo Thỏa thuận &nbsp;này của Bên được cấp phép sẽ
                      không vi phạm bất cứ quyền của bên thứ ba nào. <br />
                      5. Bên được cấp phép sẽ chỉ sử dụng các Bản ghi theo đúng
                      hình thức, phạm vi, thời hạn quy định tại Thỏa thuận này;{' '}
                      <br />
                      <br />‍<br />‍
                      <strong className='bold-text-8'>
                        TUYÊN BỐ TỪ CHỐI TRÁCH NHIỆM
                        <br />‍
                      </strong>
                      EMVN từ chối mọi đảm bảo rõ ràng hoặc ngụ ý, bao gồm nhưng
                      không giới hạn: <br />
                      1. Các bảo đảm, ngụ ý về tính thương mại hoặc sự phù hợp
                      cho một mục đích cụ thể; <br />
                      2. Các bảo đảm về chất lượng hoặc hiệu suất của các tài
                      liệu, thông tin, hàng hóa, dịch vụ, công nghệ và/hoặc nội
                      dung được cung cấp theo hoặc liên quan đến thỏa thuận này,
                      bao gồm nhưng không giới hạn bất kỳ giới hạn nào đối với
                      việc truy cập hoặc sử dụng nội dung của Bên được cấp phép;{' '}
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BỒI THƯỜNG
                        <br />‍
                      </strong>
                      1. Các bên đồng ý bảo vệ, bồi thường và giữ cho nhau vô
                      hại khỏi mọi khiếu nại, trách nhiệm pháp lý, yêu cầu, chi
                      phí, tổn thất, thiệt hại hoặc chi phí khác, bao gồm phí
                      luật sư và chi phí tòa án hợp lý, phát sinh từ bất kỳ vi
                      phạm vật chất hoặc thất bại của bất kỳ điều khoản, điều
                      kiện, đại diện và / hoặc bảo hành được thực hiện ở đây.
                      <br />
                      2. EMVN không chịu trách nhiệm pháp lý cho bất kỳ thiệt
                      hại gián tiếp, ngẫu nhiên, hay bất kỳ khoản phí bồi
                      thường, trừng phạt hay khoản phạt thiệt hại nào phát sinh
                      từ hoặc liên quan đến Thỏa thuận này mà không phải do hành
                      vi cố ý vi phạm của EMVN.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẢO MẬT THÔNG TIN
                        <br />‍
                      </strong>
                      1. Tất cả các thông tin cấp cho Bên được cấp phép thông
                      qua địa chỉ email đã đăng ký, số điện thoại (bao gồm điện
                      thoại di động), RSS hoặc các nguồn cấp thông tin tương tự,
                      hoặc thông qua Thư viện đều được xem là thông tin mật (“
                      <strong>Thông tin mật</strong>”), trừ trường hợp có quy
                      định rõ ràng khác.
                      <br />
                      2. Các bên không được tiết lộ cho bất cứ bên nào khác bất
                      kỳ Thông tin mật nói trên trừ trường hợp được chấp thuận
                      bằng văn bản của Các bên hoặc trường hợp phải tiết lộ để
                      chứng minh phạm vi cấp quyền hoặc theo yêu cầu của cơ quan
                      nhà nước có thẩm quyền. <br />
                      3. Các bên cùng hiểu rằng, việc tiết lộ thông tin mật có
                      thể gây ra những thiệt hại không thể khắc phục đối với mỗi
                      bên. Đối tác thiệt hại có quyền được đòi bồi thường thiệt
                      hại cho mỗi vi phạm đồng thời áp dụng các biện pháp khắc
                      phục khác.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẤT KHẢ KHÁNG
                        <br />‍
                      </strong>
                      1. Sự kiện bất khả kháng: Là sự kiện mà Các bên không thể
                      lường trước được cũng như không thể ngăn chặn sự kiện xảy
                      ra hoặc kết quả của sự kiện, bao gồm nhưng không giới hạn
                      ở các sự kiện sau: chính sách pháp luật thay đổi, động
                      đất, sóng thần, lũ lụt, lốc xoáy và các thảm họa tự nhiên
                      khác, bạo động, khủng bố, hỏa hoạn, tình trạng chiến tranh
                      hoặc các hành động quân sự khác, các lỗi kỹ thuật phát
                      sinh do các đợt tấn công mạng.
                      <br />
                      2. Nếu bất cứ Bên nào không thể thực hiện được toàn bộ
                      hoặc một phần Thỏa thuận này do Sự kiện bất khả kháng thì
                      Bên đó phải thông báo cho Bên còn lại bằng văn bản về việc
                      không thực hiện được nghĩa vụ của mình do Sự kiện bất khả
                      kháng trong thời gian 10 (mười) ngày kể từ ngày xảy ra Sự
                      kiện bất khả kháng.
                      <br />
                      3. Quyền và nghĩa vụ của các bên theo Thỏa thuận ngay lập
                      tức khôi phục khi chấm dứt Sự kiện bất khả kháng hoặc khi
                      Sự kiện bất khả kháng đó bị loại bỏ.
                      <br />
                      4. Bên thông báo việc thực hiện Thỏa thuận của họ trở nên
                      không thể thực hiện được do Sự kiện bất khả kháng có trách
                      nhiệm phải thực hiện mọi nỗ lực để giảm thiểu ảnh hưởng
                      của Sự kiện bất khả kháng đó.
                      <br />
                      5. Nếu sự kiện bất khả kháng kéo dài hơn 02 (hai) tháng,
                      hai bên sẽ thỏa thuận về hiệu lực của Thỏa thuận hoặc xem
                      xét việc thực hiện hợp lý những điều khoản chịu ảnh hưởng
                      của Sự kiện bất khả kháng. Điều này không loại trừ khả
                      năng chấm dứt Thỏa thuận của bên có quyền bị ảnh hưởng bởi
                      Sự kiện Bất khả kháng bằng một thông báo văn bản cho bên
                      còn lại.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN SỬA ĐỔI
                        <br />‍
                      </strong>
                      1. EMVN toàn quyền chủ động quyết định về các thay đổi
                      liên quan đến thỏa thuận này. Bên được cấp phép sẽ nhận
                      được thông báo về việc thay đổi thông qua email đăng ký
                      Tài khoản tại Thư viện âm nhạc EMVN. <br />
                      2. Tại thời điểm nhận được thông báo, Bên được cấp phép có
                      quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Việc
                      Bên được cấp phép tiếp tục sử dụng các Bản ghi theo thỏa
                      thuận sau khi thay đổi phát sinh sẽ là căn cứ thể hiện
                      rằng Bên được cấp phép chấp nhận việc thay đổi này.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        HIỆU LỰC TỪNG PHẦN
                        <br />‍
                      </strong>
                      Nếu bất kỳ điều khoản nào tại đây mâu thuẫn với luật áp
                      dụng hoặc bị toà án có thẩm quyền tuyên bố là trái pháp
                      luật, điều khoản đó sẽ được xem xét điều chỉnh tới mức có
                      thể thực hiện được.. Trong trường hợp cần thiết, điều
                      khoản không hợp lệ hoặc không thể thực thi nói trên sẽ
                      được thay thế bằng điều khoản hợp lệ hoặc có thể thi hành
                      hoặc loại bỏ khỏi Thỏa thuận. Việc điều chỉnh thay thế,
                      loại bỏ không ảnh hưởng tới các Điều khoản khác, Thỏa
                      thuận vẫn duy trì hiệu lực miễn là mục đích thiết yếu của
                      nó vẫn được đảm bảo.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        LUẬT ÁP DỤNG
                        <br />‍
                      </strong>
                      Thỏa thuận này được điều chỉnh và giải nghĩa theo pháp
                      luật Việt Nam. Theo đó, các giao dịch khác liên quan tới
                      Thỏa thuận này đều chịu sự điều chỉnh của pháp luật Việt
                      Nam.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        GIẢI QUYẾT TRANH CHẤP
                        <br />‍
                      </strong>
                      Tất cả các tranh chấp liên quan đến Thỏa thuận này sẽ được
                      giải quyết thông qua thương lượng, hòa giải. Trong trường
                      hợp Các Bên không đạt được thỏa thuận chung thì tranh chấp
                      sẽ được đưa ra giải quyết tại Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam (VIAC) theo Quy tắc tố tụng trọng tài của Trung
                      tâm này. Cụ thể như sau: (i) Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam bên cạnh Phòng Thương Mại Và Công Nghiệp Việt Nam
                      tại Hà Nội, Việt Nam . (ii) Hội đồng trọng tài gồm 03 (ba)
                      trọng tài viên do VIAC chỉ định (iii) Tố tụng trọng tài
                      bằng tiếng việt và tiến hành theo thủ tục rút gọn Điều 37
                      Quy tắc tố tụng trọng tài của VIAC. <br />‍<br />
                      <strong>
                        Thỏa thuận này được cập nhật lần cuối vào ngày
                        07/07/2020.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div id='Professional-Creator' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>Professional Creator</h3>
                  <div>
                    <p className='subtitle'>
                      <strong className='bold-text-8'>
                        ĐỊNH NGHĨA
                        <br />‍
                      </strong>
                      <em>
                        Trong Thỏa thuận này, các từ viết hoa có nghĩa như sau:
                        <br />
                        1. “
                      </em>
                      <strong>
                        <em>Thư viện EMVN</em>
                      </strong>
                      <em>
                        ” là thư viện âm thanh, âm nhạc bản quyền được cung cấp
                        bởi EMVN tại đường dẫn{' '}
                      </em>
                      <a href='https://emvn.c/'>
                        <em>https://emvn.co</em>
                      </a>
                      <em>
                        .<br />
                        2. “
                      </em>
                      <strong className='bold-text-10'>
                        <em>Bản ghi</em>
                      </strong>
                      <em>
                        ” là bản ghi âm tác phẩm âm nhạc, tiếng động thuộc Thư
                        viện EMVN, do EMVN sở hữu và/hoặc có quyền phân phối.
                        <br />
                        3. “
                      </em>
                      <strong className='bold-text-11'>
                        <em>Subscription</em>
                      </strong>
                      <em>
                        ” là gói bản quyền âm nhạc do EMVN cung cấp cho cá nhân,
                        tổ chức, pháp nhân bao gồm quyền truy cập không giới hạn
                        và quyền sử dụng không giới hạn Bản ghi thuộc Thư viện
                        EMVN theo các điều khoản tại Thỏa thuận này.{' '}
                      </em>
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        PHẠM VI CẤP QUYỀN
                        <br />‍
                      </strong>
                      1. Theo các điều khoản của Thỏa thuận này, EMVN cấp cho
                      Bên được cấp phép quyền:
                      <br />
                      1.1. Truy cập, tạo bản sao Bản ghi để đồng bộ toàn bộ/một
                      phần vào sản phẩm nghe nhìn của Bên được cấp phép (“
                      <strong>Chương trình</strong>”) trong phạm vi lãnh thổ
                      Việt Nam và trong thời hạn của Thỏa thuận này. Để tránh
                      nhầm lẫn, trong phạm vi Thỏa thuận này, Chương trình không
                      bao gồm quảng cáo, trailer điện ảnh, phim điện ảnh, các
                      nội dung sản xuất và/hoặc liên kết sản xuất cho bên thứ ba
                      bất kỳ(không giới hạn hình thức thể hiện);
                      <br />
                      1.2 Đăng tải Chương trình lên các nền tảng bao gồm YouTube
                      và các trang mạng xã hội: Facebook, Instagram (“
                      <strong>Nền tảng</strong>”) trong thời hạn của Thỏa thuận
                      này. <br />
                      <br />
                      2. Trong phạm vi Thỏa thuận này, Bên được cấp phép có
                      quyền cấp các quyền thứ cấp sau: <br />
                      2.1. Cấp phép các quyền tại Mục 1.1 cho các đơn vị sản
                      xuất độc lập với mục đích thay mặt Bên được cấp phép tạo
                      ra Chương trình. Tuy nhiên, việc cấp phép này chỉ được
                      chấp nhận và có hiệu lực trong phạm vi Thỏa thuận này. Bên
                      được cấp phép có nghĩa vụ thông báo bằng văn bản tới EMVN
                      và là Bên chịu mọi trách nhiệm đối với Thỏa thuận này;
                      <br />
                      2.2. Cấp phép các quyền tại Mục 1.2 cho bên thứ ba. Hai
                      bên đồng ý rằng, Bên được cấp phép có trách nhiệm (i)
                      thông báo bằng văn bản tới EMVN trong vòng 10 (mười) ngày
                      kể từ ngày phát sinh thay đổi (ii) đảm bảo không vi phạm
                      Thỏa thuận này và (iii) nhận ủy quyền của bên thứ ba chịu
                      trách nhiệm tiếp tục thực hiện Thỏa thuận này.
                      <br />
                      <br />
                      3.Gói Subscription được áp dụng cho hầu hết các mục đích
                      sử dụng thương mại, trừ các trường hợp cụ thể sau đây:
                      <br />
                      <span className='text-span-8'>
                        3.1. Bên được cấp phép là/là một phần: (i) Cá nhân hoặc
                        nhóm sáng tạo nội dung chuyên nghiệp có từ 500.000 (Năm
                        trăm ngàn)người theo dõi/đăng ký; (ii) Pháp nhân có
                        ngành nghề kinh doanh chính là/hoặc liên quan đến sản
                        xuất và hoặc kinh doanh dịch vụ sản xuất nội dung, quảng
                        cáo, truyền thông, cung cấp cơ sở vật chất và các dịch
                        vụ liên quan, và/hoặc thu về lợi nhuận từ những hoạt
                        động này, bao gồm nhưng không giới hạn các loại hình
                        kinh doanh; (iii) Pháp nhân, bao gồm nhưng không giới
                        hạn các nhà xuất bản kỹ thuật số, nhà xuất bản podcast,
                        nhà xuất bản trò chơi video, đài truyền hình và các công
                        ty truyền thông tương tự;.
                        <br />
                        3.2. Sử dụng Bản ghi trong: (i) Các chương trình sản
                        xuất liên và/hoặc kết sản xuất cho bên thứ ba bất kỳ
                        (ii) Chương trình truyền hình, quảng cáo truyền hình,
                        nhạc hiệu cho chương trình, thương hiệu (iii) trailer
                        điện ảnh, phim điện ảnh; (iv)Các hình thức cho phép bên
                        thứ ba bất kỳ tải xuống và/hoặc truy cập hoặc sử dụng
                        Bản ghi một cách độc lập, bao gồm nhưng không giới hạn
                        các hình thức kỹ thuật số, ứng dụng cho phép người dùng
                        cuối đồng bộ và/hoặc kết hợp Bản ghi với các nội dung
                        khác, tạo ra các sản phẩm tùy chỉnh (như thiệp chúc mừng
                        điện tử hoặc nhạc chuông); hoặc tạo ra các sản phẩm vật
                        lý có chứa sẵn Bản ghi (loa thu âm sẵn); (v) Các nội
                        dung, hình thức có tính chất vi phạm pháp luật/kích động
                        hành vi vi phạm pháp luật; trái với các chuẩn mực đạo
                        đức, thuần phong mỹ tục (v)Các trường hợp sử dụng Bản
                        ghi liên quan đến các chủ đề nhạy cảm, bao gồm, nhưng
                        không giới hạn ở nội dung chính trị, video dành cho
                        người lớn hoặc tương tự. Bên được cấp phép có nghĩa vụ
                        thông báo bằng văn bản cho EMVN. Tại thời điểm nhận được
                        thông báo, EMVN có quyền quyết định có/không tiếp tục
                        cung cấp &nbsp;Subscription &nbsp;tại Thỏa thuận này;
                        <br />
                        3.3 Đăng tải Chương trình trên các Nền tảng (i) Các nền
                        tảng chia sẻ video trực tuyến, ngoại &nbsp;trừ YouTube
                        (ii) các nền tảng truyền hình tuyến tính bao gồm nhưng
                        không giới hạn phát sóng truyền hình, truyền hình cáp,
                        truyền hình vệ tinh; (iii) trong trình diễn sân khấu;
                        (iv) phát trực tuyến thương mại, bao gồm nhưng không
                        giới hạn Netflix, iTunes, Hulu, Facebook Watch, các dịch
                        vụ phòng tập &nbsp;trực tuyến dưới dạng VOD, OTT, AVOD,
                        FVOD và SVOD hoặc tương tự){' '}
                      </span>
                      <br />
                      <br />
                      4. Gói Subscription chỉ cấp cho một Tài khoản. Trong
                      trường hợp có vi phạm hoặc có lý do để tin rằng có nhiều
                      hơn một tài khoản sử dụng gói này, &nbsp;EMVN có quyền (i)
                      chấm dứt Thỏa thuận mà không phải trả bất kỳ một khoản
                      phí, bồi thường nào (i) Bên được cấp phép sẽ phải chịu mọi
                      trách nhiệm thanh toán tiền phí cho mỗi tài khoản sử dụng
                      trái phép đó theo đúng mức giá niêm yết ở thời điểm thực
                      hiện Thỏa thuận. Để tránh vấn đề này, vui lòng liên hệ với
                      EMVN để được hướng dẫn điều chỉnh gói dịch vụ phù hợp;
                      <br />
                      <br />
                      5. Các quyền được cấp tại mục 1.1 Điều này chỉ cho phép
                      các điều chỉnh như chia, cắt, lặp lại Bản ghi để phục vụ
                      nhu cầu sử dụng theo Thỏa thuận này, không cho phép thực
                      hiện các tác động làm thay đổi Bản ghi, bao gồm nhưng
                      không giới hạn remix, cover, cải biên, chuyển thể. &nbsp;
                      <br />
                      <br />
                      6. Các quyền, sản phẩm, dịch vụ mà EMVN cấp theo Thỏa
                      thuận này là không độc quyền, không thể chuyển nhượng và
                      giới hạn tuyệt đối trong phạm vi các quyền tại Mục 1. Theo
                      đó, bất kỳ hành động nào phát sinh ngoài phạm vi Thỏa
                      thuận đều được xem là vi phạm bản quyền, Bên được cấp phép
                      phải trả tiền bản quyền theo quy định và chịu mọi trách
                      nhiệm pháp lý liên quan. <br />‍<br />
                      7. EMVN có thể quyền chuyển Thỏa thuận này và/hoặc chuyển
                      nhượng toàn bộ hoặc một phần hoặc cam kết bất kỳ quyền
                      và/hoặc nghĩa vụ nào của Thỏa thuận này cho bất kỳ bên thứ
                      ba nào miễn là bên thứ ba đó đồng ý ràng buộc với Thỏa
                      thuận này.
                      <br />
                      <br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>KIẾM TIỀN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Bên được cấp phép có quyền kiếm tiền từ việc đăng tải
                      Chương trình lên các Nền tảng có chức năng này. &nbsp;Để
                      tránh nhầm lẫn, các Chương trình được sản xuất và đăng tải
                      trên Nền tảng trong thời hạn của Thỏa thuận này sẽ được
                      cấp quyền này trọn đời.
                      <br />
                      2. Trong trường hợp đăng tải Chương trình lên nền tảng
                      Youtube, Bên được cấp phép sẽ tự động nhận được thông báo
                      xác nhận sở hữu bản quyền. Để tránh tình trạng này, Bên
                      được cấp phép có thể yêu cầu đưa Tài khoản của mình vào
                      danh sách trắng (“
                      <strong>whitelist</strong>”) theo mẫu tại{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                        EMVN Contact
                      </a>
                      . Sau khi được whitelist, trong một vài trường hợp, Bên
                      cấp phép có thể vẫn nhận được thông báo xác nhận sở hữu
                      bản quyền, EMVN có nghĩa vụ hỗ trợ Bên được cấp phép cho
                      đến khi các xác nhận sở hữu bản quyền được giải quyết
                      xong. Các bên đồng ý rằng, việc hỗ trợ này chỉ áp dụng đối
                      với các Chương trình được sản xuất trong thời hạn, phạm vi
                      Thỏa thuận này.
                      <br />
                      3. Để tránh nhầm lẫn, các bên đồng ý rằng, Bên được cấp
                      phép chỉ có thể whitelist một kênh. <br />
                      4. Trong trường hợp Bên được cấp phép là đối tượng tại Mục
                      1, các video vẫn được phép kiếm tiền trên Nền tảng và phần
                      xác nhận sở hữu được sử dụng để trả tiền cho các tổ chức
                      tác quyền. EMVN không can thiệp và/hoặc có nghĩa vụ can
                      thiệp vào vấn đề này. Tuy nhiên, EMVN sẽ hỗ trợ Bên được
                      cấp phép trong phạm vi có thể.
                      <br />‍<br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>
                          THANH TOÁN &amp; HOÀN TRẢ
                        </strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Chi tiết khoản thanh toán được thể hiện tại Thông tin
                      đơn hàng. Bên được cấp phép không cần phải trả thêm bất kỳ
                      một khoản phí liên quan nào khác đến việc sử dụng các
                      quyền được cấp phép theo Thỏa thuận này. Mọi quyền và
                      nghĩa vụ chỉ có hiệu lực khi khoản thanh toán được hoàn
                      tất.
                      <br />
                      2. VAT: Khoản thanh toán trên đã bao gồm VAT. Nếu Bên được
                      cấp phép có bất kỳ yêu cầu nào khác về hóa đơn VAT, vui
                      lòng liên hệ EMVN tại:{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf' target='_blank'>
                        EMVN Contact
                      </a>{' '}
                      <br />
                      3. Không hoàn lại: Tất cả các khoản thanh toán là không
                      hoàn lại và không loại trừ việc chỉ sử dụng dịch vụ một
                      phần.
                      <br />
                      <br />
                      <br />
                      <a href='#' className='bold-text-8'>
                        ‍<strong>THỜI HẠN &amp; CHẤM DỨT THỎA THUẬN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Thỏa thuận này có có hiệu lực trong vòng 01 (một) năm
                      kể từ ngày Khách hàng nhận được email cấp Tài khoản.{' '}
                      <br />‍<br />
                      2. EMVN sẽ thông báo về ngày hết hạn tới Tài khoản của Bên
                      được cấp phép trong vòng 01 (một tháng) trước ngày hết
                      hạn. Tại thời điểm nhận được thông báo, Bên được cấp phép
                      có quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Nếu
                      trước ngày hết hạn, EMVN nhận được khoản thanh toán mới
                      của Bên được cấp phép, Thỏa thuận sẽ tự động gia hạn với
                      các điều khoản tương tự, trừ trường hợp có quy định khác;
                      <br />‍<br />
                      3. Thỏa thuận này sẽ chấm dứt hiệu lực trong các trường
                      hợp sau: &nbsp;
                      <br />
                      3.1. EMVN có quyền đơn phương chấm dứt Thỏa thuận bất cứ
                      lúc nào và yêu cầu bồi thường thiệt hại nếu Bên được cấp
                      phép sử dụng Bản ghi trái phép hoặc vi phạm các quy định
                      tại Thỏa thuận này. &nbsp;Trong trường hợp này, EMVN sẽ
                      không có/chịu bất cứ trách nhiệm nào và không hoàn trả lại
                      bất kỳ khoản thanh toán nào.
                      <br />
                      3.2. Một bên đơn phương chấm dứt thỏa thuận trái với Thỏa
                      thuận thì phải bồi thường cho Bên còn lại các thiệt hại
                      phát sinh (nếu có).
                      <br />
                      3.3. Thỏa thuận hết hạn và Bên được cấp phép không tiến
                      hành gia hạn thêm.
                      <br />
                      <br />
                      4. Sau khi chấm dứt Thỏa thuận này, Tài khoản được cấp sẽ
                      bị vô hiệu, Bên được cấp phép không còn quyền sử dụng các
                      danh mục Bản ghi, Bản ghi theo Thỏa thuận này. Theo đó,
                      Bên được cấp phép có nghĩa vụ hủy, xóa mọi bản sao của
                      danh mục Bản ghi,bản ghi &nbsp;khỏi mọi bộ nhớ, nơi lưu
                      trữ (không giới hạn hình thức, thiết bị lưu trữ).
                      <br />
                      <br />
                      5. Các bên đồng ý rằng, sau khi chấm dứt Thỏa thuận, EMVN
                      sẽ thực hiện mọi hành động cần thiết để phát hiện, ngăn
                      chặn việc tiếp tục sử dụng các Bản ghi tại Thỏa thuận.
                      <br />
                      <br />
                      6. Để tránh nhầm lẫn, &nbsp;Bên được cấp phép được phép
                      tiếp tục phân phối các Chương trình được hoàn thành trong
                      thời hạn của Thỏa thuận này cho bên thứ ba.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN QUẢNG BÁ
                        <br />
                      </strong>
                      1. Nếu Bản ghi theo Thỏa thuận được sử dụng trong Chương
                      trình, Bên được cấp phép có nghĩa vụ giới thiệu nhà cung
                      cấp là EMVN trong phần mô tả video theo mẫu: Âm nhạc cung
                      cấp bởi EMVN – http://emvn.co. Trong trường hợp cho phép,
                      địa chỉ chú thích trên nên để ở dạng đường dẫn liên kết{' '}
                      <a href='http://emvn.co/'>http://emvn.co</a>.<br />
                      2. EMVN được quyền sử dụng Chương trình (một phần hay toàn
                      bộ), hình ảnh, tên tuổi của Bên được cấp phép với mục đích
                      quảng bá trên kênh riêng của mình, bao gồm nhưng không
                      giới hạn trang web, các nền tảng mạng xã hội (Facebook,
                      Instagram, Twitter..); Các bên đồng ý rằng, quyền này chỉ
                      có hiệu lực khi có sự cho phép của Bên được cấp phép
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN BẢO ĐẢM
                        <br />‍
                      </strong>
                      1. Các bên cam kết đảm bảo về năng lực, quyền hạn khi tham
                      gia vào Thỏa thuận này;
                      <br />
                      2. Mọi thông tin trong giao dịch này được Các bên nỗ lực
                      cung cấp trung thực, chính xác và không gây hiểu lầm trong
                      bất kỳ khía cạnh trọng yếu nào. <br />
                      3. Các bên độc lập tham gia Thỏa thuận này. Không có bất
                      kỳ nội dung nào trong Thỏa thuận này có ý định tạo ra một
                      đại lý, sự cộng tác, hoặc một liên doanh dưới bất kỳ
                      phương thức nào giữa các bên. <br />
                      4. EMVN là chủ sở hữu duy nhất các quyền tác giả và quyền
                      liên quan đối của Bản ghi tại lãnh thổ Việt Nam. Theo đó,
                      EMVN (i) có đầy đủ quyền pháp lý để cấp phép sử dụng Bản
                      ghi theo quy định tại Thỏa thuận ; (iii) việc sử dụng Bản
                      ghi theo Thỏa thuận &nbsp;này của Bên được cấp phép sẽ
                      không vi phạm bất cứ quyền của bên thứ ba nào. <br />
                      5. Bên được cấp phép sẽ chỉ sử dụng các Bản ghi theo đúng
                      hình thức, phạm vi, thời hạn quy định tại Thỏa thuận này;{' '}
                      <br />
                      <br />‍<br />‍
                      <strong className='bold-text-8'>
                        TUYÊN BỐ TỪ CHỐI TRÁCH NHIỆM
                        <br />‍
                      </strong>
                      EMVN từ chối mọi đảm bảo rõ ràng hoặc ngụ ý, bao gồm nhưng
                      không giới hạn: <br />
                      1. Các bảo đảm, ngụ ý về tính thương mại hoặc sự phù hợp
                      cho một mục đích cụ thể; <br />
                      2. Các bảo đảm về chất lượng hoặc hiệu suất của các tài
                      liệu, thông tin, hàng hóa, dịch vụ, công nghệ và/hoặc nội
                      dung được cung cấp theo hoặc liên quan đến thỏa thuận này,
                      bao gồm nhưng không giới hạn bất kỳ giới hạn nào đối với
                      việc truy cập hoặc sử dụng nội dung của Bên được cấp phép;{' '}
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BỒI THƯỜNG
                        <br />‍
                      </strong>
                      1. Các bên đồng ý bảo vệ, bồi thường và giữ cho nhau vô
                      hại khỏi mọi khiếu nại, trách nhiệm pháp lý, yêu cầu, chi
                      phí, tổn thất, thiệt hại hoặc chi phí khác, bao gồm phí
                      luật sư và chi phí tòa án hợp lý, phát sinh từ bất kỳ vi
                      phạm vật chất hoặc thất bại của bất kỳ điều khoản, điều
                      kiện, đại diện và / hoặc bảo hành được thực hiện ở đây.
                      <br />
                      2. EMVN không chịu trách nhiệm pháp lý cho bất kỳ thiệt
                      hại gián tiếp, ngẫu nhiên, hay bất kỳ khoản phí bồi
                      thường, trừng phạt hay khoản phạt thiệt hại nào phát sinh
                      từ hoặc liên quan đến Thỏa thuận này mà không phải do hành
                      vi cố ý vi phạm của EMVN.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẢO MẬT THÔNG TIN
                        <br />‍
                      </strong>
                      1. Tất cả các thông tin cấp cho Bên được cấp phép thông
                      qua địa chỉ email đã đăng ký, số điện thoại (bao gồm điện
                      thoại di động), RSS hoặc các nguồn cấp thông tin tương tự,
                      hoặc thông qua Thư viện đều được xem là thông tin mật (“
                      <strong>Thông tin mật</strong>”), trừ trường hợp có quy
                      định rõ ràng khác.
                      <br />
                      2. Các bên không được tiết lộ cho bất cứ bên nào khác bất
                      kỳ Thông tin mật nói trên trừ trường hợp được chấp thuận
                      bằng văn bản của Các bên hoặc trường hợp phải tiết lộ để
                      chứng minh phạm vi cấp quyền hoặc theo yêu cầu của cơ quan
                      nhà nước có thẩm quyền. <br />
                      3. Các bên cùng hiểu rằng, việc tiết lộ thông tin mật có
                      thể gây ra những thiệt hại không thể khắc phục đối với mỗi
                      bên. Đối tác thiệt hại có quyền được đòi bồi thường thiệt
                      hại cho mỗi vi phạm đồng thời áp dụng các biện pháp khắc
                      phục khác.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẤT KHẢ KHÁNG
                        <br />‍
                      </strong>
                      1. Sự kiện bất khả kháng: Là sự kiện mà Các bên không thể
                      lường trước được cũng như không thể ngăn chặn sự kiện xảy
                      ra hoặc kết quả của sự kiện, bao gồm nhưng không giới hạn
                      ở các sự kiện sau: chính sách pháp luật thay đổi, động
                      đất, sóng thần, lũ lụt, lốc xoáy và các thảm họa tự nhiên
                      khác, bạo động, khủng bố, hỏa hoạn, tình trạng chiến tranh
                      hoặc các hành động quân sự khác, các lỗi kỹ thuật phát
                      sinh do các đợt tấn công mạng.
                      <br />
                      2. Nếu bất cứ Bên nào không thể thực hiện được toàn bộ
                      hoặc một phần Thỏa thuận này do Sự kiện bất khả kháng thì
                      Bên đó phải thông báo cho Bên còn lại bằng văn bản về việc
                      không thực hiện được nghĩa vụ của mình do Sự kiện bất khả
                      kháng trong thời gian 10 (mười) ngày kể từ ngày xảy ra Sự
                      kiện bất khả kháng.
                      <br />
                      3. Quyền và nghĩa vụ của các bên theo Thỏa thuận ngay lập
                      tức khôi phục khi chấm dứt Sự kiện bất khả kháng hoặc khi
                      Sự kiện bất khả kháng đó bị loại bỏ.
                      <br />
                      4. Bên thông báo việc thực hiện Thỏa thuận của họ trở nên
                      không thể thực hiện được do Sự kiện bất khả kháng có trách
                      nhiệm phải thực hiện mọi nỗ lực để giảm thiểu ảnh hưởng
                      của Sự kiện bất khả kháng đó.
                      <br />
                      5. Nếu sự kiện bất khả kháng kéo dài hơn 02 (hai) tháng,
                      hai bên sẽ thỏa thuận về hiệu lực của Thỏa thuận hoặc xem
                      xét việc thực hiện hợp lý những điều khoản chịu ảnh hưởng
                      của Sự kiện bất khả kháng. Điều này không loại trừ khả
                      năng chấm dứt Thỏa thuận của bên có quyền bị ảnh hưởng bởi
                      Sự kiện Bất khả kháng bằng một thông báo văn bản cho bên
                      còn lại.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN SỬA ĐỔI
                        <br />‍
                      </strong>
                      1. EMVN toàn quyền chủ động quyết định về các thay đổi
                      liên quan đến thỏa thuận này. Bên được cấp phép sẽ nhận
                      được thông báo về việc thay đổi thông qua email đăng ký
                      Tài khoản tại Thư viện âm nhạc EMVN. <br />
                      2. Tại thời điểm nhận được thông báo, Bên được cấp phép có
                      quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Việc
                      Bên được cấp phép tiếp tục sử dụng các Bản ghi theo thỏa
                      thuận sau khi thay đổi phát sinh sẽ là căn cứ thể hiện
                      rằng Bên được cấp phép chấp nhận việc thay đổi này.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        HIỆU LỰC TỪNG PHẦN
                        <br />‍
                      </strong>
                      Nếu bất kỳ điều khoản nào tại đây mâu thuẫn với luật áp
                      dụng hoặc bị toà án có thẩm quyền tuyên bố là trái pháp
                      luật, điều khoản đó sẽ được xem xét điều chỉnh tới mức có
                      thể thực hiện được.. Trong trường hợp cần thiết, điều
                      khoản không hợp lệ hoặc không thể thực thi nói trên sẽ
                      được thay thế bằng điều khoản hợp lệ hoặc có thể thi hành
                      hoặc loại bỏ khỏi Thỏa thuận. Việc điều chỉnh thay thế,
                      loại bỏ không ảnh hưởng tới các Điều khoản khác, Thỏa
                      thuận vẫn duy trì hiệu lực miễn là mục đích thiết yếu của
                      nó vẫn được đảm bảo.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        LUẬT ÁP DỤNG
                        <br />‍
                      </strong>
                      Thỏa thuận này được điều chỉnh và giải nghĩa theo pháp
                      luật Việt Nam. Theo đó, các giao dịch khác liên quan tới
                      Thỏa thuận này đều chịu sự điều chỉnh của pháp luật Việt
                      Nam.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        GIẢI QUYẾT TRANH CHẤP
                        <br />‍
                      </strong>
                      Tất cả các tranh chấp liên quan đến Thỏa thuận này sẽ được
                      giải quyết thông qua thương lượng, hòa giải. Trong trường
                      hợp Các Bên không đạt được thỏa thuận chung thì tranh chấp
                      sẽ được đưa ra giải quyết tại Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam (VIAC) theo Quy tắc tố tụng trọng tài của Trung
                      tâm này. Cụ thể như sau: (i) Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam bên cạnh Phòng Thương Mại Và Công Nghiệp Việt Nam
                      tại Hà Nội, Việt Nam . (ii) Hội đồng trọng tài gồm 03 (ba)
                      trọng tài viên do VIAC chỉ định (iii) Tố tụng trọng tài
                      bằng tiếng việt và tiến hành theo thủ tục rút gọn Điều 37
                      Quy tắc tố tụng trọng tài của VIAC. <br />‍<br />
                      <strong>
                        Thỏa thuận này được cập nhật lần cuối vào ngày
                        07/07/2020.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div id='Music' className='faq-set no-margin'>
                <div id='Industrial-Producer' className='set-title'>
                  <h3 className='h3 big'>Industrial Producer</h3>
                  <div>
                    <p className='subtitle'>
                      <strong className='bold-text-8'>
                        ĐỊNH NGHĨA
                        <br />‍
                      </strong>
                      <em>
                        Trong Thỏa thuận này, các từ viết hoa có nghĩa như sau:
                        <br />
                        1. “
                      </em>
                      <strong>
                        <em>Thư viện EMVN</em>
                      </strong>
                      <em>
                        ” là thư viện âm thanh, âm nhạc bản quyền được cung cấp
                        bởi EMVN tại đường dẫn{' '}
                      </em>
                      <a href='https://emvn.c/'>
                        <em>https://emvn.co</em>
                      </a>
                      <em>
                        .<br />
                        2. “
                      </em>
                      <strong className='bold-text-10'>
                        <em>Bản ghi</em>
                      </strong>
                      <em>
                        ” là bản ghi âm tác phẩm âm nhạc, tiếng động thuộc Thư
                        viện EMVN, do EMVN sở hữu và/hoặc có quyền phân phối.
                        <br />
                        3. “
                      </em>
                      <strong className='bold-text-11'>
                        <em>Subscription</em>
                      </strong>
                      <em>
                        ” là gói bản quyền âm nhạc do EMVN cung cấp cho cá nhân,
                        tổ chức, pháp nhân bao gồm quyền truy cập không giới hạn
                        và quyền sử dụng không giới hạn Bản ghi thuộc Thư viện
                        EMVN theo các điều khoản tại Thỏa thuận này.{' '}
                      </em>
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        PHẠM VI CẤP QUYỀN
                        <br />‍
                      </strong>
                      1. Theo các điều khoản của Thỏa thuận này, EMVN cấp cho
                      Bên được cấp phép quyền:
                      <br />
                      1.1. Truy cập, tạo bản sao Bản ghi để đồng bộ toàn bộ/một
                      phần vào sản phẩm nghe nhìn của Bên được cấp phép (“
                      <strong>Chương trình</strong>”) trong phạm vi lãnh thổ
                      Việt Nam và trong thời hạn của Thỏa thuận này. Để tránh
                      nhầm lẫn, trong phạm vi Thỏa thuận này, Chương trình không
                      bao gồm quảng cáo, trailer điện ảnh, phim điện ảnh, các
                      nội dung sản xuất và/hoặc liên kết sản xuất cho bên thứ ba
                      bất kỳ(không giới hạn hình thức thể hiện);
                      <br />
                      1.2 Đăng tải Chương trình lên các nền tảng bao gồm YouTube
                      và các trang mạng xã hội: Facebook, Instagram (“
                      <strong>Nền tảng</strong>”) trong thời hạn của Thỏa thuận
                      này. <br />
                      <br />
                      2. Trong phạm vi Thỏa thuận này, Bên được cấp phép có
                      quyền cấp các quyền thứ cấp sau: <br />
                      2.1. Cấp phép các quyền tại Mục 1.1 cho các đơn vị sản
                      xuất độc lập với mục đích thay mặt Bên được cấp phép tạo
                      ra Chương trình. Tuy nhiên, việc cấp phép này chỉ được
                      chấp nhận và có hiệu lực trong phạm vi Thỏa thuận này. Bên
                      được cấp phép có nghĩa vụ thông báo bằng văn bản tới EMVN
                      và là Bên chịu mọi trách nhiệm đối với Thỏa thuận này;
                      <br />
                      2.2. Cấp phép các quyền tại Mục 1.2 cho bên thứ ba. Hai
                      bên đồng ý rằng, Bên được cấp phép có trách nhiệm (i)
                      thông báo bằng văn bản tới EMVN trong vòng 10 (mười) ngày
                      kể từ ngày phát sinh thay đổi (ii) đảm bảo không vi phạm
                      Thỏa thuận này và (iii) nhận ủy quyền của bên thứ ba chịu
                      trách nhiệm tiếp tục thực hiện Thỏa thuận này.
                      <br />
                      <br />
                      3.Gói Subscription được áp dụng cho hầu hết các mục đích
                      sử dụng thương mại, trừ các trường hợp cụ thể sau đây:
                      <br />
                      <span className='text-span-8'>
                        3.1. Bên được cấp phép là/là một phần: (i) Cá nhân hoặc
                        nhóm sáng tạo nội dung chuyên nghiệp có từ 500.000 (Năm
                        trăm ngàn)người theo dõi/đăng ký; (ii) Pháp nhân có
                        ngành nghề kinh doanh chính là/hoặc liên quan đến sản
                        xuất và hoặc kinh doanh dịch vụ sản xuất nội dung, quảng
                        cáo, truyền thông, cung cấp cơ sở vật chất và các dịch
                        vụ liên quan, và/hoặc thu về lợi nhuận từ những hoạt
                        động này, bao gồm nhưng không giới hạn các loại hình
                        kinh doanh; (iii) Pháp nhân, bao gồm nhưng không giới
                        hạn các nhà xuất bản kỹ thuật số, nhà xuất bản podcast,
                        nhà xuất bản trò chơi video, đài truyền hình và các công
                        ty truyền thông tương tự;.
                        <br />
                        3.2. Sử dụng Bản ghi trong: (i) Các chương trình sản
                        xuất liên và/hoặc kết sản xuất cho bên thứ ba bất kỳ
                        (ii) Chương trình truyền hình, quảng cáo truyền hình,
                        nhạc hiệu cho chương trình, thương hiệu (iii) trailer
                        điện ảnh, phim điện ảnh; (iv)Các hình thức cho phép bên
                        thứ ba bất kỳ tải xuống và/hoặc truy cập hoặc sử dụng
                        Bản ghi một cách độc lập, bao gồm nhưng không giới hạn
                        các hình thức kỹ thuật số, ứng dụng cho phép người dùng
                        cuối đồng bộ và/hoặc kết hợp Bản ghi với các nội dung
                        khác, tạo ra các sản phẩm tùy chỉnh (như thiệp chúc mừng
                        điện tử hoặc nhạc chuông); hoặc tạo ra các sản phẩm vật
                        lý có chứa sẵn Bản ghi (loa thu âm sẵn); (v) Các nội
                        dung, hình thức có tính chất vi phạm pháp luật/kích động
                        hành vi vi phạm pháp luật; trái với các chuẩn mực đạo
                        đức, thuần phong mỹ tục (v)Các trường hợp sử dụng Bản
                        ghi liên quan đến các chủ đề nhạy cảm, bao gồm, nhưng
                        không giới hạn ở nội dung chính trị, video dành cho
                        người lớn hoặc tương tự. Bên được cấp phép có nghĩa vụ
                        thông báo bằng văn bản cho EMVN. Tại thời điểm nhận được
                        thông báo, EMVN có quyền quyết định có/không tiếp tục
                        cung cấp &nbsp;Subscription &nbsp;tại Thỏa thuận này;
                        <br />
                        3.3 Đăng tải Chương trình trên các Nền tảng (i) Các nền
                        tảng chia sẻ video trực tuyến, ngoại &nbsp;trừ YouTube
                        (ii) các nền tảng truyền hình tuyến tính bao gồm nhưng
                        không giới hạn phát sóng truyền hình, truyền hình cáp,
                        truyền hình vệ tinh; (iii) trong trình diễn sân khấu;
                        (iv) phát trực tuyến thương mại, bao gồm nhưng không
                        giới hạn Netflix, iTunes, Hulu, Facebook Watch, các dịch
                        vụ phòng tập &nbsp;trực tuyến dưới dạng VOD, OTT, AVOD,
                        FVOD và SVOD hoặc tương tự){' '}
                      </span>
                      <br />
                      <br />
                      4. Gói Subscription chỉ cấp cho một Tài khoản. Trong
                      trường hợp có vi phạm hoặc có lý do để tin rằng có nhiều
                      hơn một tài khoản sử dụng gói này, &nbsp;EMVN có quyền (i)
                      chấm dứt Thỏa thuận mà không phải trả bất kỳ một khoản
                      phí, bồi thường nào (i) Bên được cấp phép sẽ phải chịu mọi
                      trách nhiệm thanh toán tiền phí cho mỗi tài khoản sử dụng
                      trái phép đó theo đúng mức giá niêm yết ở thời điểm thực
                      hiện Thỏa thuận. Để tránh vấn đề này, vui lòng liên hệ với
                      EMVN để được hướng dẫn điều chỉnh gói dịch vụ phù hợp;
                      <br />
                      <br />
                      5. Các quyền được cấp tại mục 1.1 Điều này chỉ cho phép
                      các điều chỉnh như chia, cắt, lặp lại Bản ghi để phục vụ
                      nhu cầu sử dụng theo Thỏa thuận này, không cho phép thực
                      hiện các tác động làm thay đổi Bản ghi, bao gồm nhưng
                      không giới hạn remix, cover, cải biên, chuyển thể. &nbsp;
                      <br />
                      <br />
                      6. Các quyền, sản phẩm, dịch vụ mà EMVN cấp theo Thỏa
                      thuận này là không độc quyền, không thể chuyển nhượng và
                      giới hạn tuyệt đối trong phạm vi các quyền tại Mục 1. Theo
                      đó, bất kỳ hành động nào phát sinh ngoài phạm vi Thỏa
                      thuận đều được xem là vi phạm bản quyền, Bên được cấp phép
                      phải trả tiền bản quyền theo quy định và chịu mọi trách
                      nhiệm pháp lý liên quan. <br />‍<br />
                      7. EMVN có thể quyền chuyển Thỏa thuận này và/hoặc chuyển
                      nhượng toàn bộ hoặc một phần hoặc cam kết bất kỳ quyền
                      và/hoặc nghĩa vụ nào của Thỏa thuận này cho bất kỳ bên thứ
                      ba nào miễn là bên thứ ba đó đồng ý ràng buộc với Thỏa
                      thuận này.
                      <br />
                      <br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>KIẾM TIỀN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Bên được cấp phép có quyền kiếm tiền từ việc đăng tải
                      Chương trình lên các Nền tảng có chức năng này. &nbsp;Để
                      tránh nhầm lẫn, các Chương trình được sản xuất và đăng tải
                      trên Nền tảng trong thời hạn của Thỏa thuận này sẽ được
                      cấp quyền này trọn đời.
                      <br />
                      2. Trong trường hợp đăng tải Chương trình lên nền tảng
                      Youtube, Bên được cấp phép sẽ tự động nhận được thông báo
                      xác nhận sở hữu bản quyền. Để tránh tình trạng này, Bên
                      được cấp phép có thể yêu cầu đưa Tài khoản của mình vào
                      danh sách trắng (“
                      <strong>whitelist</strong>”) theo mẫu tại{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                        EMVN Contact
                      </a>
                      . Sau khi được whitelist, trong một vài trường hợp, Bên
                      cấp phép có thể vẫn nhận được thông báo xác nhận sở hữu
                      bản quyền, EMVN có nghĩa vụ hỗ trợ Bên được cấp phép cho
                      đến khi các xác nhận sở hữu bản quyền được giải quyết
                      xong. Các bên đồng ý rằng, việc hỗ trợ này chỉ áp dụng đối
                      với các Chương trình được sản xuất trong thời hạn, phạm vi
                      Thỏa thuận này.
                      <br />
                      3. Để tránh nhầm lẫn, các bên đồng ý rằng, Bên được cấp
                      phép chỉ có thể whitelist một kênh. <br />
                      4. Trong trường hợp Bên được cấp phép là đối tượng tại Mục
                      1, các video vẫn được phép kiếm tiền trên Nền tảng và phần
                      xác nhận sở hữu được sử dụng để trả tiền cho các tổ chức
                      tác quyền. EMVN không can thiệp và/hoặc có nghĩa vụ can
                      thiệp vào vấn đề này. Tuy nhiên, EMVN sẽ hỗ trợ Bên được
                      cấp phép trong phạm vi có thể.
                      <br />‍<br />
                      <br />‍
                      <a href='#'>
                        <strong className='bold-text-8'>
                          THANH TOÁN &amp; HOÀN TRẢ
                        </strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Chi tiết khoản thanh toán được thể hiện tại Thông tin
                      đơn hàng. Bên được cấp phép không cần phải trả thêm bất kỳ
                      một khoản phí liên quan nào khác đến việc sử dụng các
                      quyền được cấp phép theo Thỏa thuận này. Mọi quyền và
                      nghĩa vụ chỉ có hiệu lực khi khoản thanh toán được hoàn
                      tất.
                      <br />
                      2. VAT: Khoản thanh toán trên đã bao gồm VAT. Nếu Bên được
                      cấp phép có bất kỳ yêu cầu nào khác về hóa đơn VAT, vui
                      lòng liên hệ EMVN tại:{' '}
                      <a href='https://airtable.com/shr2kH3g6tLNFb5Vf' target='_blank'>
                        EMVN Contact
                      </a>{' '}
                      <br />
                      3. Không hoàn lại: Tất cả các khoản thanh toán là không
                      hoàn lại và không loại trừ việc chỉ sử dụng dịch vụ một
                      phần.
                      <br />
                      <br />
                      <br />
                      <a href='#' className='bold-text-8'>
                        ‍<strong>THỜI HẠN &amp; CHẤM DỨT THỎA THUẬN</strong>
                      </a>
                      <strong>
                        <br />
                      </strong>
                      1. Thỏa thuận này có có hiệu lực trong vòng 01 (một) năm
                      kể từ ngày Khách hàng nhận được email cấp Tài khoản.{' '}
                      <br />‍<br />
                      2. EMVN sẽ thông báo về ngày hết hạn tới Tài khoản của Bên
                      được cấp phép trong vòng 01 (một tháng) trước ngày hết
                      hạn. Tại thời điểm nhận được thông báo, Bên được cấp phép
                      có quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Nếu
                      trước ngày hết hạn, EMVN nhận được khoản thanh toán mới
                      của Bên được cấp phép, Thỏa thuận sẽ tự động gia hạn với
                      các điều khoản tương tự, trừ trường hợp có quy định khác;
                      <br />‍<br />
                      3. Thỏa thuận này sẽ chấm dứt hiệu lực trong các trường
                      hợp sau: &nbsp;
                      <br />
                      3.1. EMVN có quyền đơn phương chấm dứt Thỏa thuận bất cứ
                      lúc nào và yêu cầu bồi thường thiệt hại nếu Bên được cấp
                      phép sử dụng Bản ghi trái phép hoặc vi phạm các quy định
                      tại Thỏa thuận này. &nbsp;Trong trường hợp này, EMVN sẽ
                      không có/chịu bất cứ trách nhiệm nào và không hoàn trả lại
                      bất kỳ khoản thanh toán nào.
                      <br />
                      3.2. `Một bên đơn phương chấm dứt thỏa thuận trái với Thỏa
                      thuận thì phải bồi thường cho Bên còn lại các thiệt hại
                      phát sinh (nếu có).
                      <br />
                      3.3. Thỏa thuận hết hạn và Bên được cấp phép không tiến
                      hành gia hạn thêm.
                      <br />
                      <br />
                      4. Sau khi chấm dứt Thỏa thuận này, Tài khoản được cấp sẽ
                      bị vô hiệu, Bên được cấp phép không còn quyền sử dụng các
                      danh mục Bản ghi, Bản ghi theo Thỏa thuận này. Theo đó,
                      Bên được cấp phép có nghĩa vụ hủy, xóa mọi bản sao của
                      danh mục Bản ghi,bản ghi &nbsp;khỏi mọi bộ nhớ, nơi lưu
                      trữ (không giới hạn hình thức, thiết bị lưu trữ).
                      <br />
                      <br />
                      5. Các bên đồng ý rằng, sau khi chấm dứt Thỏa thuận, EMVN
                      sẽ thực hiện mọi hành động cần thiết để phát hiện, ngăn
                      chặn việc tiếp tục sử dụng các Bản ghi tại Thỏa thuận.
                      <br />
                      <br />
                      6. Để tránh nhầm lẫn, &nbsp;Bên được cấp phép được phép
                      tiếp tục phân phối các Chương trình được hoàn thành trong
                      thời hạn của Thỏa thuận này cho bên thứ ba.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN QUẢNG BÁ
                        <br />
                      </strong>
                      1. Nếu Bản ghi theo Thỏa thuận được sử dụng trong Chương
                      trình, Bên được cấp phép có nghĩa vụ giới thiệu nhà cung
                      cấp là EMVN trong phần mô tả video theo mẫu: Âm nhạc cung
                      cấp bởi EMVN – http://emvn.co. Trong trường hợp cho phép,
                      địa chỉ chú thích trên nên để ở dạng đường dẫn liên kết{' '}
                      <a href='http://emvn.co/'>http://emvn.co</a>.<br />
                      2. EMVN được quyền sử dụng Chương trình (một phần hay toàn
                      bộ), hình ảnh, tên tuổi của Bên được cấp phép với mục đích
                      quảng bá trên kênh riêng của mình, bao gồm nhưng không
                      giới hạn trang web, các nền tảng mạng xã hội (Facebook,
                      Instagram, Twitter..); Các bên đồng ý rằng, quyền này chỉ
                      có hiệu lực khi có sự cho phép của Bên được cấp phép
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN BẢO ĐẢM
                        <br />‍
                      </strong>
                      1. Các bên cam kết đảm bảo về năng lực, quyền hạn khi tham
                      gia vào Thỏa thuận này;
                      <br />
                      2. Mọi thông tin trong giao dịch này được Các bên nỗ lực
                      cung cấp trung thực, chính xác và không gây hiểu lầm trong
                      bất kỳ khía cạnh trọng yếu nào. <br />
                      3. Các bên độc lập tham gia Thỏa thuận này. Không có bất
                      kỳ nội dung nào trong Thỏa thuận này có ý định tạo ra một
                      đại lý, sự cộng tác, hoặc một liên doanh dưới bất kỳ
                      phương thức nào giữa các bên. <br />
                      4. EMVN là chủ sở hữu duy nhất các quyền tác giả và quyền
                      liên quan đối của Bản ghi tại lãnh thổ Việt Nam. Theo đó,
                      EMVN (i) có đầy đủ quyền pháp lý để cấp phép sử dụng Bản
                      ghi theo quy định tại Thỏa thuận ; (iii) việc sử dụng Bản
                      ghi theo Thỏa thuận &nbsp;này của Bên được cấp phép sẽ
                      không vi phạm bất cứ quyền của bên thứ ba nào. <br />
                      5. Bên được cấp phép sẽ chỉ sử dụng các Bản ghi theo đúng
                      hình thức, phạm vi, thời hạn quy định tại Thỏa thuận này;{' '}
                      <br />
                      <br />‍<br />‍
                      <strong className='bold-text-8'>
                        TUYÊN BỐ TỪ CHỐI TRÁCH NHIỆM
                        <br />‍
                      </strong>
                      EMVN từ chối mọi đảm bảo rõ ràng hoặc ngụ ý, bao gồm nhưng
                      không giới hạn: <br />
                      1. Các bảo đảm, ngụ ý về tính thương mại hoặc sự phù hợp
                      cho một mục đích cụ thể; <br />
                      2. Các bảo đảm về chất lượng hoặc hiệu suất của các tài
                      liệu, thông tin, hàng hóa, dịch vụ, công nghệ và/hoặc nội
                      dung được cung cấp theo hoặc liên quan đến thỏa thuận này,
                      bao gồm nhưng không giới hạn bất kỳ giới hạn nào đối với
                      việc truy cập hoặc sử dụng nội dung của Bên được cấp phép;{' '}
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BỒI THƯỜNG
                        <br />‍
                      </strong>
                      1. Các bên đồng ý bảo vệ, bồi thường và giữ cho nhau vô
                      hại khỏi mọi khiếu nại, trách nhiệm pháp lý, yêu cầu, chi
                      phí, tổn thất, thiệt hại hoặc chi phí khác, bao gồm phí
                      luật sư và chi phí tòa án hợp lý, phát sinh từ bất kỳ vi
                      phạm vật chất hoặc thất bại của bất kỳ điều khoản, điều
                      kiện, đại diện và / hoặc bảo hành được thực hiện ở đây.
                      <br />
                      2. EMVN không chịu trách nhiệm pháp lý cho bất kỳ thiệt
                      hại gián tiếp, ngẫu nhiên, hay bất kỳ khoản phí bồi
                      thường, trừng phạt hay khoản phạt thiệt hại nào phát sinh
                      từ hoặc liên quan đến Thỏa thuận này mà không phải do hành
                      vi cố ý vi phạm của EMVN.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẢO MẬT THÔNG TIN
                        <br />‍
                      </strong>
                      1. Tất cả các thông tin cấp cho Bên được cấp phép thông
                      qua địa chỉ email đã đăng ký, số điện thoại (bao gồm điện
                      thoại di động), RSS hoặc các nguồn cấp thông tin tương tự,
                      hoặc thông qua Thư viện đều được xem là thông tin mật (“
                      <strong>Thông tin mật</strong>”), trừ trường hợp có quy
                      định rõ ràng khác.
                      <br />
                      2. Các bên không được tiết lộ cho bất cứ bên nào khác bất
                      kỳ Thông tin mật nói trên trừ trường hợp được chấp thuận
                      bằng văn bản của Các bên hoặc trường hợp phải tiết lộ để
                      chứng minh phạm vi cấp quyền hoặc theo yêu cầu của cơ quan
                      nhà nước có thẩm quyền. <br />
                      3. Các bên cùng hiểu rằng, việc tiết lộ thông tin mật có
                      thể gây ra những thiệt hại không thể khắc phục đối với mỗi
                      bên. Đối tác thiệt hại có quyền được đòi bồi thường thiệt
                      hại cho mỗi vi phạm đồng thời áp dụng các biện pháp khắc
                      phục khác.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        BẤT KHẢ KHÁNG
                        <br />‍
                      </strong>
                      1. Sự kiện bất khả kháng: Là sự kiện mà Các bên không thể
                      lường trước được cũng như không thể ngăn chặn sự kiện xảy
                      ra hoặc kết quả của sự kiện, bao gồm nhưng không giới hạn
                      ở các sự kiện sau: chính sách pháp luật thay đổi, động
                      đất, sóng thần, lũ lụt, lốc xoáy và các thảm họa tự nhiên
                      khác, bạo động, khủng bố, hỏa hoạn, tình trạng chiến tranh
                      hoặc các hành động quân sự khác, các lỗi kỹ thuật phát
                      sinh do các đợt tấn công mạng.
                      <br />
                      2. Nếu bất cứ Bên nào không thể thực hiện được toàn bộ
                      hoặc một phần Thỏa thuận này do Sự kiện bất khả kháng thì
                      Bên đó phải thông báo cho Bên còn lại bằng văn bản về việc
                      không thực hiện được nghĩa vụ của mình do Sự kiện bất khả
                      kháng trong thời gian 10 (mười) ngày kể từ ngày xảy ra Sự
                      kiện bất khả kháng.
                      <br />
                      3. Quyền và nghĩa vụ của các bên theo Thỏa thuận ngay lập
                      tức khôi phục khi chấm dứt Sự kiện bất khả kháng hoặc khi
                      Sự kiện bất khả kháng đó bị loại bỏ.
                      <br />
                      4. Bên thông báo việc thực hiện Thỏa thuận của họ trở nên
                      không thể thực hiện được do Sự kiện bất khả kháng có trách
                      nhiệm phải thực hiện mọi nỗ lực để giảm thiểu ảnh hưởng
                      của Sự kiện bất khả kháng đó.
                      <br />
                      5. Nếu sự kiện bất khả kháng kéo dài hơn 02 (hai) tháng,
                      hai bên sẽ thỏa thuận về hiệu lực của Thỏa thuận hoặc xem
                      xét việc thực hiện hợp lý những điều khoản chịu ảnh hưởng
                      của Sự kiện bất khả kháng. Điều này không loại trừ khả
                      năng chấm dứt Thỏa thuận của bên có quyền bị ảnh hưởng bởi
                      Sự kiện Bất khả kháng bằng một thông báo văn bản cho bên
                      còn lại.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        ĐIỀU KHOẢN SỬA ĐỔI
                        <br />‍
                      </strong>
                      1. EMVN toàn quyền chủ động quyết định về các thay đổi
                      liên quan đến thỏa thuận này. Bên được cấp phép sẽ nhận
                      được thông báo về việc thay đổi thông qua email đăng ký
                      Tài khoản tại Thư viện âm nhạc EMVN. <br />
                      2. Tại thời điểm nhận được thông báo, Bên được cấp phép có
                      quyền quyết định tiếp tục/chấm dứt thỏa thuận này. Việc
                      Bên được cấp phép tiếp tục sử dụng các Bản ghi theo thỏa
                      thuận sau khi thay đổi phát sinh sẽ là căn cứ thể hiện
                      rằng Bên được cấp phép chấp nhận việc thay đổi này.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        HIỆU LỰC TỪNG PHẦN
                        <br />‍
                      </strong>
                      Nếu bất kỳ điều khoản nào tại đây mâu thuẫn với luật áp
                      dụng hoặc bị toà án có thẩm quyền tuyên bố là trái pháp
                      luật, điều khoản đó sẽ được xem xét điều chỉnh tới mức có
                      thể thực hiện được.. Trong trường hợp cần thiết, điều
                      khoản không hợp lệ hoặc không thể thực thi nói trên sẽ
                      được thay thế bằng điều khoản hợp lệ hoặc có thể thi hành
                      hoặc loại bỏ khỏi Thỏa thuận. Việc điều chỉnh thay thế,
                      loại bỏ không ảnh hưởng tới các Điều khoản khác, Thỏa
                      thuận vẫn duy trì hiệu lực miễn là mục đích thiết yếu của
                      nó vẫn được đảm bảo.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        LUẬT ÁP DỤNG
                        <br />‍
                      </strong>
                      Thỏa thuận này được điều chỉnh và giải nghĩa theo pháp
                      luật Việt Nam. Theo đó, các giao dịch khác liên quan tới
                      Thỏa thuận này đều chịu sự điều chỉnh của pháp luật Việt
                      Nam.
                      <br />
                      <br />
                      <br />‍
                      <strong className='bold-text-8'>
                        GIẢI QUYẾT TRANH CHẤP
                        <br />‍
                      </strong>
                      Tất cả các tranh chấp liên quan đến Thỏa thuận này sẽ được
                      giải quyết thông qua thương lượng, hòa giải. Trong trường
                      hợp Các Bên không đạt được thỏa thuận chung thì tranh chấp
                      sẽ được đưa ra giải quyết tại Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam (VIAC) theo Quy tắc tố tụng trọng tài của Trung
                      tâm này. Cụ thể như sau: (i) Trung Tâm Trọng Tài Quốc Tế
                      Việt Nam bên cạnh Phòng Thương Mại Và Công Nghiệp Việt Nam
                      tại Hà Nội, Việt Nam . (ii) Hội đồng trọng tài gồm 03 (ba)
                      trọng tài viên do VIAC chỉ định (iii) Tố tụng trọng tài
                      bằng tiếng việt và tiến hành theo thủ tục rút gọn Điều 37
                      Quy tắc tố tụng trọng tài của VIAC. <br />‍<br />
                      <strong>
                        Thỏa thuận này được cập nhật lần cuối vào ngày
                        07/07/2020.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
