import React, { Component } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Select,
  Radio,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from 'antd';
import { History } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import './index.scss';
import 'antd/dist/antd.css';

// COMPONENTS
import ReactLogo from '../../assets/images/shield2.png';

// API
import { API_URL, API_ROUTE, headerConfiguration } from '../../constant';

// DATA
import { Plan, PaymentPeriod, DefaultPaymentPeriod, getPlanPrice } from '../../model/data';

// HELPER
import {
  numberWithCommas,
  getLocalStorageKey,
  removeLocalStorageToken,
} from '../../helpers';

// MODEL
import { Service, Payment } from '../../model/payment';
import { User } from '../../model/user';

interface TProps {
  history: History;
  user: User;
}

interface TState {
  paymentPeriod: number;
  addSfx: boolean;
  paymentMethod: number;
  selectedPlan: Plan | undefined;
  price: number;
  promotion: string;
  company: string;
  vat: string;
  billing_add: string;
  additional: string;
  showAddInvoice: boolean;
}

const { Option } = Select;

export default class Pricing extends Component<TProps, TState> {
  formInvoiceRef = React.createRef<any>();

  constructor(props: TProps) {
    super(props);
    this.state = {
      paymentPeriod: DefaultPaymentPeriod,
      addSfx: false,
      paymentMethod: 0,
      selectedPlan: undefined,
      price: 0,
      promotion: '',
      company: '',
      vat: '',
      billing_add: '',
      additional: '',
      showAddInvoice: false,
    };
  }

  componentDidMount() {
    // currently disable the payment page altogether
    return this.props.history.push('/pricing');
    // const detailPlan = JSON.parse(getLocalStorageKey('detailPlan') as string)
    // if (!detailPlan ||
    //   !detailPlan.plan ||
    //   !detailPlan.paymentPeriod ||
    //   !detailPlan.price)
    //   return this.props.history.push('/pricing');

    // return this.setState({
    //   paymentPeriod: detailPlan.paymentPeriod,
    //   addSfx: detailPlan.addSfx,
    //   selectedPlan: detailPlan.plan,
    //   price: detailPlan.price,
    // });
  }

  getPrice = () => {
    const { paymentPeriod, selectedPlan, addSfx } = this.state;
    if (!selectedPlan)
      return 0;
    return getPlanPrice(paymentPeriod, selectedPlan, addSfx)
  };

  generatePrice = () => {
    const newPrice = this.getPrice();

    this.setState({ price: newPrice });
  };

  handlerSubscribe = async () => {
    if (this.formInvoiceRef.current) {
      const formInvoice = this.formInvoiceRef.current.getFieldsValue();

      if (formInvoice) {
        console.log(formInvoice);
      }
    }

    const channel = getLocalStorageKey('channel');

    const { selectedPlan, price } = this.state;

    const order_id = v4();

    var paymentPeriodObj = PaymentPeriod.filter((item) => {
      return item.id === this.state.paymentPeriod;
    })
    const paymentRequest: Payment = {
      amount: price,
      descrition: `Payment for ${selectedPlan!.title}`,
      order_id,
      service: Service.LicenseWeb,
      metadata: {
        plan: {
          id: String(selectedPlan!.id),
          title: selectedPlan!.title,
          modelTitle: selectedPlan!.modelTitle
        },
        period: {
          id: String(paymentPeriodObj[0].id),
          value: String(paymentPeriodObj[0].value)
        },
        addSfx: this.state.addSfx,
        channel: channel
      }
    };

    try {
      const response = await axios.post(
        `${API_URL}${API_ROUTE.PAYMENT}`,
        { ...paymentRequest, userId: this.props.user.id },
        {
          headers: headerConfiguration,
        },
      );

      const url = response.data.url;

      removeLocalStorageToken('detailPlan');
      removeLocalStorageToken('channel');

      notification.success({
        message: 'EMVN Success',
        description: 'You are being redirected to payment verification',
        top: 100,
        onClose: () => window.open(url, '_self'),
      });
    } catch (error) {
      notification.error({
        message: 'EMVN Error',
        description:
          'There is an error, Please contact EMVN team for assistance.',
        top: 100,
      });
    }
  };

  handleChangeShowAddInvoice = () => {
    this.setState((v) => {
      return {
        showAddInvoice: !v.showAddInvoice,
      };
    });
  };

  render() {
    const {
      paymentPeriod,
      addSfx,
      paymentMethod,
      selectedPlan,
      price,
      promotion,
      company,
      vat,
      billing_add,
      additional,
      showAddInvoice,
    } = this.state;
    const { history } = this.props;

    return (
      <div className='pay-container'>
        <div id='paybg' />

        <div className='custom-container'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[32, 8]} className='pay-main-wrapper'>
              <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                <Row className='pay-title-wrapper' style={{ height: 50 }}>
                  <Col span={24} className='pay-main-title'>
                    Your Plan
                  </Col>
                </Row>

                <div className='pay-main-wrapper-item'>
                  <div className='item-wrapper'>
                    <span
                      className='pay-wrapper-title'
                      style={{ fontWeight: 'bold' }}
                    >
                      {selectedPlan?.modelTitle}
                    </span>
                    <span
                      className='pay-wrapper-title change-plan-btn'
                      style={{ textDecoration: 'underline', color: '#b99876' }}
                      onClick={() => {
                        removeLocalStorageToken('detailPlan');
                        history.push('/pricing');
                      }}
                    >
                      Change Plan
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <span
                      className='pay-wrapper-title'
                      style={{ fontSize: 21 }}
                    >
                      {numberWithCommas(price)} VND
                    </span>
                    <span>
                      <Select
                        style={{ width: '100%' }}
                        value={paymentPeriod}
                        onChange={(v) =>
                          this.setState({ paymentPeriod: v }, () => {
                            this.generatePrice();
                          })
                        }
                      >
                        {PaymentPeriod.map((v) => (
                          <Option key={v.id} value={v.id}>
                            {v.name}
                          </Option>
                        ))}
                      </Select>
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <span className='pay-wrapper-title'>
                      Add SFXs to your subscription plan?
                    </span>
                    <span>
                      <Select
                        style={{ width: '100%' }}
                        value={addSfx ? 'true' : 'false'}
                        onChange={(v) =>
                          this.setState({ addSfx: v === 'true' }, () => {
                            this.generatePrice();
                          })
                        }
                      >
                        <Option value='true'>Yes</Option>
                        <Option value='false'>No</Option>
                      </Select>
                    </span>
                  </div>
                  <div className='item-wrapper' style={{ marginTop: 0 }}>
                    <span
                      className='pay-wrapper-title'
                      style={{
                        fontStyle: 'italic',
                        color: '#B99876',
                        marginTop: -10,
                      }}
                    >
                      +25% for unlimited use of 300K SFXs library
                    </span>
                  </div>
                </div>
                <div
                  className='pay-main-wrapper-item'
                  style={{ marginTop: 30 }}
                >
                  <div className='item-wrapper'>
                    <span
                      className='pay-wrapper-title'
                      style={{ fontWeight: 'bold' }}
                    >
                      Payment Method
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <Radio.Group
                      onChange={(v) =>
                        this.setState({ paymentMethod: v.target.value })
                      }
                      style={{ width: '100%' }}
                      value={paymentMethod}
                    >
                      <Radio
                        className='payment-method-title'
                        value={0}
                        style={{ marginTop: 0 }}
                      >
                        Momo
                      </Radio>

                      {paymentMethod === 1 ? (
                        <div className='bank-information-wrapper'>
                          <Radio className='payment-method-title' value={1}>
                            Pay by bank transfer
                          </Radio>

                          <p className='bank-info-title'>
                            Transfer the total value of the order via account
                          </p>
                          <p className='bank-info-title yellow'>
                            Account holder:
                          </p>
                          <p className='bank-info-title'>
                            EpicMusicVN Multimedia Entertainment Company Limited
                          </p>
                          <p className='bank-info-title yellow'>
                            Account number:
                          </p>
                          <p className='bank-info-title'>0491000074755</p>
                          <p className='bank-info-title yellow'>Bank:</p>
                          <p className='bank-info-title'>
                            Joint Stock Commercial Bank for Foreign Trade of
                            Vietnam (Vietcombank)
                          </p>
                          <p className='bank-info-title yellow'>
                            Transfer content:{' '}
                            <span style={{ color: 'white' }}>
                              Name + Phone number
                            </span>
                          </p>
                          <p className='bank-info-title'>
                            Note : With bank transfer method, please pay within
                            24 hours from the time of order. After that time,
                            EMVN will cancel the order.
                          </p>
                        </div>
                      ) : (
                        <Radio className='payment-method-title' value={1}>
                          Pay by bank transfer
                        </Radio>
                      )}
                    </Radio.Group>
                  </div>
                </div>

                {paymentMethod !== 1 && (
                  <>
                    <div
                      className='pay-main-wrapper-item'
                      style={{ marginTop: 30 }}
                    >
                      <div className='item-wrapper'>
                        <span
                          className='pay-wrapper-title'
                          style={{ fontWeight: 'bold' }}
                        >
                          Add invoice information
                        </span>
                        {showAddInvoice ? (
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            size='1x'
                            color='#b99876'
                            className='iconDown'
                            onClick={this.handleChangeShowAddInvoice}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            size='1x'
                            color='#b99876'
                            className='iconDown'
                            onClick={this.handleChangeShowAddInvoice}
                          />
                        )}
                      </div>

                      {showAddInvoice ? (
                        <Form
                          style={{ marginTop: 20 }}
                          name='nest-messages'
                          ref={this.formInvoiceRef}
                        >
                          <Form.Item
                            name={['company']}
                            style={{ marginBottom: 20 }}
                          >
                            <Input
                              placeholder='Company (Optional)'
                              style={{
                                width: '100%',
                                height: 40,
                                backgroundColor: 'transparent',
                                color: '#fff',
                              }}
                              value={company}
                              onChange={(v) =>
                                this.setState({ company: v.target.value })
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={['vat']}
                            style={{ marginBottom: 20 }}
                          >
                            <Input
                              type='number'
                              placeholder='VAT number (Optional)'
                              style={{
                                width: '100%',
                                height: 40,
                                backgroundColor: 'transparent',
                                color: '#fff',
                              }}
                              value={vat}
                              onChange={(v) =>
                                this.setState({ vat: v.target.value })
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={['billing_add']}
                            style={{ marginBottom: 20 }}
                          >
                            <Input
                              placeholder='Billing address (Optional)'
                              style={{
                                width: '100%',
                                height: 40,
                                backgroundColor: 'transparent',
                                color: '#fff',
                              }}
                              value={billing_add}
                              onChange={(v) =>
                                this.setState({ billing_add: v.target.value })
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={['additional']}
                            style={{ marginBottom: 0 }}
                          >
                            <Input
                              placeholder='Additional information (Optional)'
                              style={{
                                width: '100%',
                                height: 40,
                                backgroundColor: 'transparent',
                                color: '#fff',
                              }}
                              value={additional}
                              onChange={(v) =>
                                this.setState({ additional: v.target.value })
                              }
                            />
                          </Form.Item>
                        </Form>
                      ) : null}
                    </div>
                    <div
                      className='pay-main-wrapper-item'
                      style={{ marginTop: 20 }}
                    >
                      <Form
                        style={{ marginTop: 30 }}
                        name='nest-messages'
                        onFinish={() => console.log('check Promocode')}
                      >
                        <Row gutter={[32, 8]}>
                          <Col span={18}>
                            <Form.Item
                              name={['promotion_code']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Promotion code is required',
                                },
                              ]}
                              style={{ marginBottom: 10 }}
                            >
                              <Input
                                placeholder='Promotion code'
                                style={{
                                  width: '100%',
                                  height: 40,
                                  backgroundColor: 'unset',
                                  borderColor: '#828282',
                                  color: '#fff',
                                }}
                                value={promotion}
                                onChange={(v) =>
                                  this.setState({ promotion: v.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item>
                              <Button
                                htmlType='submit'
                                className='pay-subscription-submit'
                              >
                                ENTER
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <Form name='payment' onFinish={this.handlerSubscribe}>
                      <Form.Item
                        name='agreement'
                        valuePropName='checked'
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject('Should accept agreement'),
                          },
                        ]}
                      >
                        <Checkbox
                          style={{
                            color: '#fff',
                            fontFamily: 'Montserrat',
                            fontSize: 14,
                            fontWeight: 500,
                            fontStyle: 'normal',
                          }}
                        >
                          I have read and agreed to the{' '}
                          <span style={{ color: ' #b99876', marginRight: 5 }}>
                            Terms of Use
                          </span>
                          and{' '}
                          <span style={{ color: ' #b99876', marginRight: 5 }}>
                            Privacy
                          </span>
                          Policy.
                        </Checkbox>
                      </Form.Item>
                      {/* <Form.Item
                        name='agreement1'
                        valuePropName='checked'
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject('Should accept agreement'),
                          },
                        ]}
                      >
                        <Checkbox
                          style={{
                            color: '#fff',
                            fontFamily: 'Montserrat',
                            fontSize: 14,
                            fontWeight: 500,
                            fontStyle: 'normal',
                          }}
                        >
                          I have read and acknowledge the Annual Subscription
                          Auto-Renewal Policy and Cancellation Policy
                          <span style={{ color: ' #b99876' }}>
                            {' '}
                            Subscription Terms
                          </span>
                        </Checkbox>
                      </Form.Item> */}
                      <Form.Item>
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            className='submit-payment'
                            style={{ borderColor: 'unset' }}
                            htmlType='submit'
                          >
                            <span>
                              <img
                                src={ReactLogo}
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: 10,
                                }}
                                alt=''
                              />
                              PAY NOW
                            </span>
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </>
                )}
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
                <Row className='pay-title-wrapper' style={{ height: 50 }} />
                <div className='pay-main-wrapper-item'>
                  {selectedPlan &&
                    selectedPlan.rules.map((v, i) => (
                      <div className='select-plan-detail' key={i}>
                        <FontAwesomeIcon
                          size='1x'
                          icon={faCheck}
                          style={{ marginRight: 15, color: '#b99876' }}
                        />
                        <p>{v}</p>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
