import React, { useEffect, useState } from 'react';
import { History } from 'history';
import axios from 'axios';
import ReactLoading from 'react-loading';

// COMPONENT
import { Button } from '../../components/button';

// HELPER
import { API_URL, API_ROUTE } from '../../constant';
import { getLocalStorageKey } from '../../helpers';

// MODEL
import { User } from '../../model/user';

import './index.scss';

import SuccessfullLogo from '../../assets/images/successful-logo.png';

interface TProps {
  history: History;
  user: User;
}

const Loading = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactLoading type='cylon' color='#fff' height={'10%'} width={'10%'} />
    </div>
  );
};

export const Redirect = ({ history, user }: TProps) => {
  const [localState, setLocalState] = useState({
    isLoading: true,
    isSuccess: false,
    isSystemError: false,
  });

  useEffect(() => {
    const urlSearch = new URL(window.location.href).searchParams;

    const order_id = urlSearch.get('order_id');

    if (!order_id || (order_id && order_id.length === 0)) {
      return setLocalState({
        isSystemError: false,
        isLoading: false,
        isSuccess: false,
      });
    }

    const accessToken = getLocalStorageKey('accessToken');
    const userId = getLocalStorageKey('userId');

    if (user) {
      axios
        .post(
          `${API_URL}${API_ROUTE.UPGRADE_USER_PLAN}/${userId}`,
          {
            order_id: order_id,
            source_audio_id: user.srcAudioMeta?.srcAudioId,
            email: user.email,
            name: user.displayName,
          },
          {
            headers: {
              authorization: accessToken,
            },
          },
        )
        .then((v) => {
          if (v.data.status === 'failed') {
            return setLocalState({
              isSystemError: false,
              isLoading: false,
              isSuccess: false,
            });
          }

          return setLocalState({
            isSystemError: false,
            isLoading: false,
            isSuccess: true,
          });
        })
        .catch((v) => {
          return setLocalState({
            isSystemError: true,
            isLoading: false,
            isSuccess: false,
          });
        });
    }
  }, [user]);

  if (localState.isLoading) {
    return (
      <div style={{ height: '100%' }}>
        <Loading />
      </div>
    );
  }

  if (
    !localState.isLoading &&
    !localState.isSuccess &&
    !localState.isSystemError
  ) {
    const urlSearch = new URL(window.location.href).searchParams;

    const order_id = urlSearch.get('order_id');

    return (
      <div className='redirect-payment'>
        {/* <div className='success-logo'>
          <img src={SuccessfullLogo} alt='logo' />
        </div> */}
        <div className='text-wrapper'>
          <p className='success-text'>
            {!order_id || (order_id && order_id.length === 0)
              ? 'Order is not valud'
              : 'Payment is not success. Please contact EMVN team for more details.'}
          </p>
        </div>
        <Button
          className='custom-home-page-btn'
          onClick={() => history.push('/')}
          title='Home'
        />
      </div>
    );
  }

  if (
    !localState.isLoading &&
    !localState.isSuccess &&
    localState.isSystemError
  ) {
    return (
      <div className='redirect-payment'>
        {/* <div className='success-logo'>
          <img src={SuccessfullLogo} alt='logo' />
        </div> */}
        <div className='text-wrapper'>
          <p className='success-text'>
            There is an error, Please contact EMVN team for assistance.
          </p>
        </div>
        <Button
          className='custom-home-page-btn'
          onClick={() => history.push('/')}
          title='Home'
        />
      </div>
    );
  }

  return (
    <div className='redirect-payment'>
      <div className='success-logo'>
        <img src={SuccessfullLogo} alt='logo' />
      </div>
      <div className='text-wrapper'>
        <p className='success-text'>Payment Successful</p>
      </div>
      <Button
        className='custom-home-page-btn'
        onClick={() => history.push('/')}
        title='Home'
      />
    </div>
  );
};
