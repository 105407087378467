/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';
import './index.scss';

export const AffiliateProgram = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/header_aff.jpeg'
        mainTitle='CHƯƠNG TRÌNH'
        subTitle='ĐỐI TÁC'
      />
      <div id='Intro' className='content-section-rich-text-mobile top-padding'>
        <p className='paragraph-10-ap'>
          Với nhu cầu mở rộng thị trường, Công ty TNHH EpicMusicVN (“EMVN”) mang
          đến cơ hội hợp tác với chính sách hỗ trợ hấp dẫn dành cho đối tác kinh
          doanh (“Đối tác”).
          <br />
          <br />‍
          <strong className='bold-text-8'>
            ĐỐI TƯỢNG ÁP DỤNG
            <br />‍
          </strong>
          Áp dụng cho các cá nhân/tổ chức đăng ký trở thành Đối tác để giới
          thiệu các sản phẩm, dịch vụ của EMVN, bao gồm nhưng không giới hạn
          người sáng lập các trang/nhóm/diễn đàn, cá nhân (KOL, Influencer) có
          sức ảnh hưởng trong các lĩnh vực có liên quan đến bản quyền âm nhạc,
          từng sử dụng/trải nghiệm dịch vụ của EMVN là lợi thế (không bắt buộc).
          <br />
          <br />
          <br />‍
          <strong className='bold-text-8'>
            CHÍNH SÁCH HỢP TÁC
            <br />-{' '}
          </strong>
          EMVN cấp miễn phí tài khoản Professional Creator cho Đối tác trong
          suốt thời hạn hợp tác để trải nghiệm sản phẩm và phục vụ công việc của
          một Đối tác.
          <br />- Đối tác sẽ phối hợp cùng EMVN tạo và đăng các bài viết giới
          thiệu về sản phẩm/dịch vụ của EMVN nhằm mục đích quảng bá và bán sản
          phẩm trên nhóm và/hoặc trang cá nhân và/hoặc kênh YouTube của mình
          (“Kênh”), bao gồm nhưng không giới hạn bài viết, hình ảnh, video giới
          thiệu, review sản phẩm, tối thiểu một tuần một lần.
          <br />- Đối tác được cung cấp affiliate link riêng và mã giảm giá
          (theo chiến lược marketing tại từng thời điểm) để theo dõi và thống kê
          tương tác và số lượng đơn hàng thành công.
          <br />- Đối tác sẽ nhận được khoản hoa hồng cho các đơn hàng thành
          công đến từ Kênh của Đối tác bằng:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ 10% tổng giá trị sau chiết khấu và sau
          VAT của đơn hàng
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Tối đa $50/đơn hàng
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Chỉ áp dụng cho lần đầu tiên đăng ký,
          không áp dụng cho các gói gia hạn tiếp theo &nbsp; &nbsp; &nbsp;{' '}
          <br />
          <br />
          Ví dụ chi tiết như bảng dưới:
          <br />‍<br />
        </p>
        <div className='flex'>
          <div className='div-block-32 ap'>
            <div className='w-layout-grid grid-6'>
              <div className='text-block-15-copy-copy black'>Gói</div>
              <div className='text-block-15 yellowh'>
                Personal Creator$13/tháng
                <br />
              </div>
              <div className='text-block-15 greenh'>
                Professional Creator $39/tháng
                <br />
              </div>
              <div className='text-block-15 redh'>
                Industrial Producer$149/tháng
                <br />
              </div>
              <div className='text-block-15-copy'>
                Giá gói 1 năm (đã bao gồm VAT)
              </div>
              <div className='text-block-15 yellow'>$156</div>
              <div className='text-block-15 green'>$486</div>
              <div className='text-block-15 red'>$1788</div>
              <div className='text-block-15-copy'>Chiết khấu</div>
              <div className='text-block-15 yellow'>10%</div>
              <div className='text-block-15 green'>10%</div>
              <div className='text-block-15 red'>10%</div>
              <div className='text-block-15-copy'>
                Giá gói 1 năm sau chiết khấu
              </div>
              <div className='text-block-15 yellow'>$140.4</div>
              <div className='text-block-15 green'>$421.2</div>
              <div className='text-block-15 red'>$1609.2</div>
              <div className='text-block-15-copy'>VAT </div>
              <div className='text-block-15 yellow'>10%</div>
              <div
                id='w-node-f0483b44c63e-ea0c1df7'
                className='text-block-15 green'
              >
                10%
              </div>
              <div className='text-block-15 red'>10%</div>
              <div className='text-block-15-copy'>
                {' '}
                Gia trị đơn hàng sau VAT
              </div>
              <div className='text-block-15 yellow'>$126.36</div>
              <div className='text-block-15 green'>$379.08</div>
              <div
                id='w-node-e1934612aa47-ea0c1df7'
                className='text-block-15 red'
              >
                $1448.28
              </div>
              <div className='text-block-15-copy'>
                Hoa hồng Đối tác nhận (10%)
              </div>
              <div
                id='w-node-9e2306ccb3b5-ea0c1df7'
                className='text-block-15 yellow'
              >
                $12.63
              </div>
              <div className='text-block-15 green'>$37.09</div>
              <div className='text-block-15 red'>$50</div>
            </div>
          </div>
        </div>
        <div className='container-2 w-container'>
          <div className='columns-3 w-row'>
            <div className='column-7 w-col w-col-4'>
              <div className='w-row'>
                <div className='column-6 w-col w-col-2'>
                  <div className='box-yellow' />
                </div>
                <div className='w-col w-col-10'>
                  <div className='text-block-18'>Personal Creator$13/tháng</div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div className='w-row'>
                <div className='column-6 w-col w-col-2'>
                  <div className='box-green' />
                </div>
                <div className='w-col w-col-10'>
                  <div className='text-block-18'>
                    Professional Creator $39/tháng
                  </div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div className='w-row'>
                <div className='column-6 w-col w-col-2'>
                  <div className='box-red' />
                </div>
                <div className='w-col w-col-10'>
                  <div className='text-block-18'>
                    Industrial Producer$149/tháng
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='paragraph-10-ap-2'>
          <span>
            <strong className='bold-text-8'>
              ĐỐI SOÁT VÀ THANH TOÁN DOANH THU HOA HỒNG
              <br />
            </strong>
            - <strong>Chu kỳ đối soát:</strong> Tuần đầu tiên mỗi tháng.
            <br />- <strong>Phương thức đối soát:</strong> Công ty sẽ gửi thông
            tin đối soát cho Đối tác thông qua hệ thống CMS và Email với thống
            kê rõ ràng, minh bạch và công khai. Sau đó, EMVN sẽ thanh toán hoa
            hồng cho những hợp đồng hiện tại. Đối tác sẽ đối chiếu và kiểm tra
            lại thông tin. Trong thời gian 1-2 ngày, Đối tác trả lời email nếu
            có thắc mắc với biên bản đối soát và đưa rõ lý do cụ thể.
            <br />- <strong>Giá trị thanh toán:</strong> mỗi lần không dưới
            500.000 (vnđ)/ lần thanh toán. Nếu giá trị thấp hơn sẽ được cộng dồn
            cho lần đối soát tiếp theo.
            <br />- <strong>Phương thức thanh toán:</strong> chuyển khoản.
            <br />- <strong>Đồng tiền thanh toán:</strong> Việt Nam đồng.
            <br />
            <br />
            <br />‍
            <strong className='bold-text-8'>
              QUYỀN LỢI KHÁC CỦA ĐỐI TÁC
              <br />-{' '}
            </strong>
            Được sử dụng hồ sơ công ty cùng các giấy tờ liên quan để thực hiện
            việc quảng bá, tiếp thị tới thị trường và khách hàng tiềm năng.
            <br />- Được hỗ trợ kiến thức về bản quyền âm nhạc và dịch vụ của
            công ty.
            <br />- Được thông tin về giá cả, sản phẩm, chính sách, các chương
            trình hỗ trợ có liên quan.
            <br />- Được hỗ trợ tư vấn, đàm phán khi có nhu cầu. Đối với các yêu
            cầu từ phía khách hàng vượt tầm xử lý của Đối tác, EMVN sẽ hỗ trợ
            đảm nhận và xử lý.
            <br />
            <br />
            <br />‍
            <strong className='bold-text-8'>
              NGHĨA VỤ CỦA &nbsp;ĐỐI TÁC
              <br />-{' '}
            </strong>
            Chủ động quảng bá thương hiệu, tiếp thị dịch vụ, tìm kiếm và khai
            thác nguồn khách hàng tiềm năng.
            <br />- Sử dụng đúng những thông tin mà công ty đã cung cấp để tư
            vấn cho khách hàng. Không được tư vấn sai với chủ trương công ty đã
            ban hành, hay hứa hẹn bất kì điều gì nằm ngoài khả năng hoặc không
            có thực liên quan đến sản phẩm và dịch vụ do công ty cung cấp.
            <br />- Thu thập thông tin góp ý của khách hàng, phản hồi với Công
            ty.
            <br />- Nghiêm cấm Đối tác lợi dụng danh nghĩa của Công ty để thực
            hiện những hành vi vi phạm pháp luật hoặc những hành vi có thể làm
            ảnh hưởng xấu đến uy tín và thương hiệu của Công ty.
            <br />- Không được nhận bất kỳ khoản tiền nào của Khách hàng trong
            quá trình làm Đối tác.
            <br />- Trong thời gian làm Đối tác của EMVN, Đối tác không được
            giới thiệu khách hàng mới hoặc lôi kéo khách hàng hiện hữu của công
            ty cho một công ty cùng ngành khác.
            <br />
            <br />
            <br />‍
            <strong className='bold-text-8'>
              QUY ĐỊNH DỪNG HỢP TÁC &nbsp;ĐỐI TÁC TRONG TRƯỜNG HỢP
              <br />-{' '}
            </strong>
            Khi Đối tác vi phạm các điều khoản của chính sách này gây ảnh hưởng
            tới quyền lợi của khách hàng, quyền lợi và hình ảnh của Công ty hoặc
            cố tình vi phạm nhiều lần, không hợp tác với Công ty mặc dù Công ty
            đã nhắc nhở nhưng không rút kinh nghiệm và khắc phục, sửa sai.
            <br />- Khi Đối tác không có doanh thu trong 06 tháng liên tiếp.
            <br />- Khi Đối tác chủ động đề nghị ngừng tham gia chương trình Đối
            tác của EMVN.
            <br />- Khi EMVN quyết định ngừng toàn bộ chương trình Đối tác.
            <br />
            <br />‍
            <em>
              EMVN có trách nhiệm thanh toán toàn bộ dư nợ còn lại chưa được
              thanh toán cho Đối tác. Mọi quyền lợi khác của Đối tác sẽ được
              EMVN thu hồi.
              <br />
              <br />
              <br />‍
            </em>
            <strong className='bold-text-8'>
              CÁC CHÍNH SÁCH LIÊN QUAN
              <br />-{' '}
            </strong>
            Chính sách bảo mật thông tin
            <br />- Chính sách tư vấn &amp; hỗ trợ
            <br />- Chính sách ký kết hợp đồng
            <br />‍<br />
            Để biết thêm chi tiết, vui lòng liên hệ email{' '}
          </span>
          <a href='#'>
            <span>license@emvn.co</span>
          </a>
          <span>
            .<br />
            <strong>
              <br />
              TRÂN TRỌNG HỢP TÁC.
            </strong>
          </span>
        </p>
      </div>
      ;
      <Footer />
    </>
  );
};
