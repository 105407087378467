import React, { Component } from 'react';
import { History } from 'history';
import axios from 'axios';
import { notification } from 'antd';

// COMPONENT
import { Form, Input, Button } from 'antd';

// HELPER
import { getLocalStorageKey, } from '../../../../helpers';

// STYLE
import './index.scss';

// API
import { API_ROUTE, API_URL } from '../../../../constant';

// MODEL
import { User } from '../../../../model/user';

interface TProps {
  user?: User;
  history: History;
  changeLoadingStatus: (
    status: boolean,
    callback: (data?: any) => void,
  ) => void;
}

interface TState {
  user?: User;
}

export default class Profile extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      user: undefined,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.invoice) {
      this.setState({ user: this.props.user });
    } else {
      this.setState({ user: this.props.user });
    }
  }

  componentWillReceiveProps(nextProps: TProps) {
    if (nextProps.user && nextProps.user.invoice) {
      this.setState({ user: nextProps.user });
    } else {
      this.setState({ user: nextProps.user });
    }
  }

  handleCallApi = (v: {
    youtubeURL: string;
    emvnLink: string;
    note: string;
  }) => {
    this.props.changeLoadingStatus(true, async () => {
      const accessToken = getLocalStorageKey('accessToken');

      try {
        await axios.post<
          { youtubeURL: string; emvnLink: string; name: string; email: string },
          any
        >(
          `${API_URL}${API_ROUTE.CLEAR_VIDEO}`,
          {
            youtubeURL: v.youtubeURL,
            emvnLink: v.emvnLink,
            name: `${this.props.user!.firstName} ${this.props.user!.lastName}`,
            email: this.props.user!.email,
          },
          {
            headers: {
              authorization: accessToken,
            },
          },
        );

        this.props.changeLoadingStatus(false, async () => {
          notification.success({
            message: 'EMVN Success',
            description:
              'You have requested successfully, We will keep you posted.',
            top: 100,
          });
        });
      } catch (error) {
        this.props.changeLoadingStatus(false, async () => {
          notification.error({
            message: 'EMVN Error',
            description:
              'There is an error, Please contact EMVN team for assistance.',
            top: 100,
          });
        });
      }
    });
  };

  render() {
    const { user } = this.props;

    if (!user) return null;

    if (
      !user.srcAudioMeta ||
      (user.srcAudioMeta && user.srcAudioMeta.data.length === 0)
    ) {
      return (
        <div className='clear-video-profile-information-wrapper'>
          <Form
            style={{ marginTop: 0 }}
            name='nest-messages'
            onFinish={this.handleCallApi}
            initialValues={{
              youtubeURL: '',
              emvnLink: '',
              note: '',
            }}
          >
            <div className='clear-video-personal-information'>
              <p className='profile-information-title'>
                Clear video from copyright claims
              </p>
            </div>

            <p className='clear-video-plan-text'>
              Add URLs of your published video and we’ll clear them for you.
              Once cleared they are cleared forever. To be able to use this
              feature you need to have an active subscription
            </p>
          </Form>
          <hr />
        </div>
      );
    }

    return (
      <div className='clear-video-profile-information-wrapper'>
        <Form
          style={{ marginTop: 0 }}
          name='nest-messages'
          onFinish={this.handleCallApi}
          initialValues={{
            youtubeURL: '',
            emvnLink: '',
            note: '',
          }}
        >
          <div className='clear-video-personal-information'>
            <p className='profile-information-title'>
              Clear video from copyright claims
            </p>
          </div>

          <p className='clear-video-plan-text'>
            Add URLs of your published video and we’ll clear them for you. Once
            cleared they are cleared forever.
          </p>

          <Form.Item
            name={['youtubeURL']}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Clear Youtube URL is required',
              },
            ]}
            style={{ marginBottom: 20, backgroundColor: 'transparent' }}
          >
            <Input
              placeholder='Clear youtube URL'
              className='profile-information-input'
              style={{ backgroundColor: 'transparent' }}
            />
          </Form.Item>

          <div
            className='clear-video-personal-information'
            style={{ marginBottom: 10 }}
          >
            <p className='profile-information-title'>EMVN Music URL</p>
          </div>

          <Form.Item
            name={['emvnLink']}
            rules={[
              {
                required: true,
                type: 'string',
                message: 'EMVN URL is required',
              },
            ]}
            style={{ marginBottom: 20, backgroundColor: 'transparent' }}
          >
            <Input
              placeholder='EMVN Music URL'
              className='profile-information-input'
              style={{ backgroundColor: 'transparent' }}
            />
          </Form.Item>

          <div
            className='clear-video-personal-information'
            style={{ marginBottom: 10 }}
          >
            <p className='profile-information-title'>Note</p>
          </div>

          <Form.Item
            name={['note']}
            rules={[
              {
                required: false,
                type: 'string',
              },
            ]}
            style={{ marginBottom: 20 }}
          >
            <Input placeholder='Note' className='profile-information-input' />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: 'end' }}>
              <Button
                className='submit-profile-information '
                style={{ borderColor: 'unset' }}
                htmlType='submit'
              >
                <span>CLEAR VIDEO</span>
              </Button>
            </div>
          </Form.Item>
        </Form>
        <hr />
      </div>
    );
  }
}
