/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';
import './index.scss';

export const TermsCondition = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/policy.jpeg'
        mainTitle='ĐIỀU KHOẢN &'
        subTitle='ĐIỀU KIỆN'
      />
      <div id='Intro' className='content-section-rich-text top-padding'>
        <p className='paragraph-10'>
          Tại EMVN việc bảo vệ dữ liệu cá nhân của Người dùng là ưu tiên hàng
          đầu. Chúng tôi hiểu rằng, &nbsp;các dữ liệu của Người dùng là thuộc
          quyền sở hữu hợp pháp và được pháp luật bảo vệ, chính vì vậy, việc thu
          thập, lưu giữ, xử lý Dữ liệu cá nhân chỉ được EMVN thực hiện khi có sự
          đồng ý và cho phép của Người dùng.
          <br />
          Để truy cập và sử dụng Trang Web, Dịch vụ ngoài việc đồng ý về điều
          khoản sử dụng thì người dùng còn phải đọc kỹ những chính sách bảo mật
          của EMVN sau đây. Việc đăng ký sử dụng Trang Web, Dịch vụ của EMVN
          cũng có nghĩa rằng Người dùng đã đồng ý và chấp thuận ràng buộc bởi
          các điều khoản của bản Chính sách bảo mật này.
          <br />
          Chính sách bảo mật giải thích cách EMVN thu thập, lưu giữ và xử lý Dữ
          liệu cá nhân trong quá trình Người dùng truy cập Trang Web hoặc sử
          dụng Dịch vụ của chúng tôi. <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 1: <strong className='bold-text-8'>ĐỊNH NGHĨA</strong>
          </a>
          <strong className='bold-text-8'>
            {' '}
            <br />‍
          </strong>
          Các thuật ngữ viết hoa trong Chính sách bảo mật này có ý nghĩa như
          sau:
          <br />
          <br />“<strong>EMVN</strong>” nghĩa là Công ty TNHH Giải trí Đa Phương
          tiện EpicMusicVN. &nbsp;Trong Chính sách bảo mật này, &nbsp;EMVN có
          thể &nbsp;được đề cập là “chúng tôi” hoặc “của chúng tôi”, tùy theo
          ngữ cảnh.“
          <strong>Dịch vụ</strong>” nghĩa là mọi sản phẩm, dịch vụ, nội dung,
          tính năng, công nghệ hay chức năng, cũng như tất cả các Trang Web, ứng
          dụng và dịch vụ liên quan do EMVN &nbsp;cung cấp cho người dùng.“
          <strong>Trang Web</strong>” nghĩa là các Trang Web, ứng dụng di động,
          nền tảng mạng xã hội chính thức hoặc các thuộc tính trực tuyến khác mà
          qua đó EMVN cung cấp Dịch vụ và đã đăng tải hoặc liên kết với Chính
          sách bảo mật này.
          <br />
          <br />“<strong>Người dùng</strong>” nghĩa là cá nhân sử dụng Dịch vụ
          hoặc truy cập các Trang Web.
          <br />
          <br />“<strong>Dữ liệu cá nhân</strong>” có thể bao gồm tên, địa chỉ
          gửi thư (bao gồm cả địa chỉ thanh toán và địa chỉ giao hàng), số điện
          thoại, địa chỉ email, số thẻ thanh toán, thông tin tài khoản tài chính
          khác, số tài khoản, ngày sinh và thông tin chứng nhận do cơ quan có
          thẩm quyền cấp (ví dụ: số giấy phép lái xe, số căn cước, hộ chiếu, và
          mã số người nộp thuế). &nbsp;Dữ liệu cá nhân không bao gồm các thông
          tin không xác định danh tính của một Người dùng cụ thể.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 2: TÀI KHOẢN NGƯỜI DÙNG
          </a>
          <br />- Một số tính năng, Dịch vụ yêu cầu Người dùng phải tạo tài
          khoản và được cấp phép từ EMVN. Người dùng hoàn toàn chịu trách nhiệm
          duy trì, cập nhật chính xác thông tin cũng như tính bảo mật của dữ
          liệu cá nhân, bao gồm nhưng không giới hạn mật khẩu và mọi hoạt động
          xảy ra trong tài khoản. Người dùng đồng ý thông báo cho EMVN ngay lập
          tức về bất kỳ vi phạm nào đối với tài khoản hoặc bất kỳ vi phạm bảo
          mật nào khác. <br />- Người dùng có thể phải chịu trách nhiệm cho
          những mất mát do EMVN hoặc bất kỳ người dùng khác hoặc khách truy cập
          vào Trang Web do những đối tượng này sử dụng thông tin tài khoản của
          bạn xuất phát từ lỗi không bảo mật và bảo mật thông tin tài khoản của
          mình.
          <br />- Chấm dứt tài khoản: Người dùng có thể đóng tài khoản và chấm
          dứt mối quan hệ với EMVN bất cứ lúc nào mà không bị mất phí hoặc tiền
          phạt. Tuy nhiên, Người dùng vẫn phải chịu trách nhiệm với các quyền và
          nghĩa vụ liên quan đến tài khoản ngay cả khi tài khoản đã chấm dứt.
          <br />- Tài khoản Người dùng có thể chấm dứt trong các trường hợp sau
          đây: <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Người dùng thông báo và đề
          nghị việc chấm dứt tài khoản với EMVN;
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Người dùng là Đối tác hợp tác
          kinh doanh không phát sinh bất cứ đơn hàng nào trong 06 tháng liên
          tục.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Người dùng vi phạm các thỏa
          thuận pháp lý của EMVN và/hoặc các quy định pháp luật khác;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bất kỳ trường hợp chấm dứt tài
          khoản nào cũng sẽ được EMVN gửi thông báo đến Người dùng với lý do cụ
          thể. <br />- Tài khoản Người dùng không thể chấm dứt trong các trường
          hợp sau đây:: <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Người dùng đang
          có giao dịch, nghĩa vụ chưa hoàn thành và cần xử lý.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Tài khoản Người dùng đang có
          tranh chấp, khiếu nại hoặc các vấn đề cần chờ xác minh.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 3: NỘI DUNG VÀ PHẠM VI SỬ DỤNG
          </a>
          <br />- Thỏa thuận này điều chỉnh các nội dung sau đây: &nbsp;
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Trang Web: Tất cả văn bản, đồ
          họa, giao diện người dùng, giao diện trực quan, ảnh, nhãn hiệu, logo,
          âm thanh, âm nhạc, tác phẩm nghệ thuật và mã máy tính (sau đây gọi là
          "Nội dung") trên Trang Web được sở hữu, kiểm soát hoặc cấp phép bởi
          EMVN và được bảo vệ bởi cơ quan chức năng có thẩm quyền. <br />{' '}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Dịch vụ: EMVN cung cấp các dịch vụ đặc
          thù liên quan đến việc sử dụng các tác phẩm âm nhạc, bản ghi. Các Dịch
          vụ cùng thông tin chi tiết sẽ được công bố trên Trang Web và được chủ
          động cập nhật, thay đổi tùy theo thời điểm. Để được cấp phép sử dụng
          Dịch vụ, Người dùng còn cần tuân thủ các thỏa thuận riêng. Thông tin
          chi tiết tại “Service Agreement”. <br />- Người dùng đồng ý KHÔNG thực
          hiện các hành vi sau đây trong quá trình sử dụng Trang Web và Dịch vụ
          của EMVN: <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Xâm nhập, tìm cách
          xâm nhập trái phép vào tài khoản người dùng khác để truy cập Trang Web
          và sử dụng Dịch vụ.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Xâm nhập, tìm cách xâm nhập,
          tiếp cận hoặc sử dụng bất kỳ các dữ liệu nào trên Trang Web và máy chủ
          của EMVN mà không được cho phép.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Phát tán, sử dụng các chương
          trình có hại gây trở ngại, gian lận hoặc ảnh hưởng tới hệ thống, dữ
          liệu, thông tin, Dịch vụ của EMVN. &nbsp;
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Thực hiện các hành vi làm tổn
          hại đến uy tín của EMVN và/hoặc các Dịch vụ của EMVN dưới bất kỳ hình
          thức nào.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Thực hiện các hành vi
          &nbsp;trái với thuần phong mỹ tục, đạo đức xã hội và quy định pháp
          luật &nbsp;dưới bất kỳ hình thức nào.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Sử dụng các thông tin, dữ liệu
          của Trang Web vào mục đích thương mại.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 4: ĐIỀU KHOẢN THANH TOÁN
          </a>
          <br />- Thanh toán chung:
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Người dùng có trách nhiệm kiểm
          tra kỹ Dịch vụ, thỏa thuận dịch vụ, Điều khoản sử dụng trước khi thực
          hiện thanh toán.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Chi phí các Dịch vụ do EMVN
          cung cấp &nbsp;đã bao gồm thuế VAT.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ EMVN có quyền cập nhật và thay
          đổi giá Dịch vụ tùy từng thời điểm và mục đích kinh doanh. Việc thay
          đổi mức giá được cập nhật công khai và gửi thông báo đến Người dùng
          trong thời gian hợp lý. Mức giá mới có hiệu lực vào thời điểm được cập
          nhật tại Trang Web. Nếu bạn tiếp tục sử dụng Trang Web và đăng ký sử
          dụng Dịch vụ &nbsp;sau thời điểm thay đổi có nghĩa là bạn đã đồng ý
          chấp nhận mức giá mới.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ EMVN không có trách nhiệm hoàn
          trả chi phí Dịch vụ và hoặc chịu bất kỳ chi phí nào &nbsp;khác sau khi
          Người dùng đã thanh toán, bao gồm nhưng không giới hạn các trường hợp
          sử dụng một phần Dịch vụ, đề nghị chấm dứt Dịch vụ trước thời hạn.
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Liên kết thanh toán: &nbsp;EMVN
          sử dụng liên kết thanh toán với bên thứ ba nhằm đảm bảo mục đích kinh
          doanh và đáp ứng nhu cầu sử dụng của Người dùng, bao gồm nhưng không
          giới hạn tiền mặt, Internet Banking, ví điện tử,... Trong trường hợp
          này, chúng tôi yêu cầu các bên thứ ba đó phải đảm bảo các nghĩa vụ
          tương ứng với EMVN theo luật pháp liên quan.
          <br />- Đối với trường hợp phát sinh lỗi khi thanh toán, Người dùng có
          nghĩa vụ thông báo và cung cấp các chứng từ xác minh cho giao dịch
          thanh toán cho EMVN để được kiểm tra và xử lý. Chúng tôi chỉ chịu
          trách nhiệm đối với các trường hợp lỗi phát sinh từ phía EMVN.
          &nbsp;EMVN không có chính sách hoàn trả lại giao dịch đã thực hiện.
          <br />- EMVN luôn ưu tiên và nỗ lực xây dựng một cơ chế thanh toán an
          toàn cho các giao dịch tại Trang web nhưng vẫn đáp ứng được yêu cầu sử
          dụng của Người dùng.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 5: QUYỀN VÀ NGHĨA VỤ NGƯỜI DÙNG
          </a>
          <br />
          Trong phạm vi Thỏa thuận này, Người dùng có các Quyền và nghĩa vụ sau:
          <br />- Được cung cấp, giới thiệu đầy đủ, phù hợp các Dịch vụ nhằm đáp
          ứng nhu cầu sử dụng. &nbsp;Theo đó, việc Người dùng cam kết đảm bảo
          thực hiện đúng các thỏa thuận pháp lý của chúng tôi sẽ là cơ sở để đảm
          bảo quyền này. &nbsp; &nbsp; &nbsp;
          <br />- Nhận được sự hỗ trợ từ các kênh chăm sóc khách hàng của EMVN.
          Theo đó, việc Người dùng phải cam kết cung cấp thông tin đầy đủ,
          &nbsp;trung thực, chính xác và luôn cập nhật sẽ là cơ sở để chúng tôi
          đảm bảo quyền này. Người dùng phải chịu trách nhiệm về toàn bộ các
          thông tin cung cấp.
          <br />- Người dùng có trách nhiệm bảo mật dữ liệu cá nhân như:
          &nbsp;Mật khẩu, số điện thoại bảo vệ tài khoản, giấy tờ tùy thân,
          Email bảo vệ tài khoản, tài khoản liên kết... Nếu những thông tin trên
          bị tiết lộ dưới bất kỳ hình thức nào thì Người dùng phải chấp nhận
          những rủi ro phát sinh. Người dùng đồng ý sẽ thông báo ngay lập tức
          cho EMVN &nbsp;về bất kỳ trường hợp nào sử dụng trái phép tài khoản và
          mật khẩu của bạn hoặc bất kỳ các hành động phá vỡ hệ thống bảo mật
          nào. <br />- Cam kết không thực hiện các hành vi được quy định tại
          Điều 3 Thỏa thuận này.
          <br />- Thông báo cho EMVN theo các kênh liên lạc được cung cấp tại
          Thỏa thuận này khi phát hiện ra lỗi của Trang Web, Dịch vụ, các vấn đề
          gây ảnh hưởng tới hoạt động bình thường của Trang Web cũng như Dịch vụ
          liên quan.Thực hiện trách nhiệm khác theo quy định pháp luật.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 6: QUYỀN VÀ NGHĨA VỤ CỦA EMVN
          </a>
          <br />
          Trong phạm vi Thỏa thuận này, EMVN có các Quyền và nghĩa vụ sau:{' '}
          <br />- Đảm bảo tính pháp lý của Trang Web, Dịch vụ cung cấp cho Người
          dùng. Theo đó, EMVN cam kết công khai các dịch vụ, Chi phí dịch vụ,
          thỏa thuận cũng như các vấn đề liên quan đến Người dùng.
          <br />- Hỗ trợ Người dùng trong quá trình sử dụng Trang Web, Dịch vụ
          được cung cấp; Tiếp nhận, giải quyết khiếu nại đúng quy định của Người
          dùng trong quá trình sử dụng Trang Web, Dịch vụ được cung cấp. <br />-
          Bảo mật dữ liệu cá nhân Người dùng. EMVN cam kết không bán hoặc trao
          đổi những thông tin này với bên thứ ba, trừ trường hợp theo quy định
          pháp luật hoặc được thành viên chấp nhận. <br />- Rà soát và có quyền
          quyền tạm khóa, chấm dứt tài khoản của Người dùng mà không cần sự đồng
          ý và không phải chịu bất cứ trách nhiệm nào trong trường hợp phát hiện
          hoặc có cơ sở cho thấy Người dùng cung cấp thông tin không trung thực,
          không chính xác, hoặc &nbsp;Người dùng vi phạm bất cứ điều khoản nào
          trong thỏa thuận pháp lý của chúng tôi. <br />- Khi phát hiện những vi
          phạm như gian lận, bẻ khóa hay đánh cắp thông tin hoặc những lỗi khác,
          ở mức độ hợp lý, EMVN có quyền yêu cầu cơ quan chức năng can thiệp để
          giải quyết. Theo đó, chúng tôi có quyền sử dụng thông tin được cung
          cấp để phục vụ quá trình này &nbsp;theo quy định của pháp luật.
          <br />- Phối hợp với cơ quan nhà nước có thẩm quyền trong các trường
          hợp cần thiết liên quan quy trình báo cáo, thanh tra, kiểm tra, kiện
          tụng pháp lý; liên quan đến các hoạt động khủng bố, tội phạm, an ninh
          quốc gia.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 7: BẢO MẬT DỮ LIỆU CÁ NHÂN
          </a>{' '}
          &nbsp;
          <br />
          Tại EMVN việc bảo vệ dữ liệu cá nhân của Người dùng luôn là ưu tiên
          hàng đầu. Chúng tôi hiểu rằng, các dữ liệu của Người dùng là thuộc
          quyền sở hữu hợp pháp và được pháp luật bảo vệ, chính vì vậy, việc thu
          thập, lưu giữ, xử lý Dữ liệu cá nhân chỉ được EMVN thực hiện khi có sự
          đồng ý và cho phép của Người dùng.
          <br />
          Chúng tôi luôn nỗ lực xây dựng và hoàn thiện các điều khoản trong
          Chính sách bảo mật để đảm bảo các tiêu chuẩn và yêu cầu của Người
          dùng. Theo đó, Chính sách Bảo mật áp dụng cho toàn bộ việc sử dụng
          Trang Web và Dịch vụ của EMVN. Các điều khoản của Chính sách bảo mật
          cũng được coi là một phần của Thỏa thuận người dùng này. Bạn vui lòng
          tìm hiểu chi tiết tại “Chính sách bảo mật”
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 8: TRƯỜNG HỢP BẤT KHẢ KHÁNG{' '}
          </a>
          <br />
          Nếu phát sinh rủi ro, thiệt hại trong trường hợp bất khả kháng bao gồm
          nhưng không giới hạn như chập điện, hư hỏng phần cứng, phần mềm, sự cố
          đường truyền internet hoặc do thiên tai v.v. Người dùng phải chấp nhận
          những rủi ro, thiệt hại nếu có. EMVN cam kết sẽ nỗ lực giảm thiểu
          những rủi ro, thiệt hại phát sinh.Tuy nhiên chúng tôi sẽ không chịu
          bất cứ trách nhiệm nào phát sinh trong các trường hợp này.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 9: SỞ HỮU TRÍ TUỆ
          </a>
          <br />- Người dùng đồng ý rằng Trang Web, Dịch vụ, bao gồm nhưng không
          giới hạn ở nội dung, đồ họa, giao diện người dùng, clip âm thanh, clip
          video, nội dung biên tập và phần mềm được sử dụng để thực hiện Dịch
          vụ, có chứa thông tin và tài liệu độc quyền thuộc sở hữu của EMVN
          và/hoặc bên cấp phép của EMVN, và được bảo vệ theo luật sở hữu trí tuệ
          và các luật khác, bao gồm nhưng không giới hạn ở luật bản quyền. Người
          dùng đồng ý sẽ không sử dụng thông tin hoặc tài liệu độc quyền này
          theo bất kỳ cách nào, ngoại trừ việc sử dụng Dịch vụ phù hợp với Thỏa
          thuận này.
          <br />- Tất cả quyền sở hữu trí tuệ tồn tại trên Trang Web, trong các
          Dịch vụ đều thuộc về EMVN hoặc được cấp phép hợp pháp cho EMVN. Theo
          đó, tất cả các quyền hợp pháp của EMVN đều được đảm bảo bởi pháp luật.
          Trừ khi có sự đồng ý bằng văn bản của EMVN, Người dùng không được phép
          sử dụng, sao chép, xuất bản, tái sản xuất, truyền hoặc phân phát bằng
          bất cứ hình thức nào, bất cứ thành phần nào của Dịch vụ và thông tin
          trên Trang Web.
          <br />- EMVN có toàn quyền, bao gồm nhưng không giới hạn các quyền về
          thương hiệu, bí mật kinh doanh và các quyền sở hữu khác đối với Trang
          Web, Dịch vụ của EMVN. Việc sử dụng quyền và sở hữu của EMVN cần phải
          được cấp phép rõ ràng bằng văn bản. Theo đó chúng tôi không cấp phép
          dưới bát kỳ hình thức nào bao gồm nhưng không giới hạn các công bố,
          hàm ý cho phép Người dùng thực hiện các quyền trên. &nbsp;
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 10: CHẤM DỨT VÀ TẠM NGỪNG DỊCH VỤ
          </a>
          <br />
          EMVN có quyền ngừng cung cấp Dịch vụ cho Người dùng trong các trường
          hợp sauNgười dùng không tuân thủ, hoặc có căn cứ để EMVN nghi ngờ
          người dùng đã không tuân thủ, bất kỳ điều khoản nào của Thỏa thuận
          này. Theo đó EMVN có thể, mà không cần thông báo cho quý khách: (i)
          chấm dứt Thỏa thuận này và/hoặc tài khoản của Người dùng và/hoặc (ii)
          chấm dứt thỏa thuận sử dụng Dịch vụ của quý khách; và/hoặc (iii) ngăn
          cản quý khách truy cập vào Trang Web.Người dùng sử dụng Dịch vụ
          và/hoặc Trang Web nhằm thực hiện hành vi vi phạm pháp luật.Bao gồm
          nhưng không giới hạn các hành vi gian lận trong sử dụng dịch vụ, gian
          lận trong thanh toán…Theo yêu cầu cảu cơ quan chức năng có thẩm quyền
          và quy định của pháp luật.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 11: TỪ CHỐI BẢO ĐẢM &amp; GIỚI HẠN TRÁCH NHIỆM
          </a>
          <br />- EMVN tuyên bố từ chối trách nhiệm đối với mọi thiệt hại, trách
          nhiệm pháp lý phát sinh do bất kỳ lỗi hệ thống, lỗi đường truyền; sự
          cố kỹ thuật; sự cố máy chủ hoặc do trường hợp Bất khả kháng … Người
          dùng phải chấp nhận những rủi ro, thiệt hại nếu có. Tuy nhiên, EMVN
          cam kết sẽ nỗ lực giảm thiểu rủi ro, thiệt hại phát sinh. <br />- EMVN
          sẽ không chịu bất kỳ loại trách nhiệm nào cùng với các khoản thanh
          toán, phí, tiền phạt hay bồi thường mà không phải phát sinh từ lỗi cố
          ý của EMVN. <br />- EMVN sẽ thực hiện những cố gắng hợp lý để bảo vệ
          thông tin do Người dùng cung cấp có liên quan đến Trang Web, DỊch vụ.
          Tuy nhiên, Người dùng đồng ý miễn trừ EMVN đối với bất kỳ và tất cả
          trách nhiệm pháp lý với bất kỳ thiệt hại hoặc trách nhiệm nào liên
          quan đến các thông tin đó. <br />- Đối với các trường hợp sử dụng,
          liên kết sử dụng dịch vụ với bên thứ ba, EMVN chỉ hỗ trợ Người dùng mà
          không kiểm soát, liên quan hay chịu bất kỳ trách nhiệm nào trong thỏa
          thuận giữa Người dùng và bên thứ ba đó. Người dùng đồng ý cam kết loại
          trừ trách nhiệm của EMVN với các nghĩa vụ giữa Người dùng và bên thứ
          ba cung cấp dịch vụ. <br />
          - Người dùng đồng ý bồi thường và giữ cho EMVN, giám đốc, thành viên
          sáng lập, người tiền nhiệm, người kế thừa, nhân viên, đại lý, công ty
          con và chi nhánh, không bị tổn hại bởi bất kỳ yêu cầu, trách nhiệm,
          khiếu nại hoặc chi phí nào (kể cả phí luật sư) bởi bất kỳ bên thứ ba
          nào do hoặc phát sinh từ hoặc liên quan đến việc Người dùng sử dụng
          Trang Web, Dịch vụ.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 12: GIẢI QUYẾT TRANH CHẤP
          </a>
          <br />- Tất cả các vấn đề liên quan đến việc Người dùng truy cập hoặc
          sử dụng Trang Web, Dịch vụ, bao gồm các tranh chấp, sẽ chịu sự điều
          chỉnh của luật pháp VIệt Nam.
          <br />- Các khiếu nại phát sinh trong quá trình sử dụng Trang Web,
          Dịch vụ phải được gửi đến EMVN ngay khi sự kiện đó phát sinh. Chúng
          tôi cam kết sẽ hỗ trợ giải quyết hợp lý cho những trường hợp cung cấp
          thông tin đầy đủ chính xác. Bên khiếu nại có nghĩa vụ cung cấp bằng
          chứng liên quan đến khiếu nại và chịu trách nhiệm về nội dung khiếu
          nại cũng như bằng chứng đã cung cấp. <br />- Trong trường hợp xảy ra
          tranh chấp giữa Người dùng hoặc tranh chấp với bên thứ ba, các bên
          &nbsp;sẽ tự sắp xếp giải quyết vấn đề của mình. EMVN sẽ hỗ trợ thông
          tin liên hệ và các thông tin hợp lý để bảo vệ tối đa quyền lợi hợp
          pháp và chính đáng của Người dùng.
          <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 13: HIỆU LỰC THỎA THUẬN
          </a>
          <br />- Các Điều khoản sử dụng cấu thành nên toàn bộ Thỏa thuận giữa
          Người dùng và EMVN và điều chỉnh việc sử dụng Trang Web, Dịch vụ của
          Người dùng, &nbsp;thay thế các thỏa thuận trước đây đối với vấn đề
          chính giữa các bên. .Người dùng cũng phải tuân thủ các điều khoản và
          điều kiện bổ sung có thể áp dụng khi sử dụng dịch vụ trực thuộc, nội
          dung của bên thứ ba, phần mềm của bên thứ ba, hoặc các dịch vụ bổ
          sung.
          <br />- Nếu bất kỳ phần nào của Thỏa thuận này không có hiệu lực hoặc
          không thể thi hành, phần đó sẽ được hiểu theo cách phù hợp với pháp
          luật và tiếp tục có hiệu lực thi hành. Việc EMVN không thực hiện bất
          kỳ quyền hoặc quy định nào trong Thỏa thuận này sẽ không được coi là
          từ bỏ quy định đó hoặc bất kỳ quy định nào khác. Chúng tôi cũng sẽ
          không chịu trách nhiệm về việc không thực hiện bất kỳ nghĩa vụ nào do
          các nguyên nhân nằm ngoài tầm kiểm soát của mình.
          <br />- Người dùng đồng ý cấp cho EMVN quyền thực hiện các bước cần
          thiết hoặc thích hợp một cách hợp lý để thi hành và/hoặc xác minh sự
          tuân thủ với bất kỳ phần nào của Thỏa thuận này. <br />
          <br />
          <br />
          <a href='#' className='bold-text-8'>
            ĐIỀU 14: LIÊN HỆ VỚI CHÚNG TÔI
          </a>
          <br />
          Nếu có bất kỳ thắc mắc nào liên quan đến Thỏa thuận người dùng này,
          bạn có thể liên hệ với EMVN bằng cách gửi email tới địa chỉ
          contact@emvn.co. EMVN rất vui lòng và sẵn sàng hỗ trợ Người dùng liên
          quan.
          <br />‍<br />
          Bản Điều khoản và Điều kiện được cập nhật vào ngày 14/07/2020
          <br />
          <br />
          <br />
        </p>
      </div>
      ;
      <Footer />
    </>
  );
};
