import React, { ReactNode } from 'react';

import './index.scss';

interface TProps {
  onOk?: () => void;
  onCancel?: () => void;
  modalState: string;
  children: ReactNode;
}

export const Modal = ({ modalState, onOk, onCancel, children }: TProps) => {
  return (
    <div className='custom-modal' style={{ display: modalState }}>
      {/* <button onClick={() => onOk()}>Check</button> */}
      <div className='custom-modal-content'>
        {/* <span className='close' onClick={onCancel}>
          &times;
        </span> */}
        {children}
      </div>
    </div>
  );
};
