/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// COMPONENT
import { HeaderBackground } from '../../components/headerbackground';
import { Footer } from '../../components/footer';
import './index.scss';

export const FAQ = () => {
  return (
    <>
      <HeaderBackground
        background='/assets/images/faq.jpeg'
        mainTitle='CÂU HỎI'
        subTitle='THƯỜNG GẶP'
      />
      <div
        id='Intro'
        className='content-section-normal top-padding-visible faq'
      >
        <div className='hero-content-wrapper faq w-container'>
          <div className='flex mobile-vertical faq'>
            <div className='faq-left w-clearfix'>
              <div className='position-sticky'>
                <h4 className='h4 title'>FAQs</h4>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#License' className='no-underline w--current'>
                    Bắt đầu
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Basic' className='no-underline'>
                    Quản lý tài khoản
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Music' className='no-underline'>
                    Gói Subscription
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Payment' className='no-underline'>
                    Thanh toán
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Misc' className='no-underline'>
                    Bản quyền nhạc
                  </a>
                </div>
                <div className='top-margin-7 _20-pixel'>
                  <a href='#Misc' className='no-underline'>
                    Xác nhận bản quyền &amp; contentID
                  </a>
                </div>
              </div>
            </div>
            <div className='expand faq'>
              <div id='License' className='faq-set'>
                <div className='set-title'>
                  <h3 className='h3 big'>bắt đầu</h3>
                  <div>
                    <p className='subtitle'>Thông tin chung</p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_1'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể nghe, tìm các bài nhạc để sử dụng ở
                              đâu?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_1'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Bạn có thể nghe, tìm và trải nghiệm âm nhạc tại thư
                            viện nhạc EMVN:{' '}
                            <a href='https://emvn.sourceaudio.com/#!home'>
                              http://emvn.co{' '}
                            </a>
                            Hoặc truy cập vào website -&gt; Free trial để được
                            điều hướng tới thư viện nhạc.Lưu ý rằng, để sử dụng
                            thư viện nhạc bạn cần tạo
                            <a href='https://secure.sourceaudio.com/signup.php?sub=epicmusicvn'>
                              {' '}
                              một tài khoản
                            </a>
                            . Nếu đã có tài khoản, hãy đăng nhập
                            <a href='https://secure.sourceaudio.com/login.php?forward=http%3A%2F%2Fepicmusicvn.sourceaudio.com%2F%23!home'>
                              {' '}
                              tại đây
                            </a>{' '}
                            Thư viện nhạc EMVN luôn được cập nhật mỗi ngày.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-wrapper'>
                  <a
                    href='#accordion-wrapper_2'
                    className='accordion-item-trigger w-inline-block'
                    data-toggle='collapse'
                    aria-expanded='false'
                  >
                    <div className='open-close-box'>
                      <img
                        src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                        width={16}
                        alt=''
                        className='accordion-arrow'
                        style={{
                          transform:
                            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                          transformStyle: 'preserve-3d',
                        }}
                      />
                    </div>
                    <div className='expand right-padding'>
                      <div className='flex-no-wrap'>
                        <h4 className='question-title'>
                          <strong>
                            Tôi có thể sử dụng nhạc trên thư viện miễn phí được
                            không?
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </a>
                  <div
                    id='accordion-wrapper_2'
                    className='collapse accordion-item-content'
                    style={{ height: 0 }}
                  >
                    <div className='flex-no-wrap'>
                      <div>
                        <p className='answer'>
                          Đối với các bản ghi trên thư viện đều là các bản ghi
                          có bản quyền. Bạn hoàn toàn có cơ hội để tải các bản
                          ghi xuống với tài khoản dùng thử, từ đó cân nhắc mức
                          độ phù hợp của các bài nhạc đối với dự án của mình.
                          Tuy nhiên EMVN không cung cấp bản quyền cho các bản
                          nhạc bạn đã tải xuống từ tài khoản dùng thử của mình.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-wrapper'>
                  <a
                    href='#accordion-wrapper_3'
                    className='accordion-item-trigger w-inline-block'
                    data-toggle='collapse'
                    aria-expanded='false'
                  >
                    <div className='open-close-box'>
                      <img
                        src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                        width={16}
                        alt=''
                        className='accordion-arrow'
                        style={{
                          transform:
                            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                          transformStyle: 'preserve-3d',
                        }}
                      />
                    </div>
                    <div className='expand right-padding'>
                      <div className='flex-no-wrap'>
                        <h4 className='question-title'>
                          <strong>
                            Quyền lợi của tài khoản dùng thử là gì?
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </a>
                  <div
                    id='accordion-wrapper_3'
                    className='collapse accordion-item-content'
                    style={{ height: 0 }}
                  >
                    <div className='flex-no-wrap'>
                      <div>
                        <p className='answer'>
                          Khi đăng ký tài khoản dùng thử thành công, bạn sẽ có
                          được 30 lượt tải nhạc miễn phí với hơn 100k bài trong
                          thư viện của EMVN để kiểm tra mức độ phù hợp của các
                          bản ghi với các dự án của mình
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-wrapper'>
                  <a
                    href='#accordion-wrapper_4'
                    className='accordion-item-trigger w-inline-block'
                    data-toggle='collapse'
                    aria-expanded='false'
                  >
                    <div className='open-close-box'>
                      <img
                        src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                        width={16}
                        alt=''
                        className='accordion-arrow'
                        style={{
                          transform:
                            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                          transformStyle: 'preserve-3d',
                        }}
                      />
                    </div>
                    <div className='expand right-padding'>
                      <div className='flex-no-wrap'>
                        <h4 className='question-title'>
                          <strong>
                            Làm thế nào để tôi tìm được một bài nhạc ưng ý?
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </a>
                  <div
                    id='accordion-wrapper_4'
                    className='collapse accordion-item-content'
                    style={{ height: 0 }}
                  >
                    <div className='flex-no-wrap'>
                      <div>
                        <p className='answer'>
                          Để tìm được một bài nhạc ưng ý, bạn có thể sử dụng
                          công cụ tìm kiếm của EMVN ngay phía trên đầu trang.
                          Thanh tìm kiếm hỗ trợ bạn tìm nhạc theo cảm xúc
                          (mood), phong cách (styles). Ngoài ra bạn còn có thể
                          tìm kiếm 1 bài nhạc dựa vào chức năng tìm kiếm nâng
                          cao (Advanced Search).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-wrapper'>
                  <a
                    href='#accordion-wrapper_5'
                    className='accordion-item-trigger w-inline-block'
                    data-toggle='collapse'
                    aria-expanded='false'
                  >
                    <div className='open-close-box'>
                      <img
                        src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                        width={16}
                        alt=''
                        className='accordion-arrow'
                        style={{
                          transform:
                            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                          transformStyle: 'preserve-3d',
                        }}
                      />
                    </div>
                    <div className='expand right-padding'>
                      <div className='flex-no-wrap'>
                        <h4 className='question-title'>
                          <strong>
                            Làm thế nào để tôi có thể tải xuống các bài nhạc từ
                            Thư viện?
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </a>
                  <div
                    id='accordion-wrapper_5'
                    className='accordion-item-content'
                    style={{ height: 0 }}
                  >
                    <div className='flex-no-wrap'>
                      <div>
                        <p className='answer'>
                          Sau khi tài khoản dùng thử được kích hoạt, bạn có thể:
                          <br />- Nghe trực tuyến bài nhạc trên website{' '}
                          <a target='_blank' href='http://emvn.co/'>
                            http://emvn.co
                          </a>
                          <br />- Tải xuống bài nhạc ở mục Download Now
                          <br />
                          Lưu ý rằng bạn sẽ chỉ có thể tải xuống giới hạn số
                          lượng của một tài khoản dùng thử.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Basic' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>quản lý tài khoản</h3>
                  <div>
                    <p className='subtitle'> </p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_6'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Làm thế nào để tạo tài khoản và trải nghiệm nhạc
                              tại thư viện
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_6'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            - Truy cập Thư viện nhạc
                            <a target='_blank' href='http://emvn.co/'>
                              {' '}
                              EMVN{' '}
                            </a>
                            <br />- Chọn mục REGISTER tại góc phải màn hình
                            -&gt; Xuất hiện bảng SIGN UP
                            <br />- Điền các thông tin được yêu cầu tại bảng
                            này. Vui lòng điền chính xác thông tin để bộ phận
                            CSKH của chúng tôi có thể liên lạc với bạn để cập
                            nhật về tình trạng tài khoản
                            <br />- Chọn Sign up để hoàn tất quá trình đăng ký.{' '}
                            <br />
                            - Tài khoản sau khi tạo sẽ cần kích hoạt thủ công
                            bởi EMVN. <br />- EMVN sẽ gửi thông báo tình trạng
                            tài khoản qua email được cung cấp
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_7'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Làm thế nào để tôi thay đổi được thông tin tài
                              khoản?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_7'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Vào phần Dashboard ngay dưới ảnh đại diện của bạn,
                            chọn phần Manage Account để có thể thay đổi các
                            thông tin tài khoản bao gồm tên, email, mật khẩu và
                            ảnh đại diện
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_8'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Làm thế nào để tôi thay đổi được địa chỉ email
                              đăng nhập?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_8'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Vào phần Dashboard ngay dưới ảnh đại diện của bạn,
                            chọn phần Manage Account để có thể thay đổi email.
                            Sau khi thay đổi thông tin email, bạn cần phải ấn
                            vào link kích hoạt đã được gửi về địa chỉ email mới
                            để kích hoạt.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Music' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>gói subscription</h3>
                  <div>
                    <p className='subtitle'> </p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_9'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Làm thế nào để tôi có thể đăng ký gói
                              Subscription?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_9'
                      className='accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Để đăng ký và được tư vấn kỹ càng về gói
                            Subscription, bạn vui lòng gửi yêu cầu vào form{' '}
                            <a
                              target='_blank'
                              href='https://airtable.com/shrp4gYE9wFmH509s'
                            >
                              Đăng ký Mua nhạc
                            </a>{' '}
                            EMVN team sẽ liên hệ bạn để hỗ trợ và giải đáp các
                            yêu cầu của bạn về việc đăng ký gói Subscription
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_10'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể thay đổi thông tin kênh YouTube hay
                              Facebook đã đăng ký ban đầu hay không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_10'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Hoàn toàn được. Bạn có thể gửi thông tin kênh
                            YouTube hoặc Facebook cũ và mới về email
                            care@emvn.co hoặc gửi yêu cầu tới{' '}
                            <a target='_blank' href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                              EMVN Contact
                            </a>{' '}
                            EMVN sẽ liên hệ và phản hồi về tình trạng tiếp nhận
                            yêu cầu của bạn
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_11'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể hủy gói trước thời hạn hợp đồng hay
                              không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_11'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Bạn có thể hủy gói đã đăng ký trước thời hạn bằng
                            một thông báo tới EMVN tại địa chỉ email
                            license@emvn.co để nhận được hướng dẫn. Lưu ý rằng
                            EMVN sẽ không hoàn trả lại bất kỳ khoản phí nào có
                            liên quan đến hợp đồng nếu bạn hủy gói trước thời
                            hạn .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_12'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Nếu đăng ký gói Subscription dành cho cá nhân, tôi
                              có được sử dụng nhạc cho các video có nội dung tài
                              trợ hay quảng cáo không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_12'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Gói Subscription dành cho khách hàng cá nhân hiện
                            tại chưa hỗ trợ việc đăng tải các nội dung, video có
                            chứa quảng cáo hoặc nội dung được tài trợ. Tuy
                            nhiên, các nội dung này khi đăng tải trên YouTube
                            vẫn được đảm bảo chức năng bật kiếm tiền.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_13'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể sử dụng 1 bản ghi cho nhiều video với
                              các dự án khác nhau được hay không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_13'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Hoàn toàn được khi bạn đăng ký gói Subscription. Bạn
                            chỉ cần mua gói và có quyền sử dụng và tải xuống
                            không giới hạn các bản ghi trong thời gian đăng ký.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_14'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể đăng ký sử dụng 2 kênh YouTube hoặc
                              Facebook khi đăng ký gói dành cho Cá nhân hay
                              Professional không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_14'
                      className='accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Hiện tại các gói tài khoản dành cho Cá nhân và
                            Professional chỉ hỗ trợ tối đa 1 kênh cho mỗi nền
                            tảng số. Tuy nhiên EMVN hoàn toàn có thể điều chỉnh
                            các quyền lợi của bạn để phù hợp với nhu cầu của
                            khách hàng ( gói Custom). Vui lòng liên hệ EMVN qua
                            hotline hoặc form EMVN Contact để được tư vấn kỹ
                            hơn
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_15'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có cần gỡ video sau khi hết hạn hợp đồng hay
                              không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_15'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Không. Bản quyền của các bản nhạc mà EMVN cung cấp
                            cho bạn là trọn đời. Bạn hoàn toàn không cần phải gỡ
                            các sản phẩm của mình xuống sau khi hết hạn hợp đồng
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Payment' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>thanh toán</h3>
                  <div>
                    <p className='subtitle'> </p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_16'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Các phương thức thanh toán cho gói Subscription là
                              gì?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_16'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            EMVN hỗ trợ thanh toán qua chuyển khoản ngân hàng.
                            Để có thông tin chi tiết về thủ tục thanh toán, bạn
                            vui lòng gửi yêu cầu qua{' '}
                            <a
                              target='_blank'
                              href='https://airtable.com/shrp4gYE9wFmH509s'
                            >
                              Đăng ký Mua nhạc
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_17'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể mua lẻ các bản ghi trong thư viện của
                              bạn sau khi đã đăng ký gói Subscription được
                              không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_17'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Hoàn toàn được. Đối với các gói dành cho cá nhân và
                            Professional, bạn hoàn toàn đăng ký mua lẻ các bản
                            ghi ngoài danh mục cho phép với mức giá ưu đãi. Để
                            đăng ký mua bản quyền cho các bản ghi mong muốn, bạn
                            có thể liên hệ trực tiếp qua email care@emvn.co hoặc
                            form{' '}
                            <a target='_blank' href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                              EMVN Contact
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Misc' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>
                    <strong>Bản quyền nhạc </strong>
                  </h3>
                  <div>
                    <p className='subtitle'> </p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_18'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Những loại bản quyền nhạc nào mà bên EMVN đang
                              cung cấp?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_18'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Hiện tại EMVN đang cung cấp 2 loại bản quyền nhạc:
                            Quyền đồng bộ và quyền phát sóng ngoài công chúng.
                            Quyền đồng bộ cho phép bạn sử dụng các bản ghi,
                            chỉnh sửa các bài nhạc để hoàn thiện các sản phẩm
                            của mình. Quyền phát sóng trong công chúng cho phép
                            bạn được phát sóng rộng rãi trong công chúng như
                            phát sóng truyền hình, phát thanh ở nơi công cộng
                            như công viên, rạp xiếc, rạp chiếu phim cũng như
                            trên các nền tảng xã hội như Facebook, Youtube.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_19'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Bản quyền nhạc có hiệu lực dài bao lâu?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_19'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Bản quyền nhạc mà EMVN cung cấp có hiệu lực trọn
                            đời, không phụ thuộc vào thời gian bạn ký hợp đồng
                            với EMVN.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_20'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Bản quyền nhạc tôi mua có bao gồm cho các dự án mà
                              tôi làm cho đối tác hay không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_20'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Đối với gói dành cho Doanh nghiệp (Industrial), EMVN
                            hoàn toàn hỗ trợ gỡ đánh dấu vi phạm cho các dự án
                            và video cụ thể mà bạn làm cho các đối tác.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_21'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tôi có thể chuyển nhượng bản quyền cho bên thứ 3
                              được không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_21'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Đối với bản quyền tác phẩm âm nhạc được cấp phép
                            theo gói subscription, bạn sẽ không được phép chuyển
                            nhượng cho bên thứ ba. <br />
                            Đối với bản quyền các chương trình được sản xuất
                            trong thời hạn gói subscription,bạn được phép chuyển
                            nhượng cho bên thứ ba. Tuy nhiên, bạn cần thông báo
                            với EMVN tới địa chỉ email license@emvn.co trong
                            vòng 10 (mười) ngày kể từ ngày phát sinh thay đổi để
                            xác nhận và được hướng dẫn.
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_22'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Những nền tảng nào mà tôi được công khai sản phẩm
                              video?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_22'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Tùy vào từng gói subcription, các nền tảng bạn được
                            đăng tải chương trình sẽ có sự khác biệt. Cụ thể như
                            sau: <br />- Đối với gói Personal Creator: YouTube
                            và các trang mạng xã hội: Facebook, Instagram
                            <br />- Đối với gói Professional
                            Creator&amp;Industrial Producer: Các nền tảng chia
                            sẻ video trực tuyến, bao gồm nhưng không giới hạn
                            YouTube, Vimeo và các nền tảng mạng xã hội bao gồm
                            nhưng không giới hạn các hình thức giao lưu trực
                            tuyến, điều hướng sử dụng, giao tiếp phi xã hội
                            Facebook, Instagram...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Misc' className='faq-set no-margin'>
                <div className='set-title'>
                  <h3 className='h3 big'>
                    <strong>Xác nhận bản quyền &amp; ContentID</strong>
                  </h3>
                  <div>
                    <p className='subtitle'> </p>
                  </div>
                </div>
                <div className='top-margin-7 mobile'>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_23'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Tại sao tôi mua bản quyền nhạc rồi mà vẫn bị đánh
                              dấu xác nhận bản quyền từ YouTube
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_23'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Việc đánh dấu xác nhận bản quyền video của bạn trên
                            YouTube không có nghĩa là bạn không có bản quyền
                            nhạc. Các dấu xác nhận xuất hiện khi phát hiện ra
                            nội dung trong video của bạn có sử dụng các tác phẩm
                            nhạc đã được đăng ký trên hệ thống ContentID.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_24'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Làm thế nào để tôi gỡ xác nhận bản quyền trên
                              video của tôi?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_24'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Nếu các video của bạn có xuất hiện đánh dấu bản
                            quyền, bạn có thể gửi yêu cầu gỡ xác nhận bản quyền
                            qua form{' '}
                            <a target='_blank' href='https://airtable.com/shr2kH3g6tLNFb5Vf'>
                              EMVN Contact
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_25'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Việc đánh dấu xác nhận bản quyền như vậy có ảnh
                              hưởng đến chức năng bật kiếm tiền của tôi hay
                              không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_25'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Chức năng bật kiếm tiền của bạn có thể sẽ bị tắt nếu
                            video của bạn bị đánh dấu bản quyền. Tuy nhiên điều
                            này không ảnh hưởng đến doanh thu từ video của bạn
                            nếu video bạn sử dụng các bản ghi thư viện nhạc của
                            EMVN khi bạn đã đăng ký tài khoản Subscription với
                            chúng tôi. Ngay sau khi video của bạn được gỡ đánh
                            dấu, doanh thu của video sẽ được YouTube trả lại cho
                            bạn.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_26'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Sau bao lâu thì video của tôi được gỡ xác nhận bản
                              quyền?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_26'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Thông thường, EMVN có thể hỗ trợ các khách hàng của
                            mình gỡ xác nhận bản quyền của mình trong thời gian
                            từ 2 - 5 ngày làm việc.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-wrapper'>
                    <a
                      href='#accordion-wrapper_27'
                      className='accordion-item-trigger w-inline-block'
                      data-toggle='collapse'
                      aria-expanded='false'
                    >
                      <div className='open-close-box'>
                        <img
                          src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f03260a4c043148500c20a0_Arrow.png'
                          width={16}
                          alt=''
                          className='accordion-arrow'
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </div>
                      <div className='expand right-padding'>
                        <div className='flex-no-wrap'>
                          <h4 className='question-title'>
                            <strong>
                              Video của tôi nếu để chế độ Riêng tư thì có được
                              gỡ xác nhận bản quyền không?
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </a>
                    <div
                      id='accordion-wrapper_27'
                      className='collapse accordion-item-content'
                      style={{ height: 0 }}
                    >
                      <div className='flex-no-wrap'>
                        <div>
                          <p className='answer'>
                            Video của bạn cần phải để chế độ Công khai (Public)
                            hoặc Không công khai (Unlisted) khi gửi yêu cầu gỡ
                            Xác nhận bản quyền về EMVN. Để đảm bảo tốt nhất về
                            doanh thu từ video, bạn nên để chế độ Unlisted cho
                            video của mình đến khi nào nhận được thông báo gỡ
                            xác nhận bản quyền thành công từ EMVN.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
      <Footer />
    </>
  );
};
