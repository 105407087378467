import React, { Component } from 'react';
import { History } from 'history';
import axios from 'axios';

// COMPONENT
import { Form, Input, Button, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// HELPER
import {
  getLocalStorageKey,
  removeLocalStorageToken,
} from '../../../../helpers';

// STYLE
import './index.scss';

// API
import { API_URL, API_ROUTE } from '../../../../constant';

// MODEL
import { User } from '../../../../model/user';

interface TProps {
  user?: User;
  history: History;
}

interface TState {
  user?: User;
  showAddInvoice: boolean;
}

export default class Profile extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      user: undefined,
      showAddInvoice: false,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.invoice) {
      this.setState({ user: this.props.user, showAddInvoice: true });
    } else {
      this.setState({ user: this.props.user });
    }
  }

  componentWillReceiveProps(nextProps: TProps) {
    if (nextProps.user && nextProps.user.invoice) {
      this.setState({ user: nextProps.user, showAddInvoice: true });
    } else {
      this.setState({ user: nextProps.user });
    }
  }

  handleChangeShowAddInvoice = () => {
    this.setState((v) => {
      return {
        showAddInvoice: !v.showAddInvoice,
      };
    });
  };

  handleCallApi = (
    v: Partial<User> & {
      newPassword: string;
      oldPassword: string;
      confirmationPassword: string;
    },
  ) => {
    const accessToken = getLocalStorageKey('accessToken');
    const userId = getLocalStorageKey('userId');

    if (
      v.newPassword.length > 0 &&
      v.oldPassword.length > 0 &&
      v.confirmationPassword.length > 0
    ) {
      return axios
        .put<User>(`${API_URL}${API_ROUTE.UPDATE_USER_PASSWORD}`, {
          email: v.email,
          currentPassword: v.oldPassword,
          newPassword: v.newPassword,
        })
        .then(() => {
          axios
            .put<User>(
              `${API_URL}${API_ROUTE.GET_USER_INFORMATION}/${userId}`,
              {
                firstName: v.firstName,
                lastName: v.lastName,
                invoice: v.invoice,
              },
              {
                headers: {
                  authorization: accessToken,
                },
              },
            )
            .then(() => {
              notification.success({
                message: 'Success Notification',
                description: 'Your information has been updated successfully',
                top: 70,
              });
            })
            .catch((error) => {
              if (error.response.status === 401) {
                removeLocalStorageToken('accessToken');
                removeLocalStorageToken('userId');
                // toast.error('Your access token is unvalid, Please login!!');
                return this.props.history.push('/log_in');
              }
            });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeLocalStorageToken('accessToken');
            removeLocalStorageToken('userId');
            // toast.error('Your access token is unvalid, Please login!!');
            return this.props.history.push('/log_in');
          }
        });
    }

    axios
      .put<User>(
        `${API_URL}${API_ROUTE.GET_USER_INFORMATION}/${userId}`,
        {
          firstName: v.firstName,
          lastName: v.lastName,
          invoice: v.invoice,
        },
        {
          headers: {
            authorization: accessToken,
          },
        },
      )
      .then((response) => {
        notification.success({
          message: 'Success Notification',
          description: 'Your information has been updated successfully',
          top: 70,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeLocalStorageToken('accessToken');
          removeLocalStorageToken('userId');
          // toast.error('Your access token is unvalid, Please login!!');
          return this.props.history.push('/log_in');
        }
      });
  };

  render() {
    const { user, showAddInvoice } = this.state;

    if (!user) return null;

    return (
      <div className='profile-information-wrapper'>
        <Form
          style={{ marginTop: 0 }}
          name='nest-messages'
          onFinish={this.handleCallApi}
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            invoice: {
              company: user.invoice ? user.invoice.company : '',
              vat: user.invoice ? user.invoice.vat : '',
              billingAddress: user.invoice ? user.invoice.billingAddress : '',
              additionalInformation: user.invoice
                ? user.invoice.additionalInformation
                : '',
            },
            newPassword: '',
            currentPassword: '',
            confirmationPassword: '',
          }}
        >
          <div className='personal-information'>
            <p className='profile-information-title'>
              Personal/ Company information
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, paddingRight: 10 }}>
              <Form.Item
                name={['firstName']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'First name is required',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  placeholder='First name'
                  className='profile-information-input'
                />
              </Form.Item>
            </div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <Form.Item
                name={['lastName']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Last name is required',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  placeholder='Last name'
                  className='profile-information-input'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            name={['email']}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email is required',
              },
            ]}
            style={{ marginBottom: 20 }}
          >
            <Input
              placeholder='Email'
              className='profile-information-input'
              disabled
            />
          </Form.Item>
          <div className='personal-information'>
            <p className='profile-information-title'>Add invoice information</p>
            {showAddInvoice ? (
              <FontAwesomeIcon
                icon={faChevronUp}
                size='1x'
                color='#b99876'
                className='iconDown'
                onClick={this.handleChangeShowAddInvoice}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                size='1x'
                color='#b99876'
                className='iconDown'
                onClick={this.handleChangeShowAddInvoice}
              />
            )}
          </div>

          {showAddInvoice && (
            <>
              <Form.Item
                name={['invoice', 'company']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  placeholder='Company (Optional)'
                  className='profile-information-input'
                />
              </Form.Item>
              <Form.Item
                name={['invoice', 'vat']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  type='number'
                  placeholder='VAT Number (Optional)'
                  className='profile-information-input'
                />
              </Form.Item>
              <Form.Item
                name={['invoice', 'billingAddress']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  placeholder='Billing Address (Optional)'
                  className='profile-information-input'
                />
              </Form.Item>
              <Form.Item
                name={['invoice', 'additionalInformation']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  placeholder='Additional Information (Optional)'
                  className='profile-information-input'
                />
              </Form.Item>
            </>
          )}
          <div className='personal-information'>
            <p className='profile-information-title'>Change Password</p>
          </div>

          {user.loginBy !== 'google' ? (
            <>
              <Form.Item
                name={['oldPassword']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input.Password
                  placeholder='Current Password'
                  className='profile-information-input'
                  style={{ backgroundColor: 'transparent', color: '#fff' }}
                />
              </Form.Item>
              <Form.Item
                name={['newPassword']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input.Password
                  placeholder='New Password'
                  className='profile-information-input'
                  style={{ backgroundColor: 'transparent', color: '#fff' }}
                />
              </Form.Item>
              <Form.Item
                name={['confirmationPassword']}
                rules={[
                  {
                    required: false,
                    type: 'string',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'The two passwords that you entered do not match!',
                      );
                    },
                  }),
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input.Password
                  placeholder='Confirmation Password'
                  className='profile-information-input'
                  style={{ backgroundColor: 'transparent', color: '#fff' }}
                />
              </Form.Item>
            </>
          ) : null}

          <Form.Item>
            <div style={{ textAlign: 'end' }}>
              <Button
                className='submit-profile-information '
                style={{ borderColor: 'unset' }}
                htmlType='submit'
              >
                <span>SUBMIT CHANGES</span>
              </Button>
            </div>
          </Form.Item>
        </Form>
        <hr />
      </div>
    );
  }
}
