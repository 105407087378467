export interface Plan {
    id: number;
    title: string;
    modelTitle: string;
    startAt?: number;
    sfxPrice?: number;
    startAtLabel?: string;
    rules: string[];
    ruleNotes?: string[];
    notAllowedRules: string[];
}

export const DefaultPaymentPeriod = 2
export const VATTaxRate = 0.08

export const PaymentPeriod = [
    // {
    //   id: 1,
    //   name: '3 months',
    //   value: 3,
    // },
    {
        id: 2,
        name: '12 months',
        value: 12,
    },
];

export const DataPlan: Plan[] = [
    {
        id: 1,
        title: 'Personal',
        modelTitle: 'Personal Subscription',
        startAt: 250000,
        sfxPrice: 62500,
        startAtLabel: '250,000',
        rules: [
            '600K premium music',
            'Covered for personal use',
            'Web: Youtube, Facebook, etc.',
            'Monetization',
        ],
        ruleNotes: [
            '',
            'limit 200k followers/channel',
            'not supported channels with standalone music content',
            '', 
        ],
        notAllowedRules: [
            'Industrial: Trade show, event',
            'Mobile app, in-game',
            'VOD: Netflix, Hulu, etc.',
            'TV and cinema',
        ],
    },
    {
        id: 2,
        title: 'COMMERCIAL',
        modelTitle: 'Commercial Subscription',
        startAt: 1000000,
        sfxPrice: 250000,
        startAtLabel: '1,000,000',
        rules: [
            '600K premium music',
            'Covered for client works',
            'Web: Youtube, Facebook, etc.',
            'Monetization',
            'Industrial: Trade show, event',
        ],
        ruleNotes: [
            '',
            '',
            'not supported channels with standalone music content',
            '',
            '',
        ],
        notAllowedRules: [
            'Mobile app, in-game',
            'VOD: Netflix, Hulu, etc.',
            'TV and cinema',
        ],
    },
    {
        id: 3,
        title: 'CUSTOM',
        modelTitle: 'Custom Subscription',
        rules: [
            '900,000+ premium music & SFXs',
            'Covered for all uses',
            'Web: Youtube, Facebook, etc.',
            'Monetization',
            'Industrial: Trade show, event',
            'Mobile app, in-game',
            'VOD: Netflix, Hulu, etc.',
            'TV and cinema',
        ],
        ruleNotes: [
            '',
            '',
            'not supported channels with standalone music content',
            '',
            '',
            '',
            '',
            '',
        ],
        notAllowedRules: [],
    },
    {
        id: 4,
        title: 'SINGLE',
        modelTitle: 'Single Subscription',
        rules: [],
        notAllowedRules: [],
    },
];

export function getPlanPrice(paymentPeriod: number, selectedPlan: Plan, addSfx: boolean) {
    var paymentPeriodObj = PaymentPeriod.filter((item) => {
        return item.id === paymentPeriod;
    })

    if (!paymentPeriodObj || paymentPeriodObj.length === 0)
        return 0;

    var oneMonthPrice = selectedPlan.startAt! + (addSfx ? selectedPlan.sfxPrice! : 0);
    var totalPrice = oneMonthPrice * paymentPeriodObj[0].value;
    var vatPrice = totalPrice * VATTaxRate;
    return totalPrice + vatPrice;
}
