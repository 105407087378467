/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-empty-pattern */
import React from 'react';
import { Link } from 'react-router-dom';

import bctLogo from '../../assets/images/bctlogo.png';

import './index.scss';

export const Footer = () => {
  return (
    <div className='footer-business'>
      <div className='hero-content-wrapper w-container'>
        <div className='footer-box'>
          <div
            className='top-margin-7 _30-pixels footer'
            style={{ paddingTop: 0 }}
          >
            <div className='columns-7 w-row'>
              <div className='column-15 w-col w-col-3 w-col-medium-3 w-col-small-6'>
                <div className='footer-menu-item-2'>
                  <Link to='/about_us' className='footer-menu-link-2'>
                    VỀ EMVN
                  </Link>
                </div>
                <div className='footer-menu-item-2'>
                  <a
                    href='https://emvn.sourceaudio.com/'
                    target='_blank'
                    className='footer-menu-link-2'
                  >
                    TÌM NHẠC
                  </a>
                </div>
              </div>
              <div className='w-col w-col-3 w-col-medium-3 w-col-small-6'>
                <div className='footer-menu-item'>
                  <Link to='/affiliate_program' className='footer-menu-link'>
                    CHƯƠNG TRÌNH ĐỐI TÁC{' '}
                  </Link>
                </div>
                <div className='footer-menu-item'>
                  <Link to='/sales_policy' className='footer-menu-link'>
                    chính sách bán hàng
                  </Link>
                </div>
              </div>
              <div className='w-col w-col-3 w-col-medium-3 w-col-small-6'>
                <div className='footer-menu-item'>
                  <Link to='/terms_condition' className='footer-menu-link'>
                    ĐIỀU KHOẢN &amp; ĐIỀU KIỆN
                  </Link>
                </div>
                <div className='footer-menu-item'>
                  <Link to='/agreement' className='footer-menu-link'>
                    THOẢ THUẬN DỊCH VỤ{' '}
                  </Link>
                </div>
              </div>
              <div className='w-col w-col-3 w-col-medium-3 w-col-small-6'>
                <div className='footer-menu-item'>
                  <Link to='/privacy_policy' className='footer-menu-link'>
                    CHÍNH SÁCH BẢO MẬT{' '}
                  </Link>
                </div>
                <div className='footer-menu-item'>
                  <Link to='/faq' className='footer-menu-link'>
                    CÂU HỎI THƯỜNG GẶP{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='top-margin-7 _30-pixels footer social'>
            <div className='flex-social'>
              <a
                href='https://www.facebook.com/EMVNMusicLicensing/'
                target='_blank'
                className='social-icon-medium facebook w-inline-block'
              />
              <a
                href='https://www.youtube.com/user/epicmusicvn'
                target='_blank'
                className='link-social w-inline-block'
              >
                <img
                  src='https://uploads-ssl.webflow.com/5f03260ae56c0eb86e4baff0/5f1073bbe054a4d4c3762496_youtube-logotype.svg'
                  alt=''
                  className='image-35'
                />
              </a>
              <a
                href='https://www.instagram.com/emvnmusiclicensing/'
                target='_blank'
                className='social-icon-medium instagram w-inline-block'
              />
              <a
                href='https://www.linkedin.com/company/emvnmusiclicensing/'
                target='_blank'
                className='social-icon-medium linkedin w-inline-block'
              />
            </div>
          </div>
          <div className='top-margin-7 _20-pixel'>
            <div className='footer-notice'>
              Tên công ty: CÔNG TY TNHH GIẢI TRÍ ĐA PHƯƠNG TIỆN EPICMUSICVN
              <br />
              Địa chỉ: Tầng 14, Toà nhà HM Town, 412 Nguyễn Thị Minh Khai, Phường 5, Quận 3, TP. Hồ Chí Minh, Việt Nam
              <br />
              Email: license@emvn.co<a href='mailto:contact@emvn.co'> </a>Số điện thoại: 0983 246 404
              <br />
              Người ĐDPL: Hoàng Thị Phương Thanh (Giám đốc)
              <br />
              Giấy chứng nhận đăng ký kinh doanh số 0107013428 do Sở Kế hoạch và Đầu tư thành phố Hà Nội cấp ngày 02/10/2015.
              <br />
            </div>
          </div>
          <div className='bct-item' style={{ textAlign: 'center', width: 190, marginTop: 10 }} onClick={() => window.open("http://online.gov.vn/Home/WebDetails/69861", "_blank")}>
            <img src={bctLogo} alt='' />
          </div>
          <div className='top-margin-7 _50-pixels'>
            <div className='footer-gcn'>
              Bản quyền © Công ty TNHH Giải trí đa phương tiện EpicMusicVN. Bảo
              lưu mọi quyền.
            </div>
            <div className='top-margin-7-footer'>
              <div className='center-flex' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
