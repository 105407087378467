import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

// MODEL
import { User } from './model/user';

interface TProps {
  children: ReactNode;
  user: User;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const PrivateRoute = ({
  children,
  user,
  isAuthenticated,
  isLoading,
}: TProps) => {
  if (!isLoading && !isAuthenticated) return <Redirect to='/log_in' />;

  return <>{children}</>;
};
