import React, { Component } from 'react';
import axios from 'axios';
import { History } from 'history';
import { notification } from 'antd';

// STYLE
import './index.scss';

// API
import { API_URL, API_ROUTE, headerConfiguration } from '../../constant';

// COMPONENTS
import { NonRoundedGoogleBtn } from '../../components/button';
import { Form, Input, Button } from 'antd';
import ReactLoading from 'react-loading';

// FIREBASE
import { auth, googleProvider } from '../../Config/auth';

// HELPERS
import { setLocalStorageKey, getLocalStorageKey } from '../../helpers';

interface TProps {
  history: History;
  callback?: (callback?: () => void) => void;
}

interface TState {
  isLoading: boolean;
}

let getUser = false;

export default class SignUp extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    auth.getRedirectResult().then(async (result) => {
      if (!result.user) {
        this.setState({ isLoading: false });
      }

      if (result.user && !getUser) {
        getUser = true;

        const firebaseToken = await result.user?.getIdToken();

        const { history, callback } = this.props;

        try {
          const response = await axios.post<
            { firebaseToken: string },
            {
              data: {
                user_id: string;
                access_token: string;
              };
            }
          >(
            `${API_URL}${API_ROUTE.SOCIAL_LOGIN}`,
            {
              firebaseToken: firebaseToken,
            },
            {
              headers: headerConfiguration,
            },
          );

          setLocalStorageKey('accessToken', response.data.access_token);
          setLocalStorageKey('userId', response.data.user_id);

          if (callback) {
            return callback(() => {
              const detailPlan = JSON.parse(getLocalStorageKey('detailPlan') as string)
              if (detailPlan &&
                detailPlan.plan &&
                detailPlan.paymentPeriod &&
                detailPlan.price)
                return history.push('/pay');

              history.push('/');
            });
          }

          history.push('/');
        } catch (error) {
          this.setState({ isLoading: false }, () => {
            notification.error({
              message: 'EMVN Error',
              description:
                'There is an error, Please contact EMVN team for assistance!',
              top: 100,
            });
          });
        }
      }
    });
  }

  componentWillUnmount() {
    getUser = false;
  }

  handleSignUp = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    const { history } = this.props;

    this.setState({ isLoading: true }, async () => {
      try {
        await axios.post(
          `${API_URL}${API_ROUTE.SIGN_UP}`,
          {
            firstName: firstName,
            lastName: lastName,
            displayName: `${firstName} ${lastName}`,
            saleId: email,
            email: email,
            username: email,
            password: password,
          },
          {
            headers: headerConfiguration,
          },
        );

        this.setState({ isLoading: false }, () => {
          notification.success({
            message: 'EMVN Success Message',
            description: 'You have registered successfully!',
            onClose: () => {
              history.push('/log_in');
            },
            top: 100,
          });
        });
      } catch (error) {
        console.log(error);
        this.setState({ isLoading: false }, () => {
          notification.error({
            message: 'EMVN Error Message',
            description: 'Email has been registered before!',
            top: 100,
          });
        });
      }
    });
  };

  handleGoogleSignIn = () => {
    auth.signInWithRedirect(googleProvider);
  };

  handleLogoClick = () => {
    const { history } = this.props;

    return history.push('/');
  };

  validatePassword = (
    rule: any,
    value: string,
    callback: (msg?: string) => any,
  ) => {
    var patt = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)$/g,
    );

    if (!value) {
      return callback('Password is required');
    }

    if (value.length === 0) {
      return callback('Password is required');
    }

    if (value.length < 6) {
      return callback('Password is at least 6 characters');
    }

    if (!patt.test(value)) {
      return callback(
        'Please make sure the password contains at least 1 letter and 1 number',
      );
    }

    return callback();
  };

  loadingScreen = () => {
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.7,
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <ReactLoading type='cylon' color='#fff' height={100} width={100} />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;

    const { history } = this.props;

    return (
      <>
        {isLoading && this.loadingScreen()}

        <div className='sign-up'>
          <div className='body-wrapper'>
            <div className='left-group'>
              <div className='left-group-wrapper'>
                <div>
                  <p className='left-group-main-title'>Start discovering</p>
                  <p className='left-group-main-title'>EMVM for free</p>
                  <p className='left-group-sub-title'>
                    With a trial account you can:
                  </p>
                  <ul className='sign-up-benefit'>
                    <li>Download 10 sample tracks to try in your projects</li>
                    <li>Organize your personal music & playlists</li>
                    <li>Get the latest news & releases</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='right-group'>
              <div className='form-group'>
                <div className='input-form'>
                  <Form
                    style={{ marginTop: 30 }}
                    name='nest-messages'
                    onFinish={this.handleSignUp}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ flex: 1, paddingRight: 10 }}>
                        <Form.Item
                          name={['firstName']}
                          rules={[
                            {
                              required: true,
                              type: 'string',
                              message: 'First name is required',
                            },
                          ]}
                          style={{ marginBottom: 20 }}
                        >
                          <Input
                            disabled
                            placeholder='First name'
                            style={{ width: '100%', height: 40 }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ flex: 1, paddingLeft: 10 }}>
                        <Form.Item
                          name={['lastName']}
                          rules={[
                            {
                              required: true,
                              type: 'string',
                              message: 'Last name is required',
                            },
                          ]}
                          style={{ marginBottom: 20 }}
                        >
                          <Input
                            disabled
                            placeholder='Last name'
                            style={{ width: '100%', height: 40 }}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item
                      name={['email']}
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Email is required',
                        },
                      ]}
                      style={{ marginBottom: 20 }}
                    >
                      <Input
                        disabled
                        placeholder='Email'
                        style={{ width: '100%', height: 40 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['password']}
                      rules={[
                        {
                          validator: this.validatePassword,
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        disabled
                        type='password'
                        placeholder='Password'
                        style={{ width: '100%', height: 40 }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button disabled htmlType='submit' className='login-submit'>
                        {/* FREE SIGN UP */}
                        NEW SIGN UP IS NOT AVAILABLE
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                {/* <div className='sign-up-btn'>
                  <p className='free-sign-up-text'>No credit card required</p>
                </div> */}
                {/* <hr /> */}
                {/* <NonRoundedGoogleBtn onClick={this.handleGoogleSignIn} /> */}
                <div className='footer-group-custom'>
                  <p style={{ fontSize: 15 }}>
                    By registering, you agree to the Terms of Use and Privacy
                    Policy
                  </p>
                  <p
                    style={{ fontSize: 15 }}
                    onClick={() => history.push('/log_in')}
                  >
                    Already a member?{' '}
                    <span
                      className='signup-click'
                      style={{
                        fontSize: 17,
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Sign In
                    </span>
                  </p>
                  {/* <p
                  className='signup-text'
                  onClick={() => history.push('/log_in')}
                >
                  Already a member?{' '}
                  <span className='sign-in-text'>Sign in</span>
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
