import React, { Component } from 'react';
import { History } from 'history';

// COMPONENT
import { Form } from 'antd';

// HELPER
import { getLocalStorageKey } from '../../../../helpers';

// STYLE
import './index.scss';

// MODEL
import { User } from '../../../../model/user';

interface TProps {
  user?: User;
  history: History;
}

interface TState {
  user?: User;
}

export default class Profile extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      user: undefined,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.invoice) {
      this.setState({ user: this.props.user });
    } else {
      this.setState({ user: this.props.user });
    }
  }

  componentWillReceiveProps(nextProps: TProps) {
    if (nextProps.user && nextProps.user.invoice) {
      this.setState({ user: nextProps.user });
    } else {
      this.setState({ user: nextProps.user });
    }
  }

  handleCallApi = (v: {
    youtubeURL: string;
    emvnLink: string;
    note: string;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const accessToken = getLocalStorageKey('accessToken');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const userId = getLocalStorageKey('userId');
  };

  render() {
    return (
      <div className='licenses-profile-information-wrapper'>
        <Form
          style={{ marginTop: 0 }}
          name='nest-messages'
          onFinish={this.handleCallApi}
          initialValues={{
            youtubeURL: '',
            emvnLink: '',
            note: '',
          }}
        >
          {/* <div className='licenses-personal-information'>
            <p className='profile-information-title'>
              Clear video from copyright claims
            </p>
          </div> */}

          <p className='licenses-plan-text'>
            Every single track license you purchase results in a license
            document and a receipt that you'll find listed below. In each
            license you can add URLs to your published content for whitelisting
            on YouTube, Facebook, Instagram and other platforms.
          </p>
        </Form>
        <hr />
      </div>
    );
  }
}
