import React, { ReactNode } from 'react';

// STYLE
import './index.scss';

interface TProps {
  className?: string;
  onClick?: () => void;
  title?: string;
  children?: ReactNode;
  type?: 'submit' | 'button' | 'reset';
}

export const Button = ({
  onClick,
  title,
  className,
  children,
  type,
}: TProps) => {
  return (
    <button type={type} className={`custom-btn ${className}`} onClick={onClick}>
      {title}
      {children}
    </button>
  );
};

export const RoundedButton = ({
  onClick,
  className,
  title,
  children,
  type,
}: TProps) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      title={title}
      className={`rounded-btn ${className}`}
    >
      {children}
    </Button>
  );
};

export const NonRoundedButton = ({
  onClick,
  className,
  title,
  type,
  children,
}: TProps) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      title={title}
      className={`non-rounded-btn ${className}`}
    >
      {children}
    </Button>
  );
};

export const FaceBookBtn = ({ onClick }: TProps) => {
  return (
    <RoundedButton onClick={onClick} className='facebook-btn'>
      <div className='title-wrapper'>
        <img src='/facebook.svg' alt='facebookicon' />
        <p className='title'>FACEBOOK</p>
      </div>
    </RoundedButton>
  );
};

export const GoogleBtn = ({ onClick }: TProps) => {
  return (
    <RoundedButton onClick={onClick} className='google-btn'>
      <div className='title-wrapper'>
        <img src='/google.svg' alt='googleicon' />
        <p className='title'>GOOGLE</p>
      </div>
    </RoundedButton>
  );
};

export const NonRoundedGoogleBtn = ({ onClick }: TProps) => {
  return (
    <NonRoundedButton onClick={onClick} className='google-btn'>
      <div className='title-wrapper'>
        <img src='/google.svg' alt='googleicon' />
        <p className='title'>GOOGLE</p>
      </div>
    </NonRoundedButton>
  );
};
